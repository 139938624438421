import { CurrencyDollar, Plus, X } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';

const CreditReferenceItem = ({
  errors,
  control,
  setValue,
  trigger,
  touchedFields,
  creditReferenceArr,
  addRaw,
  removeRaw,
}: any) => (
  <div className="rounded-lg border border-utilityGray200 shadow-xs p-4 flex flex-col gap-y-4">
    {creditReferenceArr.map((data: any, index: number) => (
      <div className="flex w-full gap-2" key={index}>
        <div className="flex-1 flex flex-wrap max-sm:flex-col max-md:gap-y-3 sm:-mx-1.5">
          <Controller
            name={`creditReferences.${index}.company_name`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Company name"
                inputName={`creditReferences.${index}.company_name`}
                placeholder="Enter Name"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.creditReferences?.[index]?.company_name?.message ??
                  null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <div className="md:w-1/4 sm:w-1/2 sm:px-1.5">
            <label className="form_label block mb-1.5">
              Phone Number <span className="text-red-600 leading-4">*</span>
            </label>
            <Controller
              name={`creditReferences.${index}.phone_number`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  onNumberChange={(country: any, phone: any, code: any) => {
                    console.log('onNumberChange');
                    const newValue = `+${code}${phone}`;
                    setValue(
                      `creditReferences.${index}.phone_number`,
                      newValue
                    );
                    setValue('phone_country_code', country);
                    onChange(newValue);

                    if (touchedFields.phone_number) {
                      trigger(`creditReferences.${index}.phone_number`);
                      trigger('phone_country_code');
                    }
                  }}
                  value={value}
                  name={`creditReferences.${index}.phone_number`}
                  errors={
                    errors.creditReferences?.[index]?.phone_number?.message ??
                    null
                  }
                />
              )}
            />
          </div>

          <Controller
            name={`creditReferences.${index}.email`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Email"
                inputName={`creditReferences.${index}.email`}
                placeholder="Enter email"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.creditReferences?.[index]?.email?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`creditReferences.${index}.credit_amount`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Credit Amount"
                inputType="number"
                inputName={`creditReferences.${index}.credit_amount`}
                placeholder="Enter credit amount"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={(e) => {
                  // Convert empty string to null or a default value, e.g., 0
                  const newValue =
                    e.target.value === '' ? 0 : parseFloat(e.target.value);

                  if (!isNaN(newValue) || newValue === 0) {
                    onChange(newValue);
                  }
                }}
                errorText={
                  errors.creditReferences?.[index]?.credit_amount?.message ??
                  null
                }
                icon={
                  <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
                className="pl-8"
                labelClassName="block mb-1.5"
              />
            )}
          />
        </div>
        <div className="flex justify-center items-end gap-2 h-[48px]">
          {index > 0 ? (
            <ButtonCmp
              className="btn_primary cursor-pointer !bg-transparent border-[#FFEBE9] text-fgErrorPrimary !border-0 !p-0 rounded-full"
              onClick={() => removeRaw(index, 'credit_reference')}
            >
              <X className="w-4 h-4" />
            </ButtonCmp>
          ) : (
            <span className="w-9 h-9">&nbsp;</span>
          )}
        </div>
      </div>
    ))}
    <div
      className="flex gap-x-1 items-center text-sm text-primary font-medium w-fit cursor-pointer"
      onClick={() => addRaw('credit_reference')}
    >
      <Plus className="w-4 h-4" />
      <span>Add new</span>
    </div>
  </div>
);

export default CreditReferenceItem;
