import {
  ClockFastForward,
  HelpCircle,
  Plus,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import { PERMISSION } from 'src/constants/common';
import { useRolePermission } from 'src/utils/CommonFunctions';

import TooltipCmp from '../../../components/TooltipCmp';

const SmartQuoteListLoading = () => {
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();

  return (
    <>
      {/* <td className="px-5 py-4 pr-0 w-8 min-w-8 max-w-8">
        <p className="w-4 h-4 bg-utilityGray200 rounded-full border-[3px] border-utilityGray200 custom-loading"></p>
      </td> */}
      <td className="px-5 py-4 min-w-44 w-44 max-w-44">
        <div className="flex items-center custom-loading">
          <p className="w-4 h-4 bg-utilityGray200 rounded-full border-[3px] border-utilityGray200 custom-loading mr-3"></p>
          <p className="mr-1 w-3 h-3 bg-successSecondary rounded-full border-[3px] border-utilitySuccess100"></p>
          <div className="w-7 h-7 bg-primary rounded-md text-white flex items-center justify-center uppercase ">
            KD
          </div>
          <div className="ml-2 text-xs font-medium max-w-24">
            <div className="text-grayLight900 font-medium leading-tight truncate ">
              Kishan Dodiya
            </div>
            <div className="text-grayLight600 font-normal leading-tight truncate">
              #SQ-L-100502
            </div>
          </div>
        </div>
      </td>

      <td className="px-5 py-4 min-w-[100px] max-w-[100px] w-[100px]">
        <BadgeCmp style="modern" type="error" mainClassName="custom-loading">
          Volume LTL
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 max-w-40 min-w-40 w-40">
        <p className="truncate custom-loading"> Multi-Formes Inc (USD)</p>
      </td>
      <td className="px-5 py-4 xxl:max-w-48 xxl:w-48 xxl:min-w-48 w-40 max-w-40 min-w-40 ">
        <TooltipCmp
          type="dark"
          parentClassName=""
          childrenClassName="custom-loading"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate max-w-[150px]">
              3496 Rue Vautelet, Québec, QC G1W 4V8, Canada
            </span>
          </p>
        </TooltipCmp>

        <TooltipCmp
          type="dark"
          parentClassName="max-w-90"
          childrenClassName="custom-loading"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate max-w-[150px]">
              Federal Way, WA 98001, USA
            </span>
          </p>
        </TooltipCmp>
      </td>
      <td className="px-5 py-4 min-w-36 w-36 max-w-36">
        <span className=" custom-loading">Sep 5th , 02:50 AM</span>
      </td>

      <td className="px-5 py-4 min-w-36 w-36 max-w-36">
        <span className="custom-loading">Sep 5th , 02:50 AM</span>
      </td>
      <td className="pl-3 pr-1 py-4 min-w-28 w-28 max-w-28">
        <span className="custom-loading"> 1 Pallet</span>
      </td>
      <td className="px-3 py-4 min-w-36 w-36 max-w-36">
        <span className="custom-loading"> 300lbs</span>
      </td>
      <td className="px-5 py-4 min-w-32 w-32 max-w-32">
        <BadgeCmp style="pill" type="success" mainClassName="custom-loading">
          <ClockFastForward className="mr-1 h-3 w-3 text-lg text-success500" />
          17d ago
        </BadgeCmp>
      </td>
      {hasRoleV2('admin') && (
        <td className="px-5 py-4 pl-0 w-[100px] max-w-[100px] min-w-[100px]">
          <div className="justify-center items-center gap-1 flex custom-loading">
            <TooltipCmp message="Quick quote" type="dark" childrenClassName="">
              <Plus className="mt-0.5 w-4 h-4 relative cursor-pointer text-primary" />
            </TooltipCmp>
            {hasPermissionV2(PERMISSION.DELETE_SMART_QUOTE) && (
              <TooltipCmp message="No quote" type="dark" childrenClassName="">
                <XClose className="mt-0.5 w-4 h-4 relative cursor-pointer text-fgErrorPrimary" />
              </TooltipCmp>
            )}
            <TooltipCmp message="Request help" type="dark" childrenClassName="">
              <HelpCircle className="mt-0.5 w-4 h-4 relative cursor-pointer text-fgSuccessPrimary" />
            </TooltipCmp>
          </div>
        </td>
      )}
    </>
  );
};

export default SmartQuoteListLoading;
