import React from 'react';
import { Controller } from 'react-hook-form';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';

const BankInfoForm = ({
  control,
  errors,
  setValue,
  trigger,
  touchedFields,
}: any) => (
  <div>
    {' '}
    <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
      Bank Information
    </h6>
    <div className="rounded-lg border border-utilityGray200 px-3 pt-3.5 pb-4 flex flex-col gap-y-4">
      <div className="flex-1 flex flex-wrap max-sm:flex-col gap-y-3 sm:-mx-1.5">
        <Controller
          name="bank_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Name"
              inputName="bank_name"
              placeholder="Enter Name"
              parentClassName="sm:w-1/2 sm:px-1.5"
              value={value}
              onChangeFunc={onChange}
              required={true}
              errorText={errors.bank_name ? errors.bank_name.message : null}
              labelClassName="block mb-1.5"
            />
          )}
        />
        <Controller
          name="bank_contact_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Bank contact name"
              inputName="bank_contact_name"
              placeholder="Enter bank name"
              parentClassName="sm:w-1/2 sm:px-1.5"
              value={value}
              onChangeFunc={onChange}
              errorText=""
              labelClassName="block mb-1.5"
            />
          )}
        />
        <Controller
          name="bank_email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Email"
              inputName="bank_email"
              placeholder="Enter email"
              parentClassName="sm:w-1/2 sm:px-1.5"
              value={value}
              onChangeFunc={onChange}
              required={true}
              errorText={errors.bank_email ? errors.bank_email.message : null}
              labelClassName="block mb-1.5"
            />
          )}
        />
        <div className="sm:w-1/2 sm:px-1.5">
          <label className="form_label block mb-1.5">
            Phone Number <span className="text-red-600 leading-4">*</span>
          </label>
          <Controller
            name="bank_phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                onNumberChange={(country: any, phone: any, code: any) => {
                  console.log('onNumberChange');
                  const newValue = `+${code}${phone}`;
                  setValue('bank_phone', newValue);
                  setValue('phone_country_code', country);
                  onChange(newValue);

                  if (touchedFields.bank_phone) {
                    trigger('bank_phone');
                    trigger('phone_country_code');
                  }
                }}
                value={value}
                name="bank_phone"
                errors={
                  errors.bank_phone
                    ? errors.bank_phone.message
                    : errors.phone_country_code
                    ? errors.phone_country_code.message
                    : null
                }
              />
            )}
          />
        </div>

        <Controller
          name="bank_address"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Address"
              inputName="address"
              placeholder="Enter address"
              parentClassName="md:w-1/2 sm:px-1.5"
              value={value}
              onChangeFunc={onChange}
              errorText=""
              labelClassName="block mb-1.5"
            />
          )}
        />
        <Controller
          name="bank_city"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="City/ State/ Zip"
              inputName="bank_city"
              placeholder="Enter City/ State/ Zip"
              parentClassName="md:w-1/2 sm:px-1.5"
              value={value}
              onChangeFunc={onChange}
              errorText=""
              labelClassName="block mb-1.5"
            />
          )}
        />
      </div>
    </div>
  </div>
);

export default BankInfoForm;
