import { Check } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactSelect, { components, NoticeProps } from 'react-select';
import { BasicContext } from 'src/context/BasicContext';
import { getShortName, onError } from 'src/utils/CommonFunctions';

import ErrorMsg from '../errorMsg';

import { ISelectBoxProps } from './SelectBox.interface';

const SelectBox = ({
  errorText,
  label,
  options,
  menuPlaceOption,
  noOptionMessage = 'No Option',
  classNamePrefix,
  tabIndex,
  isSearchable = false,
  isMultipleSelection,
  labelClassName,
  imageClassName,
  isDisabled,
  isError,
  value,
  defaultValue,
  onChangeFunc,
  isClearable,
  isLoading,
  placeholder,
  classComp,
  dropdownImageClassName,
  isCloseOnScroll = true,
  icon,
  parentClassName,
  menuIsOpen,
  selectoptiontext,
  required,
  onMenuOpen,
  placeholderIcon,
}: ISelectBoxProps) => {
  const selectRef = useRef<any>(null);
  const [menuIsOpenList, setMenuIsOpenList] = useState(menuIsOpen);
  const [isFocused, setIsFocused] = useState(false);
  const { setTooltipGlobalOpen } = useContext(BasicContext);

  const CustomOption = ({ ...props }: any) => {
    const { ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };

    return (
      <components.Option {...newProps}>
        <div className="flex justify-between items-center align-middle">
          <div className="flex justify-center items-center w-full flex-1">
            {props.data.image ? (
              <img
                alt=""
                className={`rounded-full w-5 h-5 mr-2 flex-none ${dropdownImageClassName}`}
                src={props.data.image}
                onError={onError}
              />
            ) : (
              props.data.isImage && (
                <div className="w-[18px] h-[18px] leading-[18px] mr-2 bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                  <span>{getShortName(props.data.label)}</span>
                </div>
              )
            )}
            {props.data.value === 'all_user' ? (
              <div className="w-5 h-5 mr-2"></div>
            ) : (
              ''
            )}
            <div className={`flex-1 flex flex-wrap  truncate`}>
              <span className={`truncate font-medium mr-2 ${selectoptiontext}`}>
                {props.data.label}
              </span>
              {props.data.subLabel ? (
                <span className="flex justify-center items-center font-medium sub-label">
                  {props.data.subLabel}
                </span>
              ) : (
                ''
              )}
              {props.data.email ? (
                <span className="flex justify-center items-center font-medium text-grayLight900 underline">
                  {props.data.email}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          {props.isSelected ? <Check className="w-4 h-4  text-primary" /> : ''}{' '}
        </div>
      </components.Option>
    );
  };

  const CustomNoOptionsMessage = (props: NoticeProps<any>) => (
    <components.NoOptionsMessage {...props}>
      <p className="text-[11px] text-textSecondary font-bold -my-[1px]">
        {noOptionMessage}
      </p>{' '}
      {/* Customize this message as needed */}
    </components.NoOptionsMessage>
  );

  useEffect(() => {
    setMenuIsOpenList(menuIsOpen);
  }, [menuIsOpen]);

  useEffect(() => {
    if (isCloseOnScroll) {
      const handleScroll = () => {
        setMenuIsOpenList(false);
      };

      const scrollableContainer = document.querySelector(
        '.main__layout_section'
      );

      if (scrollableContainer) {
        scrollableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollableContainer) {
          scrollableContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (menuIsOpenList && selectRef.current) {
      selectRef.current.focus();
    }
  }, [menuIsOpenList]);

  useEffect(() => {
    if (
      !isMultipleSelection &&
      selectRef.current &&
      selectRef.current.state.focusedOption &&
      selectRef.current?.state.focusedOption?.value !== value?.value
    ) {
      selectRef.current.state.focusedOption = '';
    }
  }, [menuIsOpen]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    if (newValue === null && actionMeta.action === 'clear') {
      if (onChangeFunc) onChangeFunc(null, actionMeta);
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.blur();
        }
      }, 400);
    } else {
      if (onChangeFunc) onChangeFunc(newValue, actionMeta); // Normal change handling
    }
  };

  return (
    <>
      <div className={parentClassName}>
        {label && (
          <div className="flex">
            <label className={labelClassName}>{label}</label>
            {required && (
              <span className="text-red-600 leading-4 text-xs">*</span>
            )}
          </div>
        )}

        <div
          className={`wal-hq-select ${
            isLoading ? 'custom-loading rounded-lg' : ''
          } ${errorText || isError ? 'is-invalid' : ''}`}
        >
          <ReactSelect
            ref={selectRef}
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
            options={options}
            menuPlacement={menuPlaceOption}
            formatOptionLabel={(option) => (
              <div className="flex justify-between">
                <div className="flex items-center w-full">
                  {option.image ? (
                    <img
                      alt=""
                      className={`rounded-full w-5 h-5 mr-2 ${imageClassName}`}
                      src={option.image}
                      onError={onError}
                    />
                  ) : (
                    option.isImage && (
                      <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] mr-2 rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                        <span>{getShortName(option.label)}</span>
                      </div>
                    )
                  )}
                  <span className="truncate font-medium ">{option.label}</span>
                  {option.subLabel ? (
                    <span className="ml-2 flex justify-center items-center font-medium text-grayLight600">
                      {option.subLabel}
                    </span>
                  ) : (
                    ''
                  )}
                  {option.email ? (
                    <span className="flex justify-center items-center font-medium underline ml-2">
                      {option.email}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
            hideSelectedOptions={false}
            tabIndex={tabIndex}
            classNamePrefix={classNamePrefix ? classNamePrefix : 'select'}
            isSearchable={value?.length ? false : isSearchable}
            isClearable={isClearable}
            isDisabled={isDisabled}
            // isLoading={isLoading}
            isMulti={isMultipleSelection}
            placeholder={isFocused && isSearchable ? '' : placeholder} // Render empty string when focused
            className={`${classComp}`}
            components={{
              ...(isDisabled && isMultipleSelection
                ? { MultiValueRemove: () => null }
                : {}),
              Option: CustomOption,
              NoOptionsMessage: CustomNoOptionsMessage,
              Placeholder: (props: any) => (
                <components.Placeholder {...props}>
                  {isFocused && isSearchable ? false : placeholderIcon}
                  {props.children}
                </components.Placeholder>
              ),
            }}
            menuIsOpen={menuIsOpenList}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMenuOpen={() => {
              setTooltipGlobalOpen(false);
              setMenuIsOpenList(true);

              if (onMenuOpen) {
                onMenuOpen();
              }
            }}
            onMenuClose={() => {
              if (isCloseOnScroll) {
                setMenuIsOpenList(false);
              }
            }}
          />
          {icon && icon}
        </div>
        <div className="[&:empty]:mt-0 mt-1.5">
          {errorText && <ErrorMsg errorText={errorText}></ErrorMsg>}
        </div>
      </div>
    </>
  );
};

SelectBox.defaultProps = {
  name: '',
  errorText: '',
  label: '',
  tooltip: '',
  className: '',
  options: '',
  id: '',
  labelClassName: '',
  classComp: '',
};

export default SelectBox;
