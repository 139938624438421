import { SearchLg, Plus } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { NotificationsContext } from 'src/context/NotificationsContext';
import { listCustomerOnboarding } from 'src/services/CreditDashboard';
import { useRolePermission, isValidJSON } from 'src/utils/CommonFunctions';

import AddOnBoardCustomer from '../CustomerOnboarding/AddOnBoardCustomer';
import MailConfirmation from '../CustomerOnboarding/MailConfirmation';
import ReviewOnboarding from '../CustomerOnboarding/ReviewOnboarding';

import CreditCustomerLoaderRow from './CreditCustomerOnboarding/CreditCustomerLoaderRow';
import CreditCustomerRow from './CreditCustomerOnboarding/CreditCustomerRow';
import CreditIncreaseLoaderRow from './CreditIncreaseRequest/CreditIncreaseLoaderRow';
import CreditIncreaseRow from './CreditIncreaseRequest/CreditIncreaseRow';
import NextTasks from './NextTasks';

const tabArray: any = [
  {
    value: 'customer_onboarding',
    name: 'Customer Onboarding',
  },
  {
    value: 'credit_increase_requests',
    name: 'Credit Increase Requests',
  },
];

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];
const initAction = {
  mode: '',
  id: 0,
  data: {},
};
const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  status: 'all',
};

const CreditDashboard = () => {
  const { custOnBoardCount, handleNotificationRead } =
    useContext(EntityCountsContext);
  const { entityType } = useContext(NotificationsContext);

  const navigate = useNavigate();
  const { hasRoleV2 } = useRolePermission();
  const [activeTab, setActiveTab] = useState('customer_onboarding');
  const [customerOnboardData, setCustomerOnboardData] = useState<any>(null);
  const [params, setParams] = useState(() => {
    const storedParams = localStorage.getItem(TABLE_IDS.CREDIT_DASHBOARD);

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)
      : initParams;
  });
  const [action, setAction] = useState<any>(initAction);
  const [reqParams, setReqParams] = useState(initParams);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [customerList, setCustomerList] = useState<any>([]);
  const [totals, setTotals] = useState(0);

  const headCells = [
    {
      id: 'firstName',
      name: 'User',
    },
    {
      id: 'name',
      name: 'Customers',
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'createdAt',
      name: 'Created Date',
    },
    {
      id: 'form_view_date',
      name: 'Viewed Date',
    },
    {
      id: 'email',
      name: 'Email',
    },

    {
      id: '#',
    },
  ];

  const CreditCells = [
    {
      id: 'user',
      name: 'User',
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'current_credit_limit',
      name: 'Current Credit Limit',
    },
    {
      id: 'requested_credit_increase',
      name: 'Requested Credit Increase',
    },
    {
      id: 'requested_credit_total',
      name: 'Requested Credit Total',
    },
    {
      id: 'action',
      name: '',
    },
  ];

  const filterArr: any = [
    {
      value: 'all',
      name: 'View All',
    },
    {
      value: 'Form Pending',
      name: 'Form Pending',
    },
    {
      value: 'Form Completed',
      name: 'Form Completed',
    },
    {
      value: 'Approved',
      name: 'Approved',
    },
    {
      value: 'Expired',
      name: 'Expired',
    },
    {
      value: 'Denied',
      name: 'Denied',
    },
  ];

  const creditIncreaseArr: any = [
    {
      value: 'all',
      name: 'View All',
    },
    {
      value: 'approved',
      name: 'Approved',
    },
    {
      value: 'in_review',
      name: 'In-review',
    },
    {
      value: 'denied',
      name: 'Denied',
    },
  ];

  const fetchCustomerOnboardingData = async (listApiSignal?: any) => {
    setIsLoading(true);
    setCustomerList([]);
    listCustomerOnboarding(params, listApiSignal)
      .then((response: any) => {
        setCustomerList(response.data);
        setTotals(response.total);
        setIsLoading(false);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const listApiController = new AbortController();
    const listApiSignal = listApiController.signal;
    fetchCustomerOnboardingData(listApiSignal);

    return () => {
      listApiController.abort();
    };
  }, [params]);

  useEffect(() => {
    setTimeout(() => {
      if (custOnBoardCount && custOnBoardCount > 0) {
        const param = { type: 'customer_onboarding' };
        handleNotificationRead(param);
      }
    }, 100);
  }, []);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const searchCustIncDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setReqParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchIncCustomer') {
      setSearch(value);
      searchCustIncDebounce(value);
    }

    if (name === 'searchCustomer') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  useEffect(() => {
    if (entityType === 'customer_onboarding_status') {
      fetchCustomerOnboardingData();
    }
  }, [entityType]);

  return (
    <PageSectionLayout
      header={
        <Header
          title="Credit Dashboard"
          desc="Manage and view your customer credit requests."
        />
      }
    >
      <TabButton
        parentClassName="!bg-gray50 !w-full "
        className="rounded-md text-gray500 !border-0 min-w-[182px] "
        tabParentClassName={`!w-full ${
          window.MODE === 'production' ? '!hidden' : ''
        }`}
        activeClassName="!bg-primary100 !text-primary700"
        tabArray={tabArray}
        isActive={activeTab}
        isTab={true}
        handleOnClick={(e: any) => {
          setActiveTab(e?.target?.dataset?.value);
        }}
      />
      {activeTab === 'customer_onboarding' && (
        <>
          <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
            <div className="table-top-header ">
              <div className="table-left-wrap sm:w-auto w-full">
                <div className="table-title-wrap">
                  <h5 className="table-title">All Customer Onboardings</h5>
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName={isLoading ? 'custom-loading' : ''}
                  >
                    {totals} {totals > 1 ? 'Customers' : 'Customer'}
                  </BadgeCmp>
                </div>
                <p className="table-subtitle">
                  Efficiently track and manage new customers onboardings
                </p>
              </div>
              <ButtonCmp
                className={`btn-outline-primary ${
                  window.MODE === 'production' ? '!hidden' : ''
                }`}
                onClick={() =>
                  setAction((old: any) => ({
                    ...old,
                    mode: 'addOnboardCustomer',
                  }))
                }
              >
                <Plus className="w-4 h-4" />
                Onboard New Customer
              </ButtonCmp>
            </div>
            <div className="table-bottom-header">
              <div className="table-header-bottom-left">
                <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
                  <TabButton
                    tabArray={filterArr}
                    parentClassName=""
                    className=""
                    activeClassName="!bg-utilityGray100"
                    isActive={params.status}
                    handleOnClick={(e: any) => {
                      setParams((old: any) => ({
                        ...old,
                        ...{ status: e?.target?.dataset?.value, page: 1 },
                      }));
                    }}
                  />
                </div>
              </div>
              <InputText
                inputName="searchCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                parentClassName="table-searchInput"
                isClearable={true}
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArray}
                  onChangeFunc={(event: any) => {
                    setParams((old: any) => ({
                      ...old,
                      limit: event.value,
                      page: 1,
                    }));
                  }}
                  isSearchable={false}
                  value={recordsPerPageArray.find(
                    (val: any) => val.value === params.limit
                  )}
                />
              </div>
            </div>
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
                <TableCmp
                  headCells={headCells}
                  params={params}
                  setParams={setParams}
                  tableDataArr={customerList}
                  TableLoaderRowCmp={CreditCustomerLoaderRow}
                  TableRowCmp={CreditCustomerRow}
                  numberOfSkeletonRows={15}
                  tableHeaderClass=""
                  // tableRowCmpProps={{
                  //   openCustomerPage: (rowData: any) =>
                  //     localStorage.setItem(
                  //       TABLE_IDS.TARGET_LIST_BY_CUSTOMER,  // Key for the table storage
                  //       JSON.stringify({
                  //         ...initParamsForTargetSet,
                  //         userId: data.userId,
                  //         targetMonth: params.targetMonth || initParams.targetMonth,  // Retaining the current targetMonth
                  //       })
                  //     )
                  //     hasRoleV2('admin') &&
                  //     navigate(`${PATH.CREDIT_DASHBOARD}/${rowData?.id}`),
                  //   setAction: setAction,
                  // }}
                  tableRowCmpProps={{
                    openCustomerPage: (rowData: any) => {
                      // Store the table parameters in local storage
                      localStorage.setItem(
                        TABLE_IDS.CREDIT_DASHBOARD, // Key for the table storage
                        JSON.stringify(params)
                      );

                      // Navigate to the customer page if the user has the 'admin' role
                      if (hasRoleV2('admin')) {
                        navigate(`${PATH.CREDIT_DASHBOARD}/${rowData?.id}`);
                      }
                    },
                    setAction: setAction,
                  }}
                  isTableDataLoading={isLoading}
                  isTableRowClickable={true}
                />
              </div>
              {!isLoading && !customerList.length && (
                <NotFoundUI
                  title="No Customer Found"
                  desc="There are no data for customers."
                  containerClassName="min-h-[unset]"
                />
              )}
              {/*  */}
            </div>

            <div className="w-full bg-white rounded-b-xl">
              <CustomPagination
                recordsPerPage={params.limit}
                totalRecords={totals}
                currentPage={params.page}
                handlePagination={(page: number) => {
                  setParams((old: any) => ({ ...old, page }));
                }}
              />
            </div>
            {action.mode === 'next-task' && (
              <NextTasks handleClose={() => setAction(initAction)} />
            )}
          </div>
        </>
      )}
      {activeTab === 'credit_increase_requests' && (
        <>
          <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
            <div className="table-top-header ">
              <div className="table-left-wrap sm:w-auto w-full">
                <div className="table-title-wrap">
                  <h5 className="table-title">All Credit Increase Requests</h5>
                  <BadgeCmp style="modern" type="success">
                    50 Requests
                  </BadgeCmp>
                </div>
                <p className="table-subtitle">
                  Track your customers credit increase requests
                </p>
              </div>
              <ButtonCmp
                className={`btn-outline-primary ${
                  window.MODE === 'production' ? '!hidden' : ''
                }`}
                onClick={() => {}}
              >
                <Plus className="w-4 h-4" />
                Credit Increase Request
              </ButtonCmp>
            </div>
            <div className="table-bottom-header">
              <div className="table-header-bottom-left">
                <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center">
                  <TabButton
                    tabArray={creditIncreaseArr}
                    parentClassName=""
                    className=""
                    activeClassName="!bg-utilityGray100"
                    isActive={params.status}
                    handleOnClick={(e: any) => {
                      setParams((old: any) => ({
                        ...old,
                        ...{ status: e?.target?.dataset?.value, page: 1 },
                      }));
                    }}
                  />
                </div>
              </div>
              <InputText
                inputName="searchIncCustomer"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={search}
                inputType="text"
                onChangeFunc={handleFilter}
                parentClassName="table-searchInput"
                isClearable={true}
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArray}
                  onChangeFunc={(event: any) => {
                    setReqParams((old: any) => ({
                      ...old,
                      limit: event.value,
                      page: 1,
                    }));
                  }}
                  isSearchable={false}
                  value={recordsPerPageArray.find(
                    (val: any) => val.value === reqParams.limit
                  )}
                />
              </div>
            </div>
            <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
                <TableCmp
                  headCells={CreditCells}
                  params={[{}]}
                  tableDataArr={[{}]}
                  TableLoaderRowCmp={CreditIncreaseLoaderRow}
                  TableRowCmp={CreditIncreaseRow}
                  numberOfSkeletonRows={15}
                  tableHeaderClass=""
                  isTableRowClickable={true}
                />
              </div>

              {/* <NotFoundUI
                  title="No Customer Found"
                  desc="There are no data for customers."
                  containerClassName="min-h-[unset]"
                /> */}
            </div>

            <div className="w-full bg-white rounded-b-xl">
              <CustomPagination
                recordsPerPage={10}
                totalRecords={20}
                currentPage={1}
                handlePagination={() => {}}
              />
            </div>
          </div>
        </>
      )}
      {action.mode === 'addOnboardCustomer' && (
        <AddOnBoardCustomer
          action={action}
          setAction={setAction}
          customerData={action?.data}
          handleClose={(formData?: any) => {
            if (formData) {
              setCustomerOnboardData(formData);
              setAction({ mode: 'review-onboarding', data: null });
              // setAction({ mode: 'send-mail', data: null });
            } else {
              setAction({ mode: null, data: null });
            }
          }}
        />
      )}

      {action.mode === 'send-mail' && (
        <MailConfirmation
          action={action}
          setAction={setAction}
          customerData={customerOnboardData}
          handleClose={() => setAction({ mode: null, data: null })}
        />
      )}
      {action.mode === 'review-onboarding' && (
        <ReviewOnboarding
          action={action}
          setAction={setAction}
          customerData={customerOnboardData}
          handleClose={() => setAction({ mode: null, data: null })}
        />
      )}
    </PageSectionLayout>
  );
};
export default CreditDashboard;
