import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState } from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName } from 'src/utils/CommonFunctions';

import { ILeadRowProps } from './LoadBoard.interface';
// import MoveToActive from './MoveToActive';

const LoadBoardRow = ({ data }: ILeadRowProps) => {
  const [isShowTypeInfoModal, setIsShowTypeInfoModal] = useState(false);
  // const selectedCarrierRates = null;
  // const currency = null;

  return (
    <>
      <td className="px-5 py-4 max-w-52 w-52 min-w-52">
        <div className="flex items-center">
          <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
            {getShortName(data.company)}
          </div>
          <TooltipCmp message={data.company} parentClassName="">
            <span className="truncate block max-w-32 text-grayLight900 font-semibold leading-5 ml-3">
              {data.company}
            </span>
          </TooltipCmp>
        </div>
      </td>
      <td className="px-5 py-4">
        <span>{data.companyPhone}</span>
      </td>
      <td className="px-5 py-4">
        <span>{data.dfo}</span>
      </td>
      <td className="px-5 py-4">
        <span>{data.vehicleSize}</span>
      </td>
      <td className="px-5 py-4">
        <span>{moment(data.dateAvailability).format('MMM. Do - YYYY')}</span>
      </td>
      <td className="px-5 py-4 min-w-44 w-44 max-w-44">
        <span>{moment(data.createdOn).format('MMM. Do - YYYY')}</span>
      </td>
      <td className="px-5 py-4 max-w-52 w-52 min-w-52">
        <TooltipCmp message={data.comment} parentClassName="">
          <div className="truncate">{data.comment || '-'}</div>
        </TooltipCmp>
      </td>
      <td
        className="px-5 py-4 max-w-16 w-16 min-w-16"
        onClick={() => setIsShowTypeInfoModal(true)}
      >
        <ArrowUp className="w-4 h-4 text-primary cursor-pointer" />
      </td>
      {isShowTypeInfoModal &&
        // <MoveToActive
        //   handleClose={() => setIsShowTypeInfoModal(false)}
        //   selectedCarrierRates={selectedCarrierRates}
        //   currency={currency}
        // />
        null}
    </>
  );
};

export default LoadBoardRow;
