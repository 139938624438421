import { DownloadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const LoadInsuranceLoading = () => (
  <>
    <td className="px-5 py-4 w-[19.2%] min-w-[19.2%] max-w-[1px]">
      <div className="flex items-center">
        <div className="w-7 h-7 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white custom-loading">
          WA
        </div>
        <div className="ml-2 text-xs font-medium truncate max-w-[70%] custom-loading">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            Jordan Bilerman
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            #LD-INS-200004
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 w-[19.2%] max-w-[19.2%] min-w-[19.2%]">
      <span className="custom-loading">#WAL-ALLT-24</span>
    </td>
    <td className="px-5 py-4">
      <div className="flex flex-wrap gap-1">
        <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
          Approved
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 w-[19.2%] max-w-[19.2%] min-w-[19.2%]">
      <span className="custom-loading">Test Comodity</span>
    </td>
    <td className="px-5 py-4 w-[19.2%] max-w-[19.2%] min-w-[19.2%]">
      <span className="custom-loading">12345</span>
    </td>
    <td className="px-5 py-4 w-[64px] max-w-[64px] min-w-[64px]">
      <div className="custom-loading">
        <DownloadCloud02 className="w-4 h-4" />
      </div>
    </td>
  </>
);

export default LoadInsuranceLoading;
