import { SearchLg, UsersPlus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import {
  createCustomerGroup,
  CustomersAllList,
  updateCustomerGroup,
} from 'src/services/CustomerService';
import { getShortName, onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

function arraysAreEqual(arr1: number[], arr2: number[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays
  arr1.sort((a, b) => a - b);
  arr2.sort((a, b) => a - b);

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

interface IProps {
  isUpdateCustomerGroup?: boolean;
  createCustomersList?: any[];
  currentGroupDetail?: any;
  handleClose: any;
}

const CreateCustomerGroupModal = ({
  isUpdateCustomerGroup,
  currentGroupDetail,
  createCustomersList,
  handleClose,
}: IProps) => {
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [showMemberListOriginal, setShowMemberListOriginal] = useState([]);
  const [isShowMemberList, setIsShowMemberList] = useState(false);
  const [showMemberListSearchValue, setShowMemberListSearchValue] =
    useState('');
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [showMemberList, setShowMemberList] = useState([]);
  const [errors, setErrors] = useState<any>(undefined);
  const [groupName, setGroupName] = useState(
    isUpdateCustomerGroup ? currentGroupDetail?.groupName : ''
  );
  const [isCustomerGroupCreateLoading, setIsCustomerGroupCreateLoading] =
    useState(false);

  const getShowFilteredMemberList = (
    searchValue: string,
    selectedMembersIdList: number[],
    memberListOriginal: any
  ) => {
    const filteredList = memberListOriginal.filter(
      (originalMemberData: any) => {
        const fullName = originalMemberData?.name;

        const isFullNameMatched = fullName
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim());

        const isIdMatchedWithSelectedMembers = selectedMembersIdList.includes(
          originalMemberData.id
        );

        return isFullNameMatched && !isIdMatchedWithSelectedMembers;
      }
    );

    return [...filteredList] as any;
  };

  const selectMembers = (memberData: any) => {
    setSelectedMembers([...selectedMembers, memberData]);
  };

  const handleRemove = (memberData: any) => {
    setSelectedMembers([
      ...selectedMembers.filter((sm) => sm.id !== memberData.id),
    ]);
  };

  useEffect(() => {
    const selectedMembersIds = selectedMembers.map((sm) => sm.id);
    const filteredList = getShowFilteredMemberList(
      showMemberListSearchValue,
      selectedMembersIds,
      showMemberListOriginal
    );
    setShowMemberList(filteredList);
  }, [selectedMembers, showMemberListSearchValue]);

  useEffect(() => {
    CustomersAllList()
      .then((result: any) => {
        if (result.data && result.data.length) {
          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          setShowMemberListOriginal(result.data);

          let filteredList = [];

          if (isUpdateCustomerGroup) {
            const customerGroupId = currentGroupDetail?.customers.map(
              (e: any) => e.id
            );
            filteredList = getShowFilteredMemberList(
              '',
              customerGroupId ?? [],
              result.data
            );
          } else {
            const customerGroupId = createCustomersList?.map((e: any) => e.id);
            filteredList = getShowFilteredMemberList(
              '',
              customerGroupId ?? [],
              result.data
            );
          }

          setShowMemberList(filteredList);
        }
      })
      .finally(() => setIsCustomerLoading(false))
      .catch(console.error);
  }, []);

  const handleCreateUpdateCustomerGroup = () => {
    let errorObj: any = {};

    if (!groupName || groupName === '') {
      errorObj.groupName = 'Enter customer group name.';
    }

    const customerIds = selectedMembers.map((e) => e.id);

    if (customerIds?.length <= 0) {
      errorObj.customers = `Select at least one customer to ${
        isUpdateCustomerGroup ? 'update' : 'create'
      } group.`;
    }

    if (errorObj.groupName || errorObj.customers) {
      console.log(errorObj);
      setErrors(errorObj);

      return;
    } else {
      setErrors(undefined);
    }

    if (isUpdateCustomerGroup) {
      const actualCustomerIds = currentGroupDetail?.customers.map(
        (cg: any) => cg?.id
      );

      const isCustomersListUnchanged = arraysAreEqual(
        actualCustomerIds,
        customerIds
      );

      let updatePayload: any = {
        groupName: groupName,
      };

      if (!isCustomersListUnchanged) {
        updatePayload.customerIds = customerIds;
      }

      setIsCustomerGroupCreateLoading(true);
      updateCustomerGroup(currentGroupDetail?.id, updatePayload)
        .then((response: any) => {
          WalToast.success(
            response?.message ?? 'Customer group updated successfully'
          );
          handleClose(true);
        })
        .finally(() => {
          setIsCustomerGroupCreateLoading(false);
        })
        .catch((e) => {
          console.log(e);
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong creating customer group.'
          );
        });
    } else {
      setIsCustomerGroupCreateLoading(true);
      createCustomerGroup({ groupName: groupName, customerIds: customerIds })
        .then((response: any) => {
          WalToast.success(
            response?.message ?? 'Customer group created successfully'
          );
          handleClose(true);
        })
        .finally(() => {
          setIsCustomerGroupCreateLoading(false);
        })
        .catch((e) => {
          console.log(e);
          WalToast.error(
            e?.response?.data?.message ??
              'Something went wrong creating customer group.'
          );
        });
    }
  };

  useEffect(() => {
    if (isUpdateCustomerGroup) {
      const selectedCustomersRec = currentGroupDetail.customers.filter(
        (sm: any) => sm
      );
      setSelectedMembers([...selectedCustomersRec]);
    } else {
      const selectedCustomersRec: any = createCustomersList?.filter(
        (sm: any) => sm
      );
      setSelectedMembers([...selectedCustomersRec]);
    }
  }, [isUpdateCustomerGroup, currentGroupDetail, createCustomersList]);

  return (
    <CommonModal
      title={`${
        isUpdateCustomerGroup ? 'Update' : 'Create'
      } a new customer group`}
      titleDesc={'Managing customers in effective group formation.'}
      handleClose={() => handleClose(false)}
      headerIcon={<UsersPlus />}
      size={'max-w-[500px] overflow-unset'}
      isOverflow={false}
      primaryBtnText={`${isUpdateCustomerGroup ? 'Update' : 'Create'}`}
      primaryBtnOnClick={handleCreateUpdateCustomerGroup}
      primaryBtnLoading={isCustomerGroupCreateLoading}
      primaryBtnDisabled={isCustomerGroupCreateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      secondaryBtnDisabled={isCustomerGroupCreateLoading}
    >
      <div className="w-full p-5 flex flex-col gap-4">
        <InputText
          label="Group Name"
          labelClassName="block mb-1.5"
          inputName="groupName"
          placeholder="Enter Group Name"
          parentClassName=""
          value={groupName}
          onChangeFunc={(e) => setGroupName(e?.target?.value)}
          errorText={errors?.groupName}
        />
        <div className="w-full">
          <div className="mb-4 relative">
            <InputText
              label={'Select a customer'}
              inputName="teamSearch"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={showMemberListSearchValue}
              onChangeFunc={(e: any) =>
                setShowMemberListSearchValue(e.target.value)
              }
              onFocus={() => setIsShowMemberList(true)}
              onBlur={() => {
                setTimeout(() => {
                  setIsShowMemberList(false);
                }, 200);
              }}
              isLoading={isCustomerLoading}
              errorText={errors?.customers}
              isClearable={true}
            />
            {showMemberList?.length > 0 && (
              <div
                className={` mt-3 w-full max-h-[235px] team-user-select overflow-auto py-4 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 ${
                  isShowMemberList ? 'absolute' : 'hidden'
                }`}
              >
                {showMemberList.map((memberList: any) => (
                  <div
                    key={`member_select_list_${memberList.id}`}
                    className="w-full rounded-lg cursor-pointer hover:bg-gray50"
                    onClick={() => selectMembers(memberList)}
                  >
                    <div className="px-3.5 py-2.5  justify-start  gap-2 flex items-center">
                      {memberList.image ? (
                        <img
                          className="w-9 h-9 rounded-full border-black/[0.08] border-[0.75px]"
                          src={memberList.imageUrl + memberList.image}
                          alt={memberList.name}
                          title={memberList.name}
                          onError={onError}
                        />
                      ) : (
                        <div className="w-9 h-9 bg-primary rounded-full text-white flex items-center justify-center uppercase border-black/[0.08] border-[0.75px] flex-none">
                          {getShortName(memberList.name)}
                        </div>
                      )}
                      <div className="flex-col justify-start items-start flex ">
                        <div className="text-grayLight900 text-xs font-medium leading-tight">
                          {memberList.name}
                        </div>
                        <div className="text-grayLight600 text-sm font-normal leading-tight break-all">
                          {memberList.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex-1 overflow-y-auto scrollbar-hide max-h-[calc(100vh_-_500px)]">
            {selectedMembers.map((selectedMember) => (
              <div
                key={selectedMember.name}
                className="w-full py-[9px]  border-t justify-between items-center flex border-utilityGray200 last:border-b"
              >
                <div className="justify-start items-center gap-3 flex">
                  <div className="w-9 h-9 relative rounded-full flex-none">
                    {selectedMember.image ? (
                      <img
                        className="w-full h-full rounded-full border-black border-opacity-10"
                        src={selectedMember.imageUrl + selectedMember.image}
                        alt={selectedMember.name}
                        title={selectedMember.name}
                        onError={onError}
                      />
                    ) : (
                      <div className="w-9 h-9 bg-primary rounded-full text-white flex items-center justify-center uppercase">
                        {getShortName(selectedMember.name)}
                      </div>
                    )}
                  </div>

                  <div className="flex-col justify-start items-start flex">
                    <div className="justify-center items-center gap-3 flex">
                      <div className="text-textSecondary text-xs font-semibold leading-tight">
                        {selectedMember.name}
                      </div>
                    </div>
                    <div className="text-grayLight600 text-xs font-normal leading-tight">
                      {selectedMember.email}
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-1.5 flex">
                  <ButtonCmp
                    className="text-danger700 text-sm font-semibold leading-tight cursor-pointer btn-link no-underline !shadow-none"
                    onClick={() => handleRemove(selectedMember)}
                    type="button"
                  >
                    Remove
                  </ButtonCmp>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default CreateCustomerGroupModal;
