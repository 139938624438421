import {
  CheckSquareBroken,
  InfoCircle,
  Share03,
  XClose,
} from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
} from 'src/utils/CommonFunctions';
// import BadgeCmp from 'src/components/BadgeCmp';

// import user from '../../../../assets/img/carrier1.png';

const ConflictsCommissionReportRow = ({ data, setAction }: any) => (
  <>
    <td className="px-5 py-4 pr-0 w-8 min-w-8 max-w-8">
      <InfoCircle className="w-4 h-4 text-fgErrorPrimary" />
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700 cursor-pointer">
        <a
          href={`https://wal.roserocket.com/#/ops/orders/${data.roserocketOrderId}`}
          className="text-primary700 font-normal leading-tight truncate underline"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          #{data.fullID}
        </a>
      </h6>
    </td>

    <td className="px-5 py-4  w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <div className="flex gap-1 flex-wrap">
        {(data?.orderStatus === 'new' ||
          data?.orderStatus === 'saved' ||
          data?.orderStatus === 'quoted' ||
          data?.orderStatus === 'spot-qq-requested' ||
          data?.orderStatus === 'spot-quote-requested' ||
          data?.orderStatus === 'pending' ||
          data?.orderStatus === 'draft-quick-quote' ||
          data?.orderStatus === 'quick-quoted' ||
          data?.orderStatus === 'reject' ||
          data?.orderStatus === 'archived' ||
          data?.orderStatus === 'expired') && (
          <BadgeCmp style="modern" type="gray">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'no-quote' ||
          data?.orderStatus === 'no-quick-quote' ||
          data?.orderStatus === 'cancelled') && (
          <BadgeCmp style="modern" type="pink">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'tender' ||
          data?.orderStatus === 'booked' ||
          data?.orderStatus === 'invoice-paid' ||
          data?.orderStatus === 'delivered') && (
          <BadgeCmp style="modern" type="lightblue">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'invoice-created' ||
          data?.orderStatus === 'invoice-sent') && (
          <BadgeCmp style="modern" type="error">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {/* Tags */}
        {data?.orderStatus === 'dispatched' && (
          <BadgeCmp style="modern" type="pink">
            {data?.orderStatus}
          </BadgeCmp>
        )}

        {/* <BadgeCmp style="modern" type="success">
            New
          </BadgeCmp>
          <BadgeCmp style="modern" type="primary">
            Dispatched
          </BadgeCmp>
          <BadgeCmp style="modern" type="warning">
            Invoice- created
          </BadgeCmp> */}
      </div>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <span>
        {' '}
        <span
          dangerouslySetInnerHTML={{
            __html: data?.orderCreatedAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data?.orderCreatedAt,
                    `MMM Do - YYYY, hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        ></span>
      </span>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <span>${data?.combinedCost}</span>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <span>${data?.combinedRevenue}</span>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <span>${data?.combinedMargin}</span>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <div className="flex items-center">
        {data?.month} {data?.year}
        <Share03 className="ml-2 text-gray500 w-4 h-4 cursor-pointer hover:text-primary" />
      </div>
    </td>
    <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
      <div className="justify-center items-center gap-3 flex ">
        <CheckSquareBroken
          className="w-4 h-4 text-fgSuccessPrimary"
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ type: 'resolve', orderId: data?.fullID },
            }))
          }
        />
        <XClose
          className="w-4 h-4 text-fgErrorPrimary"
          onClick={() =>
            setAction((old: any) => ({
              ...old,
              ...{ type: 'remove', orderId: data?.fullID },
            }))
          }
        />
      </div>
    </td>
  </>
);

export default ConflictsCommissionReportRow;
