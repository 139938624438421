import {
  ArrowDown,
  ArrowLeft,
  ArrowUp,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';

import defaultimage from '../../../assets/img/default-image.jpg';

import CustomerGroupOrderLoadingRaw from './CustomerGroupOrderLoadingRaw';
import CustomerGroupOrderRaw from './CustomerGroupOrderRaw';

const CustomerGroupDetail = () => {
  const [activeTab, setActiveTab] = useState('overview');
  let TabArray = [
    {
      value: 'overview',
      name: 'Overview',
    },
    {
      value: 'analytics',
      name: 'Analytics',
    },
  ];

  const providerArr = [
    { label: 'Banyan', value: 'banyan' },
    { label: 'Freightcom', value: 'freightcom' },
    { label: 'Direct', value: 'direct' },
  ];

  const recordsPerPageArray = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const headCells = useMemo(
    () => [
      {
        id: 'fullId',
        name: 'Order ID',
        sortable: true,
      },

      {
        id: 'orderCreatedAt',
        name: 'Order Created',
        sortable: true,
      },
      {
        id: 'combinedCost',
        name: 'Total Cost',
        sortable: true,
      },
      {
        id: 'totalRevenue',
        name: 'Total Revenue',
        sortable: true,
      },
      {
        id: 'combinedMargin',
        name: 'Margin',
        sortable: true,
      },
      {
        id: 'combinedRevenue',
        name: 'Total Price',
        sortable: true,
      },
      {
        id: 'orderCommissionees',
        name: 'Sales Representative',
        sortable: true,
      },
    ],
    []
  );

  return (
    <div className="z-[0] relative bg-white h-full flex flex-col before:[''] xl:before:h-[130px] mdm:before:h-[100px] sm:before:h-[102px] before:h-[70px] before:w-full before:absolute before:top-0 before:bg-[url('../../assets/img/vector-square.svg')] before:bg-gray50 before:bg-no-repeat before:bg-contain before:-z-[1] before:left-0 before:bg-right">
      <div className="pt-5 pb-3 xlm:px-[30px] px-5 flex items-center justify-between gap-1.5">
        <div className="flex items-center cursor-pointer gap-1.5">
          <ArrowLeft className="h-4 w-4" />
          <span className="text-grayLight600 text-xs font-semibold">
            Back to Teams{' '}
          </span>
        </div>
        <div className="bg-utilityGray200 rounded-full xl:p-2 sm:p-1.5 p-1 cursor-pointer">
          <XClose className="xl:w-5 xl:h-5 w-4 h-4" />
        </div>
      </div>
      <div className=" xlm:px-8 px-5 relative w-full xl:pt-[10px] sm:pt-2 pt-[26px] pb-[26px] flex max-sm:flex-col sms:gap-4 gap-2 sm:items-end justify-between">
        <div className="flex md:gap-4 gap-2 smd:items-end w-full">
          <label
            className={`relative [&>div:nth-child(2)]:opacity-0 hover:[&>div:nth-child(2)]:opacity-100 xlm:w-36 xl:w-36 mdm:w-28 sm:w-24 w-16 xlm:h-36 xl:h-36 mdm:h-28 sm:h-24 h-16 rounded-xl overflow-hidden justify-center items-center flex border-4 border-white bg-utilityGray100 shadow-lg text-primary xl:text-6xl sm:text-4xl sms:text-2xl text-base font-semibold tracking-[-1.2px] flex-none `}
          >
            <img
              className="w-full h-full relative"
              src={defaultimage}
              alt="House Team"
              title="House Team"
            />
          </label>

          <div className="smd:flex-1 flex smd:flex-row flex-col justify-between sm:mt-2 gap-2 smd:items-end">
            {/* <div className="flex justify-between"> */}
            <div className="">
              <h6
                className={`mr-3 text-grayLight900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]  `}
              >
                Wal Team
              </h6>

              <div className={`flex mt-1 `}>
                <span className="text-grayLight600 sm:text-sm text-xs sm:mt-0 mt-0.5 font-normal">
                  4 Members
                </span>
              </div>
            </div>

            <div className="flex gap-4 smd:pt-2">
              <ButtonCmp type="button" className="btn-outline-primary">
                Edit Group
              </ButtonCmp>
              <ButtonCmp type="submit" className="btn_secondary_black">
                Delete Group
              </ButtonCmp>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-utilityGray200 xlm:px-8 px-5 py-[30px] bg-white flex flex-wrap flex-1 items-start ">
        <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_312px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8 h-full">
          <TabButton
            parentClassName="!bg-gray50 w-full overflow-x-auto scrollbar-hide text-nowrap"
            className="rounded-md text-gray500 !border-0 w-[193px]"
            tabParentClassName="!block"
            activeClassName="!bg-primary400 !text-blue"
            tabArray={TabArray}
            isActive={activeTab}
            handleOnClick={(e: any) => {
              setActiveTab(e.target.dataset.value);
            }}
            isTab={true}
          />
          {activeTab === 'overview' && (
            <>
              <div className="flex items-center justify-between mt-8 flex-wrap">
                <div>
                  <h6 className="text-grayLight900 text-lg font-semibold sm:mb-0 mb-1">
                    Key Metrics
                  </h6>
                </div>

                <div className="p-1 bg-gray-50 rounded-[10px] overflow-auto text-nowrap border border-utilityGray200 cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full">
                  <div
                    className={`sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex `}
                  >
                    <div className="text-xs leading-tight">30 Days</div>
                  </div>
                  <div
                    className={`sm:basis-auto basis-full px-3 py-1.5 justify-center items-center gap-2 flex `}
                  >
                    <div className=" text-xs leading-tight">90 Days</div>
                  </div>
                  <div
                    className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex `}
                  >
                    <div className="text-xs leading-tight">180 Days </div>
                  </div>
                  <div
                    className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex`}
                  >
                    <div className="text-xs leading-tight">360 Days </div>
                  </div>
                  <div
                    className={`sm:basis-auto basis-full px-3 py-1.5 rounded-md justify-center items-center gap-2 flex `}
                  >
                    <div className="text-xs leading-tight">All Time </div>
                  </div>
                </div>
              </div>
              <ul className="flex -m-2 flex-wrap mt-1.5">
                <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
                  <div className="rounded-lg border border-utilityGray200 p-3">
                    <div className="w-full justify-between flex items-start">
                      <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                        Total Orders
                      </h6>
                    </div>
                    <div className="w-full flex justify-between pt-2 ">
                      <div className="w-full flex flex-col justify-end">
                        <div className={``}>
                          <span className="font-semibold text-2xl">45</span>
                        </div>

                        <div className="w-full flex  justify-between ">
                          <div
                            className={`w-fit flex justify-center items-center mt-2 `}
                          >
                            {/* IF ARROW UP NEEDED */}
                            <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                            {/* IF ARROW DOWN NEEDED */}
                            <ArrowDown className="w-4 h-4 flex-none  text-danger" />

                            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                              <span className="xxl:text-sm text-xs">25 %</span>{' '}
                              &nbsp;vs. Last month
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
                  <div className="rounded-lg border border-utilityGray200 p-3">
                    <div className="w-full justify-between flex items-start">
                      <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                        Total Margin
                      </h6>
                    </div>
                    <div className="w-full flex justify-between pt-2 ">
                      <div className="w-full flex flex-col justify-end">
                        <div className={``}>
                          <span className="font-semibold text-2xl">45</span>
                        </div>

                        <div className="w-full flex  justify-between ">
                          <div
                            className={`w-fit flex justify-center items-center mt-2 `}
                          >
                            {/* IF ARROW UP NEEDED */}
                            <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                            {/* IF ARROW DOWN NEEDED */}
                            <ArrowDown className="w-4 h-4 flex-none  text-danger" />

                            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                              <span className="xxl:text-sm text-xs">25 %</span>{' '}
                              &nbsp;vs. Last month
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
                  <div className="rounded-lg border border-utilityGray200 p-3">
                    <div className="w-full justify-between flex items-start">
                      <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                        Margin Percentage
                      </h6>
                    </div>
                    <div className="w-full flex justify-between pt-2 ">
                      <div className="w-full flex flex-col justify-end">
                        <div className={``}>
                          <span className="font-semibold text-2xl">45</span>
                        </div>

                        <div className="w-full flex  justify-between ">
                          <div
                            className={`w-fit flex justify-center items-center mt-2 `}
                          >
                            {/* IF ARROW UP NEEDED */}
                            <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                            {/* IF ARROW DOWN NEEDED */}
                            <ArrowDown className="w-4 h-4 flex-none  text-danger" />

                            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                              <span className="xxl:text-sm text-xs">25 %</span>{' '}
                              &nbsp;vs. Last month
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="p-2 xl:w-1/4 sms:w-1/2 w-full">
                  <div className="rounded-lg border border-utilityGray200 p-3">
                    <div className="w-full justify-between flex items-start">
                      <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
                        Average Order Value
                      </h6>
                    </div>
                    <div className="w-full flex justify-between pt-2 ">
                      <div className="w-full flex flex-col justify-end">
                        <div className={``}>
                          <span className="font-semibold text-2xl">45</span>
                        </div>

                        <div className="w-full flex  justify-between ">
                          <div
                            className={`w-fit flex justify-center items-center mt-2 `}
                          >
                            {/* IF ARROW UP NEEDED */}
                            <ArrowUp className="w-4 h-4 flex-none  text-successSecondary" />
                            {/* IF ARROW DOWN NEEDED */}
                            <ArrowDown className="w-4 h-4 flex-none  text-danger" />

                            <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
                              <span className="xxl:text-sm text-xs">25 %</span>{' '}
                              &nbsp;vs. Last month
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mt-6 overflow-hidden">
                <div className="w-full flex-col flex">
                  <div className="table-top-header flex-wrap ">
                    <div className="table-left-wrap ">
                      <div className="table-title-wrap">
                        <h5 className="table-title">All Orders</h5>
                        <BadgeCmp style="modern" type="success">
                          15 Orders
                        </BadgeCmp>
                      </div>
                      <p className="table-subtitle">
                        View complete order history for this customer.
                      </p>
                    </div>
                    <div className="table-right-wrap max-mdm:w-full">
                      <InputText
                        inputName="searchCustomer"
                        placeholder="Search"
                        className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                        icon={
                          <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                        }
                        value={'search'}
                        isClearable={true}
                        parentClassName="table-searchInput max-mdm:flex-1"
                        inputType="text"
                      />
                      <div className="table-statustbox">
                        <SelectBox
                          name="provider"
                          id="provider"
                          className="form_control"
                          size="sm"
                          placeholder="Filter by provider"
                          isClearable={true}
                          isSearchable={false}
                          options={providerArr}
                          value={''}
                        />
                      </div>
                      <div className="table-recordsPerPage">
                        <SelectBox
                          name="recordsPerPageGroup"
                          id="recordsPerPageGroup"
                          className="form_control shadow"
                          size="sm"
                          options={recordsPerPageArray}
                          isSearchable={false}
                          value={'search'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full bg-white overflow-x-auto custom-scrollbar scrollbar-hide text-nowrap">
                    <TableCmp
                      headCells={headCells}
                      params={() => {}}
                      setParams={() => {}}
                      tableDataArr={[{}]}
                      TableRowCmp={CustomerGroupOrderRaw}
                      TableLoaderRowCmp={CustomerGroupOrderLoadingRaw}
                      numberOfSkeletonRows={15}
                    />
                  </div>

                  <NotFoundUI
                    title="No latest orders found"
                    desc="There are no Latest orders found."
                    containerClassName="min-h-[160px] !h-auto"
                  />

                  <CustomPagination
                    recordsPerPage={1}
                    totalRecords={15}
                    currentPage={1}
                    handlePagination={() => {}}
                  />
                </div>
              </div>
            </>
          )}

          {activeTab === 'analytics' && (
            <NotFoundUI
              title="Coming Soon"
              desc="Lorem Ipsum is simply dummy text of the printing and type-setting-industry. Lorem Ipsum has been the industry's standard."
              containerClassName="my-0 flex-1"
              contentClassName="before:bg-[url('../../assets/img/not-found-images/coming-soon.png')] min-w-[280px]"
              iconClassName="!hidden"
              iconSecondaryClassName="!flex"
            />
          )}
        </div>
        <div className="xl:w-[306px] xls:w-[280px]  w-full xl:ml-8 xls:ml-4 lg:ml-2 ml-0 flex flex-col gap-y-4 border-b border-utilityGray200 pb-4">
          <h6 className="text-grayLight900 font-medium text-sm">
            Customers&nbsp;(4)
          </h6>
          <div className="rounded-xl border border-utilityGray200 p-3 shadow-none transition-all duration-[0.3s] hover:shadow-md cursor-pointer">
            <div className="">
              <div className="flex gap-1.5">
                <img
                  src={defaultimage}
                  className="w-8 h-8  rounded-md text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                />
                {/* if no image below code will be used */}
                {/* <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                  TT
                </div> */}
                <div>
                  <h6 className="text-grayLight900 text-xs font-semibold mb-0.5">
                    HC Companies
                  </h6>
                  <p className="text-grayLight600 text-xs font-normal">
                    2425 Boul Edouard-Michelin, Terrebonne, CA
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 empty:mt-0 mt-4">
                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-yellow300"
                  mainClassName=""
                >
                  Test
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-[#B2AC17]"
                  mainClassName=""
                >
                  Top
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-utilityPink600"
                  mainClassName=""
                >
                  Dormat
                </BadgeCmp>

                <BadgeCmp style="modern" type="lightblue" mainClassName="">
                  Prospect
                </BadgeCmp>

                <BadgeCmp
                  style="modern"
                  badgeDotColor="bg-fgSuccessPrimary"
                  mainClassName=""
                >
                  New
                </BadgeCmp>

                <BadgeCmp style="modern" type="primary" mainClassName="">
                  Active
                </BadgeCmp>

                <BadgeCmp style="modern" type="error" mainClassName="">
                  Inactive
                </BadgeCmp>

                <BadgeCmp style="modern" type="gray" mainClassName="">
                  At-Risk
                </BadgeCmp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerGroupDetail;
