import { yupResolver } from '@hookform/resolvers/yup';
import { Check, Edit05, InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AccordionCmp from 'src/components/AccordionCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import StepBar from 'src/components/StepBar/StepBar';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { bookOrder } from 'src/services/QuoteService';
import { getFormattedNumber, onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import defaultImage from '../../../../assets/img/default-image.jpg';
import Logo from '../../../../assets/img/logo2.svg';
import truckLoader from '../../../../assets/img/truckloader.gif';

interface IProps {
  handleClose: any;
  carrierRate: any;
  currency: any;
  addressDetails: any;
  dimensions: any;
  connectSocket: any;
}

interface FormData {
  contactName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  shipperName: string;
  shipperEmail: string;
  shipperPhoneNumber: string;
  consigneeName: string;
  consigneeEmail: string;
  consigneePhoneNumber: string;
}

// const phoneRegExp = /^(\+?1?[-.\s]?)?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

const validationSchema = yup.object().shape({
  contactName: yup.string().required('Contact name is required'),
  contactEmail: yup
    .string()
    .email('Invalid email')
    .required('Contact email is required'),
  contactPhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Contact phone is required'),
  shipperName: yup.string().required('Shipper name is required'),
  shipperEmail: yup
    .string()
    .email('Invalid email')
    .required('Shipper email is required'),
  shipperPhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Shipper phone is required'),
  consigneeName: yup.string().required('Consignee name is required'),
  consigneeEmail: yup
    .string()
    .email('Invalid email')
    .required('Consignee email is required'),
  consigneePhoneNumber: yup
    .string()
    // .matches(phoneRegExp, 'Invalid phone number')
    .required('Consignee phone is required'),
});

const FinalizeOrderModal = ({
  handleClose,
  carrierRate,
  currency,
  addressDetails,
  dimensions,
  connectSocket,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [quotedServices, setQuotedServices] = useState<any[]>([]);
  const [nonQuotedServices, setNonQuotedServices] = useState<any[]>([]);

  const headerIcon = (
    <img
      src={Logo}
      alt="logo-light"
      className="object-covert h-full w-full rounded-[10px] border border-utilityGray200"
    />
  );

  const STEPS = [
    {
      name: 'Confirm Order Details',
      step: 1,
    },
    {
      name: 'Additional Information',
      step: 2,
    },
    {
      name: 'Finalize Order Creation',
      step: 3,
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = (data: FormData) => {
    setIsLoading(true);
    connectSocket();
    bookOrder(data, carrierRate.id)
      .then(() => {
        // handleClose(true, 'informationModel')();
        // handleClose(response.data);
        setActiveStep(3);
      })
      .catch(() =>
        WalToast.error("Can't book this order, some details missing")
      )
      .finally(() => {
        // handleClose(true, 'informationModel')();

        setIsLoading(false);
      });
  };

  const handleNext = () => {
    if (activeStep === 1) {
      setActiveStep(2);
    } else if (activeStep === 2) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    if (carrierRate?.services?.length) {
      setQuotedServices(
        carrierRate?.services?.filter((service: any) => service.isQuoted)
      );
      setNonQuotedServices(
        carrierRate?.services?.filter((service: any) => !service.isQuoted)
      );
    }
  }, [carrierRate]);

  return (
    <CommonModal
      // title={`Finalize Order Creation with ${carrierRate.name}`}
      title={
        activeStep === 1
          ? `Confirm Order with ${carrierRate.name}`
          : activeStep === 2
          ? `Additional Information with ${carrierRate.name}`
          : activeStep === 3
          ? `Finalize Order Creation With ${carrierRate.name}`
          : `Finalize Order Creation with ${carrierRate.name}`
      }
      titleDesc={
        activeStep === 3 ? (
          <p className="max-w-[340px] mx-auto text-wrap">
            Simplify and Secure Your Order Confirmation Process with $
            {carrierRate.name}
          </p>
        ) : (
          <p className="max-w-[340px] mx-auto text-wrap">
            Streamlining the final steps of order creation for faster and more
            accurate transactions with {carrierRate.name}
          </p>
        )
      }
      handleClose={() => handleClose(false, 'carrierOrderModel')()}
      headerIcon={headerIcon}
      headerSecondIcon={
        <img
          src={`${carrierRate?.imageUrl}${carrierRate?.image}`}
          className="object-covert h-full w-full rounded-[10px] border border-utilityGray200"
          alt={defaultImage}
          title={carrierRate?.name}
          onError={onError}
        />
      }
      headerIconClass="border-0 md:!h-[48px] !h-12 md:!w-[48px] !w-12 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-[-5px] sm:before:-left-[69px] before:-left-[53px] before:opacity-50"
      headerIconSecondClass="border-0 md:!h-[48px] !h-12 md:!w-[48px] !w-12 !p-0 !inline-flex items-center relative ml-2 after:content-[''] after:h-[26px] after:w-[26px] after:border-utilityGray200 after:border after:rounded-full after:absolute  after:bg-[url('../../assets/img/link-01.svg')] after:bg-no-repeat after:bg-center after:bg-white after:top-1/2 after:-translate-y-1/2 after:-left-[22px] before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-[-5px] sm:before:-right-[69px] before:-right-[53px] before:opacity-50"
      size={'max-w-[780px]'}
      primaryBtnText="Continue"
      secondaryBtnText="Cancel"
      modalHeaderTextClass="!block text-center flex-1"
      headerInfoClass="mt-[18px]"
      modalHeaderClass="!pt-5"
      primaryBtnOnClick={handleNext}
      primaryBtnLoading={isLoading}
      secondaryBtnOnClick={() => handleClose(false, 'carrierOrderModel')()}
    >
      <div className="w-full p-5">
        <StepBar
          steps={STEPS}
          activeStep={activeStep}
          stepsParentClass="!justify-center mb-6"
          stepClass="max-sm:flex-col max-sm:text-center"
        />

        {activeStep === 1 && (
          <div className="flex flex-col gap-y-4 ">
            <div className="flex flex-wrap -mx-1 gap-y-2">
              <InputText
                label="Pickup Date"
                inputName="name"
                placeholder="Pickup Date"
                parentClassName="px-1 sm:w-1/2 w-full"
                className=""
                value={'01 February, 2024'}
                disabled={true}
                labelClassName="block mb-1.5"
              />
              <InputText
                label="Delivery Date"
                inputName="name"
                placeholder="Delivery Date"
                parentClassName="px-1 sm:w-1/2 w-full"
                className=""
                value={'22 March, 2024 (5 Business Days)'}
                disabled={true}
                labelClassName="block mb-1.5"
              />
            </div>
            <div className="flex flex-wrap -mx-1 gap-y-2">
              <InputText
                label="Base Cost"
                inputName="name"
                placeholder="Base Cost"
                parentClassName="px-1 sm:w-1/3 w-full"
                className=""
                value={
                  currency === CURRENCY.CAD
                    ? `$${getFormattedNumber(carrierRate.totalCharge)}`
                    : `$${getFormattedNumber(carrierRate.totalChargeUSD)}`
                }
                disabled={true}
                labelClassName="block mb-1.5"
              />
              <InputText
                label="Additional Services"
                inputName="name"
                placeholder="Additional Services"
                parentClassName="px-1 sm:w-1/3 w-full"
                className=""
                value={
                  currency === CURRENCY.CAD
                    ? `$${getFormattedNumber(carrierRate.additionalCharges)}`
                    : `$${getFormattedNumber(carrierRate.additionalChargesUSD)}`
                }
                disabled={true}
                labelClassName="block mb-1.5"
              />
              <InputText
                label="Total Cost"
                inputName="name"
                placeholder="Total Cost"
                parentClassName="px-1 sm:w-1/3 w-full"
                className=""
                value={
                  currency === CURRENCY.CAD
                    ? `$${getFormattedNumber(carrierRate.totalCostCAD)}`
                    : `$${getFormattedNumber(carrierRate.totalCostUSD)}`
                }
                disabled={true}
                labelClassName="block mb-1.5"
              />
            </div>
            <div>
              <div className="flex gap-x-1 justify-between items-center mb-1.5">
                <label className="form_label block mb-0">Shipper Address</label>
                <div className="gap-x-1 text-primary700 text-xs font-semibold flex items-center">
                  <Edit05 className="w-4 h-4" />
                  Update
                </div>
              </div>
              <InputText
                inputName="name"
                placeholder="Shipper Address"
                parentClassName=""
                className=""
                value={addressDetails.shipperFullAddress}
                disabled={true}
                labelClassName="block mb-1.5"
              />
            </div>
            <div>
              <div className="flex gap-x-1 justify-between items-center mb-1.5">
                <label className="form_label block mb-0">
                  Consignee Address
                </label>
                <div className="gap-x-1 text-primary700 text-xs font-semibold flex items-center">
                  <Edit05 className="w-4 h-4" />
                  Update
                </div>
              </div>
              <InputText
                inputName="name"
                placeholder="Consignee Address"
                parentClassName=""
                className=""
                value={addressDetails.consigneeFullAddress}
                disabled={true}
                labelClassName="block mb-1.5"
              />
            </div>

            <AccordionCmp title={<h5 className="text-sm">Dimensions</h5>}>
              {dimensions?.map((dimension: any, index: any) => (
                <ul key={index} className="flex flex-wrap -mx-1 gap-y-2">
                  <li className="px-1 mdm:w-[110px] md:w-1/4 sm:w-1/2 w-full">
                    <InputText
                      label="Handling unit"
                      inputName="name"
                      placeholder="Handling unit"
                      parentClassName="w-full"
                      className=""
                      value={dimension?.handlingUnitName || ''}
                      disabled={true}
                      labelClassName="block mb-1.5"
                    />
                  </li>
                  <li className="px-1 mdm:w-[110px] md:w-1/4 sm:w-1/2 w-full">
                    <InputText
                      label="№ of HU"
                      inputName="name"
                      placeholder="№ of HU"
                      parentClassName="w-full"
                      className=""
                      value={dimension?.handlingUnitNo}
                      disabled={true}
                      labelClassName="block mb-1.5"
                    />
                  </li>
                  <li className="px-1 mdm:w-[110px] md:w-1/4 sm:w-1/2 w-full">
                    <InputText
                      label="Freight Class"
                      inputName="name"
                      placeholder="Freight Class"
                      parentClassName="w-full"
                      className=""
                      value={dimension?.freightClass}
                      disabled={true}
                      labelClassName="block mb-1.5"
                    />
                  </li>

                  <li className="px-1 mdm:w-[158px] md:w-1/4 sm:w-1/2 w-full">
                    <InputText
                      label="Total Weight"
                      inputName="name"
                      placeholder="Total Weight"
                      parentClassName="w-full"
                      className=""
                      value={`${dimension?.weight} (LBS)`}
                      disabled={true}
                      labelClassName="block mb-1.5"
                    />
                  </li>
                  <li className="px-1 mdm:w-[226px] md:w-[33.33%] sm:w-1/2 w-full">
                    <InputText
                      label="Dimensions"
                      inputName="name"
                      placeholder="Dimensions"
                      parentClassName="w-full "
                      className=""
                      value={`${dimension?.itemLength}L X ${dimension?.width}W X ${dimension?.height}H`}
                      disabled={true}
                      labelClassName="block mb-1.5"
                    />
                  </li>
                </ul>
              ))}
            </AccordionCmp>
            {quotedServices?.length > 0 && (
              <div>
                <h6 className="text-grayLight900 text-xs font-semibold mb-1.5">
                  Rated Additional Services
                </h6>
                <ul className="rounded-lg border border-utilityGray200 p-3 gap-y-2 flex flex-wrap">
                  {quotedServices?.map((service: any, index: any) => (
                    <li
                      key={index}
                      className="flex  text-grayLight600 text-xs font-medium gap-x-1 w-1/3"
                    >
                      <Check className="w-4 h-4 text-primary flex-none" />
                      {service?.serviceId?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {nonQuotedServices?.length > 0 && (
              <div>
                <h6 className="text-grayLight900 text-xs font-semibold mb-1.5 flex items-center gap-x-1">
                  Non-Rated Additional Services{' '}
                  <TooltipCmp
                    message={
                      'The carrier did not provide a rate for all additional services specified. Please validate the feasibility of the shipment andor any additional costs.'
                    }
                  >
                    <InfoCircle className="text-primary700 w-3 h-3 cursor-pointer" />
                  </TooltipCmp>
                </h6>
                <ul className="rounded-lg border border-utilityGray200 p-3 gap-y-2 flex flex-wrap">
                  {quotedServices?.map((service: any, index: any) => (
                    <li
                      className="flex  text-grayLight600 text-xs font-medium gap-x-1 w-1/3"
                      key={index}
                    >
                      <Check className="w-4 h-4 text-primary flex-none" />
                      {service?.serviceId?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {activeStep === 2 && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                Contact Details
              </label>
              <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
                <div className="flex -mx-1 flex-wrap gap-y-2">
                  <Controller
                    name="contactName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        {...field}
                        label="Name"
                        inputName="name"
                        placeholder="Enter name"
                        parentClassName="px-1 md:w-1/3 w-full"
                        labelClassName="block mb-1.5"
                        errorText={errors.contactName?.message}
                      />
                    )}
                  />
                  <Controller
                    name="contactEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        {...field}
                        label="Email"
                        inputName="email"
                        placeholder="Enter Email"
                        parentClassName="px-1 md:w-1/3 w-full"
                        labelClassName="block mb-1.5"
                        errorText={errors.contactEmail?.message}
                      />
                    )}
                  />
                  <div className="px-1 md:w-1/3 w-full">
                    <label className="form_label block mb-1.5">
                      Phone number
                    </label>
                    <Controller
                      name="contactPhoneNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          onNumberChange={(country: any, phone: any) => {
                            field.onChange(`${phone}`);
                          }}
                          disabled={false}
                          errors={errors.contactPhoneNumber?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                Shipper Details
              </label>
              <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
                <div className="flex -mx-1 flex-wrap gap-y-2">
                  <Controller
                    name="shipperName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        {...field}
                        label="Name"
                        inputName="name"
                        placeholder="Enter name"
                        parentClassName="px-1 md:w-1/3 w-full"
                        labelClassName="block mb-1.5"
                        errorText={errors.shipperName?.message}
                      />
                    )}
                  />
                  <Controller
                    name="shipperEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        {...field}
                        label="Email"
                        inputName="email"
                        placeholder="Enter Email"
                        parentClassName="px-1 md:w-1/3 w-full"
                        labelClassName="block mb-1.5"
                        errorText={errors.shipperEmail?.message}
                      />
                    )}
                  />
                  <div className="px-1 md:w-1/3 w-full">
                    <label className="form_label block mb-1.5">
                      Phone number
                    </label>
                    <Controller
                      name="shipperPhoneNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          onNumberChange={(country: any, phone: any) => {
                            field.onChange(`${phone}`);
                          }}
                          disabled={false}
                          errors={errors.shipperPhoneNumber?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                Consignee Details
              </label>
              <div className="rounded-lg border border-utilityGray200 p-3 mb-3">
                <div className="flex -mx-1 flex-wrap gap-y-2">
                  <Controller
                    name="consigneeName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        {...field}
                        label="Name"
                        inputName="name"
                        placeholder="Enter name"
                        parentClassName="px-1 md:w-1/3 w-full"
                        labelClassName="block mb-1.5"
                        errorText={errors.consigneeName?.message}
                      />
                    )}
                  />
                  <Controller
                    name="consigneeEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputText
                        {...field}
                        label="Email"
                        inputName="email"
                        placeholder="Enter Email"
                        parentClassName="px-1 md:w-1/3 w-full"
                        labelClassName="block mb-1.5"
                        errorText={errors.consigneeEmail?.message}
                      />
                    )}
                  />
                  <div className="px-1 md:w-1/3 w-full">
                    <label className="form_label block mb-1.5">
                      Phone number
                    </label>
                    <Controller
                      name="consigneePhoneNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          onNumberChange={(country: any, phone: any) => {
                            field.onChange(`${phone}`);
                          }}
                          disabled={false}
                          errors={errors.consigneePhoneNumber?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              <label className="text-grayLight900 text-xs font-semibold block mb-1.5">
                Additional service not included
              </label>
              <ul className="flex flex-wrap rounded-lg border border-utilityGray200 p-3 gap-y-2">
                {carrierRate?.services?.map(
                  (service: any, index: any) =>
                    !service?.isQuoted && (
                      <li
                        className="flex gap-x-1 md:w-1/3 sm:w-1/2 w-full pr-1"
                        key={index}
                      >
                        <Check className="w-4 h-4 text-primary" />
                        <p className="text-grayLight600 text-xs font-medium">
                          {service?.serviceId?.name}
                        </p>
                      </li>
                    )
                )}
              </ul>
            </div> */}
          </form>
        )}
        {activeStep === 3 && (
          <div className="text-center">
            <div className="-mt-5 smd:max-w-[500px] max-w-[250px] mx-auto">
              <img
                src={truckLoader}
                alt=""
                width={400}
                className="rounded-2xl mx-auto"
              />
              <p className=" bg-utilityGray200 h-[1px] smd:-mt-[68px] -mt-[43px]"></p>
            </div>
            <p className="text-textSecondary text-sm font-medium mt-6 mb-2 ">
              Booking order with {carrierRate.name}
            </p>
            <p className="mx-auto text-gray500 text-xs font-normal">
              <span className="text-fgErrorPrimary">*</span> You can close this.
              We&apos;ll notify you once your order was successfully booked!
            </p>
            <ButtonCmp
              iconSide="right"
              className="btn_primary min-w-[102px] mt-6 mb-3"
            >
              Close
            </ButtonCmp>
          </div>
        )}
      </div>
    </CommonModal>
  );
};

export default FinalizeOrderModal;
