import { Check } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CUSTOMER_SEGMENT_TYPES } from 'src/constants/common';
import {
  getFormattedNumber,
  getProgressClass,
  getShortName,
  goalTarget,
} from 'src/utils/CommonFunctions';

import { ICustomerTargetRowProps } from './Target.interface';

const CustomerTargetRow = ({ data }: ICustomerTargetRowProps) => {
  const newSegmentArray = data?.tags
    ?.split(',')
    ?.map((item: string) => item.trim());

  const tdClass = data.id !== 0 ? 'px-5 py-4' : 'p-0';

  return (
    <>
      <td className={`${tdClass} min-w-[13rem] w-[13rem] max-w-[13rem]`}>
        {data.id !== 0 && (
          <div className="flex items-center gap-2">
            <div className="flex-none">
              {data.image ? (
                <img
                  className="w-8 h-8 rounded-full object-contain "
                  src={data.imageUrl + data.image}
                  alt=""
                  title=""
                />
              ) : (
                <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                  {data.id !== 0 ? getShortName(`${data.customerName}`) : ''}
                </div>
              )}
            </div>
            <div className="w-32">
              <h6 className="text-grayLight900 font-semibold leading-5 truncate">
                {data.customerName.length > 20 ? (
                  <TooltipCmp message={data.customerName}>
                    {data.customerName}
                  </TooltipCmp>
                ) : (
                  data.customerName
                )}
              </h6>
              {/* <p className="truncate">Account Executive</p> */}
            </div>
          </div>
        )}
      </td>
      <td className={`${tdClass} min-w-[13rem] w-[13rem] max-w-[13rem]`}>
        {data.id !== 0 && (
          <div className="flex flex-wrap gap-1">
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TEST) && (
              <BadgeCmp
                style="modern"
                badgeDotColor="bg-yellow300"
                mainClassName=""
              >
                {CUSTOMER_SEGMENT_TYPES.TEST}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.TOP) && (
              <BadgeCmp
                style="modern"
                mainClassName=""
                badgeDotColor="bg-[#b2ac17]"
              >
                {CUSTOMER_SEGMENT_TYPES.TOP}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.DORMANT) && (
              <BadgeCmp
                style="modern"
                badgeDotColor="bg-utilityPink600"
                mainClassName=""
              >
                {CUSTOMER_SEGMENT_TYPES.DORMANT}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.PROSPECT) && (
              <BadgeCmp style="modern" type="lightblue" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.PROSPECT}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.NEW) && (
              <BadgeCmp
                style="modern"
                badgeDotColor="bg-fgSuccessPrimary"
                mainClassName=""
              >
                {CUSTOMER_SEGMENT_TYPES.NEW}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.ACTIVE) && (
              <BadgeCmp style="modern" type="primary" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.ACTIVE}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.INACTIVE) && (
              <BadgeCmp style="modern" type="error" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.INACTIVE}
              </BadgeCmp>
            )}
            {newSegmentArray?.includes(CUSTOMER_SEGMENT_TYPES.AT_RISK) && (
              <BadgeCmp style="modern" type="gray" mainClassName="">
                {CUSTOMER_SEGMENT_TYPES.AT_RISK}
              </BadgeCmp>
            )}
          </div>
        )}
      </td>
      <td className={`${tdClass} min-w-[150px] w-[12%]`}>
        {data.id !== 0 && (
          <span>
            {getFormattedNumber(data.completedProfit, false, false, true)}
          </span>
        )}
      </td>
      <td className={`${tdClass} min-w-[150px] w-[12%]`}>
        {data.id !== 0 && (
          <span>
            {getFormattedNumber(data.targetProfit, false, false, true)}
          </span>
        )}
      </td>
      <td className={`${tdClass} min-w-[200px] w-[12%]`}>
        {data.id !== 0 && (
          <>
            {/* <progress
              className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
                data.completedProfitPercentage
              )}`}
              value={data.completedProfitPercentage}
              max="100"
            ></progress>
            <span className="text-textSecondary">
              {data.completedProfitPercentage}%
            </span> */}
            <TooltipCmp
              message={
                data.completedProfitPercentage >= 100
                  ? `${data.completedProfitPercentage}%`
                  : ''
              }
            >
              <div
                className={`radial-progress text-[10px] ${
                  data.completedProfitPercentage >= 100 ? 'cursor-pointer' : ''
                } ${getProgressClass(data.completedProfitPercentage, false)}`}
                style={
                  {
                    '--value': data.completedProfitPercentage,
                    '--size': '2rem',
                  } as React.CSSProperties & { '--value': number }
                }
                role="progressbar"
              >
                {data.completedProfitPercentage >= 100 ? (
                  <>
                    <Check className="w-4 h-4" />
                  </>
                ) : (
                  <span className="text-grayLight900 font-medium">
                    {data.completedProfitPercentage}%
                  </span>
                )}

                <div
                  className="target-div"
                  style={
                    {
                      '--value': goalTarget(),
                    } as React.CSSProperties & { '--value': number }
                  }
                ></div>
              </div>
            </TooltipCmp>
          </>
        )}
      </td>
      <td className={`${tdClass} min-w-[100px] w-[10%]`}>
        {data.id !== 0 && <span>{data.completedLoads}</span>}
      </td>
      <td className={`${tdClass} min-w-[100px] w-[10%]`}>
        {data.id !== 0 && <span>{data.targetLoads}</span>}
      </td>
      <td className={`${tdClass} min-w-[200px] w-[12%]`}>
        {data.id !== 0 && (
          <>
            {/* <progress
              className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
                data.completedLoadsPercentage
              )}`}
              value={data.completedLoadsPercentage}
              max="100"
            ></progress>
            <span className="text-textSecondary">
              {data.completedLoadsPercentage}%
            </span> */}
            <TooltipCmp
              message={
                data.completedLoadsPercentage >= 100
                  ? `${data.completedLoadsPercentage}%`
                  : ``
              }
            >
              <div
                className={`radial-progress text-[10px] ${
                  data.completedLoadsPercentage >= 100 ? 'cursor-pointer' : ''
                } ${getProgressClass(data.completedLoadsPercentage, false)}`}
                style={
                  {
                    '--value': data.completedLoadsPercentage,
                    '--size': '2rem',
                  } as React.CSSProperties & { '--value': number }
                }
                role="progressbar"
              >
                {data.completedLoadsPercentage >= 100 ? (
                  <>
                    <Check className="w-4 h-4" />
                  </>
                ) : (
                  <span className="text-grayLight900 font-medium">
                    {data.completedLoadsPercentage}%
                  </span>
                )}

                <div
                  className="target-div"
                  style={
                    {
                      '--value': goalTarget(),
                    } as React.CSSProperties & { '--value': number }
                  }
                ></div>
              </div>
            </TooltipCmp>
          </>
        )}
      </td>
    </>
  );
};

export default CustomerTargetRow;
