import {
  ChevronDown,
  SearchLg,
  FilterLines,
  CalendarDate,
  ArrowLeft,
  ArrowRight,
  XClose,
} from '@untitled-ui/icons-react//build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
// import CustomPagination from 'src/components/CustomPagination';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';
import {
  CUSTOMER_SEGMENT_TYPES,
  TABLE_IDS,
  USER_ROLE,
} from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { listCustomerTarget, setTarget } from 'src/services/TargetService';
import { getUserDetail } from 'src/services/TeamMemberService';
import {
  capitalizeFirstLetterAndDash,
  getSelectBoxOptions,
  isValidJSON,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import CustomerTargetLoadingRow from './CustomerTargetLoadingRow';
import CustomerTargetRow from './CustomerTargetRow';
import {
  ICustomerData,
  ICustomerTarget,
  IGetCustomerTargetDataParams,
  ISetSalesTargetsRequest,
  ITotalCustomerTargetNumbers,
  SortByForCustomer,
  headCellsForCustomerTargets,
  initParamsForTargetSet,
} from './Target.interface';
import TargetSetEditFooterRow from './TargetSetEditFooterRow';
import TargetSetFilterModal from './TargetSetFilterModal';
import TargetSetFooterRow from './TargetSetFooterRow';
import TargetSetLoadingRow from './TargetSetLoadingRow';
import TargetSetRow from './TargetSetRow';
import TargetSetTotalRowTable from './TargetSetTotalRowTable';

const initFooterObj: ITotalCustomerTargetNumbers = {
  name: 'Total',
  isMiscellaneous: true,
  prevMonthTargetProfit: 0,
  prevMonthCompletedProfitPercentage: 0,
  prevMonthTargetLoads: 0,
  prevMonthCompletedLoadsPercentage: 0,
  targetLoads: 0,
  targetProfit: 0,
};

const TargetSetEdit = () => {
  const { hasRoleV2 } = useRolePermission();
  const [isTotalLoading, setIsTotalLoading] = useState(true);
  const [segmentListOption, setSegmentListOption] = useState<any[]>([]);
  const [isDisplayFilterModal, setIsDisplayFilterModal] = useState(false);
  const [month, setMonth] = useState<any>(new Date());
  const [isOpenMonthPicker, setIsOpenDropDown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [isUserDetailLoading, setIsUserDetailLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<any>(undefined);
  const [targetData, setTargetData] = useState<ICustomerData[]>([]);
  // const [total, setTotal] = useState<number>(0);
  const [updatedTargets, setUpdatedTargets] = useState<ICustomerTarget[]>([]);
  const [isUpdatingTarget, setIsUpdatingTarget] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [totalData, setTotalData] =
    useState<ITotalCustomerTargetNumbers>(initFooterObj);
  const [miscellaneousData, setMiscellaneousData] =
    useState<ITotalCustomerTargetNumbers>(initFooterObj);
  const [footerData, setFooterData] = useState<ITotalCustomerTargetNumbers[]>(
    []
  );

  const [params, setParams] = useState<IGetCustomerTargetDataParams>(() => {
    const storedParams = localStorage.getItem(
      TABLE_IDS.TARGET_LIST_BY_CUSTOMER
    );

    return storedParams && isValidJSON(storedParams)
      ? {
          ...JSON.parse(storedParams),
          sortField: SortByForCustomer.Top,
          onlyTarget: !isEdit,
          sortType: 'desc',
        }
      : initParamsForTargetSet;
  });
  const [dateRange, setDateRange] = useState<any>(() => {
    const storedParams = localStorage.getItem(
      TABLE_IDS.TARGET_LIST_BY_CUSTOMER
    );

    return storedParams && isValidJSON(storedParams)
      ? JSON.parse(storedParams)?.targetMonth ||
          initParamsForTargetSet.targetMonth
      : initParamsForTargetSet.targetMonth;
  });

  // const recordsPerPageArray = [
  //   { label: '25', value: 25 },
  //   { label: '50', value: 50 },
  //   { label: '100', value: 100 },
  // ];

  useEffect(() => {
    const statusArray = Object.entries(CUSTOMER_SEGMENT_TYPES).map(
      (keyValue: any) => ({
        id: keyValue?.[1],
        value: capitalizeFirstLetterAndDash(keyValue?.[1]),
      })
    );
    setSegmentListOption(getSelectBoxOptions(statusArray, 'id', 'value'));

    if (params.userId) {
      getUserDetail(params.userId)
        .then((response: any) => {
          setUserDetails(response?.data);
        })
        .catch(() => {
          WalToast.error('Something went wrong.');
        })
        .finally(() => setIsUserDetailLoading(false));
    }
  }, []);

  const refreshData = (targetSetApiSignal?: any) => {
    setUpdatedTargets([]);
    localStorage.setItem(
      TABLE_IDS.TARGET_LIST_BY_CUSTOMER,
      JSON.stringify(params)
    );

    if (params.userId) {
      if (params.search === '') setIsTotalLoading(true);
      setIsLoading(true);
      setTargetData([]);
      listCustomerTarget(params, targetSetApiSignal)
        .then((response: any) => {
          if (response?.data) {
            if (response.data.length > 0 && response.data.length <= 5) {
              const blankRow = Object.keys(response.data[0]).reduce(
                (acc: any, key: any) => {
                  acc[key] = '';

                  return acc;
                },
                {}
              );

              blankRow.id = 0;
              response.data.push(blankRow);
            }
            setTargetData(response?.data);

            // setTotal(response?.total || 0);
            if (params.search === '') {
              setTotalData({
                name: 'Total',
                isMiscellaneous: false,
                targetProfit: response?.totals?.targetProfit || 0,
                targetLoads: response?.totals?.targetLoads || 0,
                completedLoads: response?.totals?.completedLoads || 0,
                completedProfit: response?.totals?.completedProfit || 0,
                completedLoadsPercentage:
                  response?.totals?.completedLoadsPercentage || 0,
                completedProfitPercentage:
                  response?.totals?.completedProfitPercentage || 0,
                prevMonthTargetProfit:
                  response?.totals?.prevMonthTargetProfit || 0,
                prevMonthTargetLoads:
                  response?.totals?.prevMonthTargetLoads || 0,
                prevMonthCompletedLoads:
                  response?.totals?.prevMonthCompletedLoads || 0,
                prevMonthCompletedProfit:
                  response?.totals?.prevMonthCompletedProfit || 0,
                prevMonthCompletedLoadsPercentage:
                  response?.totals?.prevMonthCompletedLoadsPercentage || 0,
                prevMonthCompletedProfitPercentage:
                  response?.totals?.prevMonthCompletedProfitPercentage || 0,
              });
            }

            if (params.search === '') {
              setMiscellaneousData({
                name: 'Miscellaneous',
                isMiscellaneous: true,
                targetProfit: response?.miscellaneousData?.targetProfit || 0,
                targetLoads: response?.miscellaneousData?.targetLoads || 0,
                completedLoads:
                  response?.miscellaneousData?.completedLoads || 0,
                completedProfit:
                  response?.miscellaneousData?.completedProfit || 0,
                completedLoadsPercentage:
                  response?.miscellaneousData?.completedLoadsPercentage || 0,
                completedProfitPercentage:
                  response?.miscellaneousData?.completedProfitPercentage || 0,
                prevMonthTargetProfit:
                  response?.miscellaneousData?.prevMonthTargetProfit || 0,
                prevMonthTargetLoads:
                  response?.miscellaneousData?.prevMonthTargetLoads || 0,
                prevMonthCompletedLoads:
                  response?.miscellaneousData?.prevMonthCompletedLoads || 0,
                prevMonthCompletedProfit:
                  response?.miscellaneousData?.prevMonthCompletedProfit || 0,
                prevMonthCompletedLoadsPercentage:
                  response?.miscellaneousData
                    ?.prevMonthCompletedLoadsPercentage || 0,
                prevMonthCompletedProfitPercentage:
                  response?.miscellaneousData
                    ?.prevMonthCompletedProfitPercentage || 0,
              });
            }

            const autoFetchPrevTargets: ICustomerTarget[] = response.data
              .filter(
                (data: ICustomerData) =>
                  (data.targetLoads <= 0 && data.prevMonthTargetLoads > 0) ||
                  (data.targetProfit <= 0 && data.prevMonthTargetProfit > 0)
              )
              .map((data: ICustomerData) => ({
                customerId: data.id,
                targetLoads:
                  data.targetLoads <= 0
                    ? data.prevMonthTargetLoads
                    : data.targetLoads,
                targetProfit:
                  data.targetProfit <= 0
                    ? data.prevMonthTargetProfit
                    : data.targetProfit,
                targetMonth: params.targetMonth,
              }));

            setUpdatedTargets(autoFetchPrevTargets);
          }
          setIsLoading(false);
          setIsTotalLoading(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsLoading(false);
          setIsTotalLoading(false);
        });
    } else {
      setTargetData([]);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setFooterData([miscellaneousData, totalData]);
    }
  }, [totalData, miscellaneousData]);

  useEffect(() => {
    setSearch(params?.search || '');
    const targetSetApiController = new AbortController();
    const targetSetApiSignal = targetSetApiController.signal;
    refreshData(targetSetApiSignal);

    return () => {
      targetSetApiController.abort();
    };
  }, [params]);

  useEffect(() => {
    setParams((old: IGetCustomerTargetDataParams) => ({
      ...old,
      targetMonth: dateRange,
    }));
    setMonth(moment(dateRange, 'YYYY-MM').toDate());
  }, [dateRange]);

  useEffect(() => {
    setParams((old: IGetCustomerTargetDataParams) => ({
      ...old,
      onlyTarget: !isEdit,
    }));
  }, [isEdit]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsOpenDropDown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datePickerRef]);

  useEffect(() => {
    let totalTargetProfit = 0;
    let totalTargetLoads = 0;

    targetData.map((data: ICustomerData) => {
      const existingTarget = updatedTargets.find(
        (target) => target.customerId === data.id
      );

      if (isEdit && existingTarget) {
        totalTargetProfit += existingTarget.targetProfit
          ? parseInt(existingTarget.targetProfit.toString())
          : 0;
        totalTargetLoads += existingTarget.targetLoads
          ? parseInt(existingTarget.targetLoads.toString())
          : 0;
      } else {
        totalTargetProfit += data.targetProfit
          ? parseInt(data.targetProfit.toString())
          : 0;
        totalTargetLoads += data.targetLoads
          ? parseInt(data.targetLoads.toString())
          : 0;
      }
    });

    setTotalData((old: ITotalCustomerTargetNumbers) => ({
      ...old,
      targetLoads:
        totalTargetLoads +
        (miscellaneousData?.targetLoads
          ? parseInt(miscellaneousData?.targetLoads.toString())
          : 0 || 0),
      targetProfit:
        totalTargetProfit +
        (miscellaneousData?.targetProfit
          ? parseInt(miscellaneousData?.targetProfit.toString())
          : 0 || 0),
    }));
  }, [updatedTargets, miscellaneousData]);

  const headCells = useMemo(
    () => [
      {
        id: SortByForCustomer.CustomerName,
        name: 'Customers',
        sortable: true,
      },
      {
        id: SortByForCustomer.Top,
        name: 'Tags',
        sortable: false,
      },
      {
        id: SortByForCustomer.PrevMonthTargetProfit,
        name: `${moment(dateRange)
          .subtract(1, 'month')
          .format('MMMM')} Target Margin  `,
        sortable: true,
      },
      {
        id: SortByForCustomer.PrevMonthTargetLoads,
        name: ` ${moment(dateRange)
          .subtract(1, 'month')
          .format('MMMM')} Target Loads `,
        sortable: true,
      },
      {
        id: SortByForCustomer.TargetProfit,
        name: 'Target Margin',
        sortable: true,
      },
      {
        id: SortByForCustomer.TargetLoads,
        name: 'Target Loads',
        sortable: true,
      },
    ],
    [dateRange]
  );

  const handleInputChange = (
    customerId: number,
    field: 'targetLoads' | 'targetProfit',
    value: any,
    otherData: any
  ) => {
    setUpdatedTargets((prevTargets: any) => {
      const existingTargetIndex = prevTargets.findIndex(
        (target: any) => target.customerId === customerId
      );

      if (existingTargetIndex !== -1) {
        // If customerId exists, update the respective field
        const newTargets = [...prevTargets];
        newTargets[existingTargetIndex] = {
          ...newTargets[existingTargetIndex],
          [field]: value,
        };

        return newTargets;
      } else {
        // If customerId does not exist, add a new target
        return [
          ...prevTargets,
          {
            ...otherData,
            customerId,
            [field]: value,
            targetMonth: params.targetMonth,
          },
        ];
      }
    });
  };

  const handleSave = () => {
    const requestData: ISetSalesTargetsRequest = {
      userId: params.userId,
      targets: updatedTargets,
      targetMonth: params?.targetMonth || initParamsForTargetSet.targetMonth,
      miscellaneousTargetProfit: miscellaneousData.targetProfit,
      miscellaneousTargetLoads: miscellaneousData.targetLoads,
    };
    setIsUpdatingTarget(true);

    setTarget(requestData)
      .then()
      .catch()
      .finally(() => {
        setIsUpdatingTarget(false);
        setUpdatedTargets([]);
        setIsEdit(false);
      });
  };

  const getFilterRange = (type: string) => {
    if (type === 'prev') {
      const prevMonth = moment(dateRange, 'YYYY-MM').subtract(1, 'months');

      if (hasRoleV2(USER_ROLE.USER)) {
        if (
          prevMonth.isSame(moment(), 'month') ||
          prevMonth.isSame(moment().add(1, 'month'), 'month')
        ) {
          setDateRange(prevMonth.format('YYYY-MM'));
        }
      } else {
        if (prevMonth.isSameOrAfter(moment('2024-08', 'YYYY-MM'))) {
          setDateRange(prevMonth.format('YYYY-MM'));
        }
      }
    }

    if (type === 'next') {
      const nextMonth = moment(dateRange, 'YYYY-MM').add(1, 'months');

      if (hasRoleV2(USER_ROLE.USER)) {
        if (
          nextMonth.isSame(moment(), 'month') ||
          nextMonth.isSame(moment().add(1, 'months'), 'month')
        ) {
          setDateRange(nextMonth.format('YYYY-MM'));
        }
      } else {
        setDateRange(nextMonth.format('YYYY-MM'));
      }
    }
  };

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: IGetCustomerTargetDataParams) => ({
        ...old,
        search: debSearch,
        page: 1,
      }));
    }, 700),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchTargetSet') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Sales Target"
          desc="Set new targets based on previous sales performances."
        />
      }
      // contentClassName="h-[calc(100%_-_87px)]"
    >
      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
        <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
          <TargetSetTotalRowTable
            isEdit={isEdit}
            dateRange={dateRange}
            isLoading={isTotalLoading}
            userDetails={userDetails}
            totalData={totalData}
          />
        </div>
      </div>
      <div className="h-[calc(100%_-_123px)] w-full bg-white rounded-xl shadow border border-utilityGray200 flex flex-col ">
        <div className="table-top-header flex-wrap mdm:justify-between justify-start">
          <div className="table-left-wrap max-xlm:min-w-full max-xls:min-w-[auto] ">
            <div className="table-title-wrap items-start">
              {/* <ButtonCmp type="submit" className="btn_secondary_black !p-0">
                <Link to={ROUTES.SALES_TARGET} className="py-[7px] px-[7px]">
                  <ArrowNarrowLeft className="w-4 h-4" />
                </Link>
              </ButtonCmp> */}
              <div>
                <h5
                  className={`table-title ${
                    isUserDetailLoading ? 'custom-loading' : ''
                  }`}
                >
                  {isEdit
                    ? 'Set Monthly Customer Targets'
                    : 'Monthly Customer Targets'}
                </h5>
                <p className="table-subtitle">
                  View your monthly customer target breakdown
                </p>
              </div>
            </div>
          </div>
          <div className="table-right-wrap xls:order-3 sm:order-none order-1 max-sm:w-full">
            {isEdit ? (
              <>
                <ButtonCmp
                  type="submit"
                  className="btn_secondary_black sm:min-w-20 min-w-full "
                  onClick={() => setIsEdit(false)}
                  disabled={isUpdatingTarget}
                >
                  Cancel
                </ButtonCmp>
                <ButtonCmp
                  type="submit"
                  className="btn_primary sm:min-w-32 max-sm:w-[calc(50%_-_6px)]"
                  loading={isUpdatingTarget}
                  onClick={handleSave}
                >
                  Save Targets
                </ButtonCmp>
              </>
            ) : (
              <>
                <Link
                  to={ROUTES.SALES_TARGET}
                  className=" max-sm:w-[calc(50%_-_6px)]"
                >
                  <ButtonCmp
                    type="submit"
                    className="btn_secondary_black sm:min-w-20 min-w-full "
                  >
                    Back
                  </ButtonCmp>
                </Link>
                <ButtonCmp
                  type="submit"
                  className="btn_primary sm:min-w-32 max-sm:w-[calc(50%_-_6px)]"
                  loading={isUpdatingTarget}
                  onClick={() => {
                    setTargetData([]);
                    setFooterData([]);
                    setIsLoading(true);
                    setIsEdit(true);
                  }}
                  disabled={isLoading}
                >
                  Set Targets
                </ButtonCmp>
              </>
            )}
          </div>
          <div className="table-right-wrap max-xlm:flex-1 max-xls:flex-[0_0_100%]">
            <InputText
              inputName="searchTargetSet"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              isClearable
              inputType="text"
              onChangeFunc={handleFilter}
              parentClassName="table-searchInput max-xlm:flex-1 max-sm:flex-[0_0_100%]"
            />
            <ButtonCmp
              type="submit"
              className="btn_secondary_black xl:hidden max-sm:w-[calc(50%_-_6px)]"
              onClick={(e: any) => {
                if (
                  e?.target?.id !== 'closeBtn' &&
                  e?.target?.parentElement?.id !== 'closeBtn'
                )
                  setIsDisplayFilterModal(true);
              }}
              icon={<FilterLines className="w-4 h-4" />}
            >
              Filter
              {filterCount > 0 ? (
                <>
                  <p className="filter-btn-selected">{filterCount}</p>
                  <XClose
                    className="w-4 h-4 text-primary700 mt-[1px]"
                    id="closeBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      setParams((old: IGetCustomerTargetDataParams) => ({
                        ...old,
                        search: '',
                      }));
                      setFilterCount(0);
                    }}
                  />
                </>
              ) : (
                ''
              )}
            </ButtonCmp>

            <div className="table-datepicker-v2 max-xl:hidden cursor-pointer">
              {/* <div
                className=" rounded-lg shadow-sm border border-gray-300 flex text-textSecondary text-xs font-medium"
                onClick={() => {
                  setIsOpenDropDown(true);
                }}
              >
                <div className="flex flex-1 items-center sm:min-w-[152px] min-w-32 justify-center lg:px-3 px-2 lg:py-[9px] py-2 gap-2">
                  {moment(month).format('MMMM YYYY')}
                  <ChevronDown className="w-4 h-4 text-gray400" />
                </div>
              </div> */}
              <div className="w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px]  justify-between items-center gap-1.5 flex bg-white  hover:border-grayText transition-all">
                <ArrowLeft
                  className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                  onClick={() => getFilterRange('prev')}
                />

                <div
                  className="flex flex-1 justify-center items-center"
                  onClick={() => {
                    setIsOpenDropDown(true);
                  }}
                >
                  <CalendarDate className="w-4 h-4 mr-2" />
                  <div className="text-textSecondary text-xs font-medium leading-none">
                    {' '}
                    {moment(month).format('MMMM YYYY')}
                  </div>
                </div>

                <ArrowRight
                  className="xs:w-4 xs:h-4 w-3 h-3 cursor-pointer flex-none"
                  onClick={() => getFilterRange('next')}
                />
              </div>
              {isOpenMonthPicker && (
                <div
                  className="shadow-lg mt-2.5 mb-2 z-[2] rounded-lg border border-utilityGray200 absolute bg-white datepicker-v2"
                  ref={datePickerRef}
                >
                  <DateSelect
                    inputName="pickupDate"
                    selected={month}
                    showMonthYearPicker
                    dateFormat="MMMM-yyyy"
                    onChangeFunc={(e: any) => {
                      setMonth(moment(e.value, 'MMM YYYY').toDate());
                      setDateRange(
                        moment(e.value, 'MMM YYYY').format('YYYY-MM')
                      );
                      setIsOpenDropDown(false);
                    }}
                    inline={isOpenMonthPicker}
                    icon={<ChevronDown className="w-5 h-5" />}
                    minDate={moment('2024-08-01', 'YYYY-MM-DD').toDate()}
                    maxDate={
                      hasRoleV2('user')
                        ? moment().add(1, 'month').toDate()
                        : null
                    }
                  />
                </div>
              )}
            </div>
            <div className="table-statustbox mdm:block">
              <SelectBox
                name="segmentGroup"
                id="segmentGroup"
                className="form_control"
                size="sm"
                placeholder="All Tags"
                isClearable={true}
                options={segmentListOption}
                onChangeFunc={(event: any) => {
                  console.log('event :>> ', event?.value);
                  setParams((old: IGetCustomerTargetDataParams) => ({
                    ...old,
                    tag: event?.value ?? '',
                    page: 1,
                  }));
                }}
                value={segmentListOption.filter(
                  (val: any) => params.tag === val?.value
                )}
              />
            </div>
          </div>
        </div>
        {/* <div className="table-recordsPerPage xls:order-none order-2">
            <SelectBox
              name="recordsPerPageGroup"
              id="recordsPerPageGroup"
              className="form_control shadow"
              size="sm"
              options={recordsPerPageArray}
              onChangeFunc={(event: any) => {
                setParams((old: IGetCustomerTargetDataParams) => ({
                  ...old,
                  limit: event.value,
                  page: 1,
                }));
              }}
              isSearchable={false}
              value={recordsPerPageArray.find(
                (val: any) => val.value === params.limit
              )}
            />
          </div> */}

        <div className="flex flex-col lg:min-h-[122px] w-full border-t border-gray100 flex-1">
          <div className="overflow-x-auto custom-scrollbar-v2 custom-scrollbar flex flex-col justify-between rounded-xl h-full">
            {isEdit ? (
              <TableCmp
                headCells={headCells}
                tableDataArr={targetData}
                TableLoaderRowCmp={TargetSetLoadingRow}
                TableRowCmp={TargetSetRow}
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={10}
                tableFooterClass="sticky bottom-0 [&>tr>td]:shadow-[inset_0_1px_0_rgba(234,236,240,1)] [&>tr]:border-0 z-[2]"
                // tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
                isTableRowClickable={false}
                tableRowCmpProps={{ handleInputChange, updatedTargets }}
                TableFooterRowCmp={TargetSetEditFooterRow}
                tableFooterRowCmpProps={{ setMiscellaneousData }}
                footerDataArr={footerData}
                trCustomClass={true}
                trCustomBaseHeight={494}
                totalHeight="100vh"
              />
            ) : (
              <TableCmp
                headCells={headCellsForCustomerTargets}
                tableDataArr={targetData}
                TableLoaderRowCmp={CustomerTargetLoadingRow}
                TableRowCmp={CustomerTargetRow}
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={10}
                tableFooterClass="sticky bottom-0 [&>tr>td]:shadow-[inset_0_1px_0px_0px_rgba(234,236,240,1)] [&>tr]:border-0"
                TableFooterRowCmp={TargetSetFooterRow}
                tableFooterRowCmpProps={{ setMiscellaneousData }}
                isTableRowClickable={false}
                // tableFooterRowClass="last:border-b-0"
                footerDataArr={footerData}
                trCustomClass={true}
                trCustomBaseHeight={494}
                totalHeight="100vh"
              />
            )}
          </div>
          {targetData.length <= 0 && !isLoading && (
            <NotFoundUI
              title="No targets found"
              desc="There are no targets for the user."
              containerClassName="min-h-[calc(100%_-_176.5px)] !h-auto"
            />
          )}
        </div>
        {/* {!!targetData.length && (
          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={total}
              currentPage={params.page}
              handlePagination={(page) =>
                setParams((old: IGetCustomerTargetDataParams) => ({
                  ...old,
                  page,
                }))
              }
            />
          </div>
        )} */}
      </div>
      {isDisplayFilterModal && (
        <TargetSetFilterModal
          setFilterCount={setFilterCount}
          setParams={setParams}
          params={params}
          // userList={userList}
          handleClose={() => setIsDisplayFilterModal(false)}
        />
      )}
    </PageSectionLayout>
  );
};

export default TargetSetEdit;
