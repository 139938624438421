import { yupResolver } from '@hookform/resolvers/yup';
import { FilePlus02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { PATH } from 'src/constants/path';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import { updateDisputeStatus } from 'src/services/DisputeService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import { handleInputChange } from '../ClaimsDisputes.const';

const disputeExplanationSchema = yup.object().shape({
  disputeExplanation: yup.string().required('Description is required.'),
  disputeAmount: yup
    .number()
    .nullable()
    .typeError('Dispute amount must be a valid number')
    .when([], {
      is: (isDisputeAmountUpdatable: boolean) => isDisputeAmountUpdatable,
      then: (schema) => schema.required('Dispute Amount is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
});

interface IProps {
  handleClose: any;
  dispute: any;
  title: any;
  isEdit: any;
  getDispute: any;
  setShowClaimDisputeNoSalesRepWarning?: any;
  isDisputeAmountUpdatable?: boolean;
}

const EditDisputeExplanation = ({
  handleClose,
  dispute,
  title,
  isEdit,
  getDispute,
  setShowClaimDisputeNoSalesRepWarning,
  isDisputeAmountUpdatable,
}: IProps) => {
  const navigate = useNavigate();
  const { reloadCounts } = useContext(EntityCountsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(disputeExplanationSchema),
    defaultValues: {
      disputeExplanation: '',
      disputeAmount: dispute?.disputeAmount,
    },
    context: { isDisputeAmountUpdatable },
  });

  useEffect(() => {
    if (dispute && dispute.disputeExplanation) {
      setValue('disputeExplanation', dispute.disputeExplanation);
    }
  }, []);

  const onSubmit = (formData: any) => {
    setIsLoading(true);

    const appendFormData: any = new FormData();

    appendFormData.append('status', 'Opened');
    appendFormData.append('disputeExplanation', formData?.disputeExplanation);

    if (dispute && dispute?.disputeID) {
      appendFormData.append('disputeID', dispute?.disputeID);
    }

    if (isDisputeAmountUpdatable) {
      appendFormData.append('disputeAmount', formData?.disputeAmount);
    }

    updateDisputeStatus(appendFormData)
      .then(() => {
        handleClose();

        if (!isEdit) {
          navigate(`${PATH.DISPUTE_DETAILS}/${dispute?.id}`);
          reloadCounts();
        } else {
          getDispute();
        }
      })
      .catch((e: any) => {
        if (e?.response?.data?.isShowNoSalesRepWarning) {
          if (setShowClaimDisputeNoSalesRepWarning) {
            setShowClaimDisputeNoSalesRepWarning(dispute);
          }
          handleClose();
        } else {
          WalToast.error(
            e?.response?.data?.message ?? 'Dispute description not added'
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const headerIcon = <FilePlus02 className="w-7 h-7" />;

  return (
    <CommonModal
      title={title}
      titleDesc={`View and manage your dispute details`}
      handleClose={handleClose}
      size={'max-w-[540px]'}
      headerIcon={headerIcon}
      headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      primaryBtnText="Save"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      primaryBtnDisabled={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isLoading}
    >
      <div className="p-5">
        {isDisputeAmountUpdatable && (
          <Controller
            name="disputeAmount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputType="number"
                inputName="disputeAmount"
                label="Dispute Amount"
                parentClassName="mb-4"
                placeholder="Enter Dispute Amount"
                value={`${value}`}
                onChangeFunc={(e) => handleInputChange(e, 'change', onChange)}
                onBlur={(e) => handleInputChange(e, 'blur', onChange)}
                labelClassName="block mb-1.5"
                errorText={errors?.disputeAmount?.message ?? ''}
              />
            )}
          />
        )}
        <Controller
          name="disputeExplanation"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="disputeExplanation"
              label="Dispute Description"
              placeholder="Enter Dispute Description"
              value={value}
              onChangeFunc={onChange}
              labelClassName="block mb-1.5"
              className="h-32"
              errorText={errors?.disputeExplanation?.message ?? ''}
              asTextarea="textarea"
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default EditDisputeExplanation;
