import { CheckSquareBroken, Clock } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  displayTimeDifference,
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedNumber,
  getShortName,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import { getBadgeType } from '../ClaimsDisputes.const';

const DisputesRow = ({ onRowClick, data, setAction, setDispute }: any) => {
  const { hasRoleV2 } = useRolePermission();

  const { type, clockType } = getBadgeType(
    moment.utc(data?.createdAt).local().format('YYYY-MM-DD HH:mm:ss')
  );

  const handleActionType =
    (keyAction: any, mode: any = null, dispute: any = {}) =>
    () => {
      setDispute(dispute);
      setAction((old: any) => ({ ...old, [keyAction]: true, mode }));
    };

  return (
    <>
      <td
        className={`px-5 py-4 min-w-44 w-44 max-w-44  ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        <div className="flex items-center">
          <div className="table-profile-wrap notification-mark-wrap">
            {data.imageUrl && data.image ? (
              <img
                className="table-profile-icn-circle-xl"
                src={data.imageUrl + data.image}
                alt={data.name}
                title={data.name}
              />
            ) : data?.name && data.name !== '' ? (
              <div className="table-profile-user-circle-xl">
                {getShortName(`${data.name}`)}
              </div>
            ) : (
              <div className="table-profile-empty-circle-xl">&nbsp;</div>
            )}

            {data.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>

          <div className="pl-2 text-xs font-medium max-w-28">
            <TooltipCmp
              message={data.name && data.name.length > 18 ? data.name : null}
            >
              <div className="text-grayLight900 font-medium leading-tight truncate">{`${
                data.name ?? '-'
              }`}</div>
            </TooltipCmp>
            <div className="text-grayLight600 font-normal leading-tight truncate">
              #{data.disputeID}
            </div>
          </div>
        </div>
      </td>

      <td
        className={`px-5 py-4 max-w-[10%] min-w-[10%] w-[10%] ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        <div className="text-grayLight600 font-normal leading-tight truncate">
          <a
            href={`https://wal.roserocket.com/#/ops/orders/${data.roserocketOrderId}`}
            className="text-primary700 font-normal leading-tight truncate underline"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            #{data.orderID}
          </a>
        </div>
      </td>
      <td
        className={`px-5 py-4 text-xs max-w-32 w-32 min-w-32 ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        <BadgeCmp
          style="modern"
          type={
            data.status === 'New'
              ? 'success'
              : data.status === 'Resolved'
              ? 'primary'
              : data.status === 'Opened'
              ? 'lightblue'
              : data.status === 'Closed'
              ? 'error'
              : 'success'
          }
        >
          {data.status}
        </BadgeCmp>
      </td>
      <td
        className={`px-5 py-4 min-w-36 w-36 max-w-36 ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.createdAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(data.createdAt, `MMM Do - YYYY`, true, true)
                )
              : '-',
          }}
        />
      </td>

      <td
        data-test-id="wal-QuotingDashboard-totalWeight"
        className={`px-5 py-4  min-w-[150px] w-[150px] max-w-[150px] ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        {data.carrier ? (
          <TooltipCmp
            message={data.carrier.length > 24 ? data.carrier : null}
            parentClassName="md:!p-3 !p-1.5 max-w-90"
          >
            <p className="truncate">{data.carrier}</p>
          </TooltipCmp>
        ) : (
          '-'
        )}
      </td>
      <td
        data-test-id="wal-QuotingDashboard-totalUnits"
        className={`px-5 py-4 min-w-36 w-36 max-w-36 ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.orderCreatedAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.orderCreatedAt,
                    `MMM Do - YYYY`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        />
      </td>
      <td
        className={`px-5 py-4 min-w-[9%] max-w-[9%] w-[9%] ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        ${getFormattedNumber(data.totalPrice)}
      </td>
      <td
        className={`px-5 py-4 min-w-[9%] max-w-[9%] w-[9%] ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        ${getFormattedNumber(data.disputeAmount)}
      </td>

      <td
        className={`px-5 py-4 min-w-[8%] max-w-[8%] w-[8%] ${
          data.hasUnreadNotification ? 'notification-row' : ''
        }`}
        onClick={onRowClick(data)}
      >
        {data.createdAt ? (
          <BadgeCmp style="pill" type={type} mainClassName="rounded-md">
            <div className="flex items-center gap-1">
              <Clock className={`w-3 h-3 mt-[1px] ${clockType}`} />
              {displayTimeDifference(
                moment
                  .utc(data?.createdAt)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              )}
            </div>
          </BadgeCmp>
        ) : (
          '-'
        )}
      </td>

      {(hasRoleV2('admin') || hasRoleV2('finance')) && (
        <td
          className={`px-5 py-4  w-16 max-w-16 min-w-16 ${
            data.hasUnreadNotification ? 'notification-row' : ''
          }`}
        >
          {data?.status !== 'Closed' && (
            <div
              onClick={() =>
                data?.status !== 'Resolved' &&
                handleActionType('dispute', 'resolveDispute', data)()
              }
              className="py-2"
            >
              <div className="flex items-center">
                <TooltipCmp
                  message={
                    data?.status === 'Resolved'
                      ? 'Resolved Dispute'
                      : 'Resolve Dispute'
                  }
                  parentClassName="md:!p-3 !p-1.5 max-w-90"
                >
                  <CheckSquareBroken
                    className={`w-4 h-4 relative mr-3 ${
                      data?.status === 'Resolved'
                        ? 'text-success'
                        : 'text-primary'
                    }`}
                  />
                </TooltipCmp>
              </div>
            </div>
          )}
        </td>
      )}
    </>
  );
};

export default DisputesRow;
