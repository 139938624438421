import { Minimize02, Route } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';

const CustomerDetail = ({ handleClose }: any) => {
  console.log('ActiveShipments :>> ');

  return (
    <div className="fixed bottom-10 right-10 w-[500px] h-[calc(100vh_-_198px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <div className="flex items-start border-b border-utilityGray200 p-4 gap-2 sticky top-0 z-[1] bg-white">
        <div className="rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
          <Route className="w-4 h-4" />
        </div>
        <div className="flex-1">
          <h6 className="text-grayLight900 text-base font-semibold">
            Customer Detail
          </h6>
          <p className="text-gray500 text-xs font-normal">
            Here is an overview of your lane details.
          </p>
        </div>
        <ButtonCmp className="!p-1.5 btn_secondary_black">
          <Minimize02
            className="w-4 h-4 text-primary cursor-pointer"
            onClick={() => {
              console.log('button :>> ');
              handleClose();
            }}
          />
        </ButtonCmp>
      </div>
    </div>
  );
};

export default CustomerDetail;
