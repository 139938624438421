import {
  SearchLg,
  Plus,
  Edit05,
  ChevronDown,
  Trash01,
  CheckCircle,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import AccordionCmp from 'src/components/AccordionCmp';
import DeleteModal from 'src/components/DeleteModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { PATH } from 'src/constants/path';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import { getTeamList } from 'src/services/SalesDashboard';

import TeamIcon1 from '../../../assets/img/Avatar7.png';
import BadgeCmp from '../../../components/BadgeCmp';
import ButtonCmp from '../../../components/ButtonCmp';
import CommonModal from '../../../components/CommonModal';
import InputText from '../../../components/InputText/InputText';
import NotFoundUI from '../../../components/NotFoundUI';
import PageSectionLayout from '../../../components/PageSectionLayout';
import Header from '../../../components/PageSectionLayout/Header/Header';
import { removeUserFromTeam } from '../../../services/TeamMemberService';
import {
  // listAllTeam,
  listTeam,
  removeTeam,
} from '../../../services/TeamService';
import {
  capitalizeFirstLetterSpace,
  getSelectBoxOptions,
  onError,
  useRolePermission,
} from '../../../utils/CommonFunctions';
import WalToast from '../../../utils/WalToast';
import AccessControlModal from '../AccessControlModal';
import CreateUpdateMemberModal from '../CreateUpdateMemberModal';
import CreateUpdateTeam from '../CreateUpdateTeam';

import CreateUpdateTeamMember from './CreateUpdateTeamMember';
import MemberProfile from './MemberProfile';
import TeamMembersTable from './TeamMembersTable';

const initAction = {
  mode: null,
  team: false,
};

const initParams = {
  search: '',
  teamId: '',
  limit: 10,
};

const TeamManagement = () => {
  const { hasRoleV2, hasPermissionV2 } = useRolePermission();
  const { setTooltipGlobalOpen, setActionTooltipGlobalOpen } =
    useContext(BasicContext);
  const { reloadUser } = useContext(AuthContext);
  const [params, setParams] = useState(initParams);
  const [action, setAction] = useState(initAction);
  const [team, setTeam] = useState<any>(undefined);
  const [teamMember, setTeamMember] = useState<any>(null);
  const [teamList, setTeamList] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [isTeamListLoading, setIsTeamListLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [isTeamsListLoading, setIsTeamsListLoading] = useState(true);
  const [updateTeamFormData, setUpdateTeamFormData] = useState(undefined);
  const [isShowTeam, setIsShowTeam] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamSelectBoxOptions: any = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          undefined,
          null,
          true,
          TeamIcon1
        );
        setTeamOptions(teamSelectBoxOptions);
      })
      .catch(console.log)
      .finally(() => setIsTeamListLoading(false));
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setTeam(null);
    setTeamMember(null);

    if (isRefresh) {
      setIsTeamsListLoading(true);
      listTeam({ ...params }, signal)
        .then((response: any) => {
          setTeamList(response.data);
          setIsTeamsListLoading(false);
        })
        .catch(console.log)
        .finally(() => {
          setIsRefresh(false);
        });
    }

    return () => {
      controller.abort();
    };
  }, [isRefresh, params]);

  const searchDebounce = useCallback(
    debounce((search: string) => {
      setParams((old) => ({ ...old, search }));
      setIsRefresh(true);
    }, 700),
    []
  );

  const handleActionType =
    (
      actionKey: any,
      mode: any = null,
      teamRec: any = null,
      teamMemberRec: any = null
    ) =>
    () => {
      if (teamRec?.id) {
        setTeam(teamRec);
      }

      if (teamMemberRec) {
        setTeamMember(teamMemberRec);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
      setTooltipGlobalOpen(false);
      setActionTooltipGlobalOpen(false);
    };

  const handleDrawerClose = useCallback(() => {
    reloadUser();
    setAction(initAction);
    setTeamMember({});
    // setTeam(null);
    setUpdateTeamFormData(undefined);
  }, []);

  const handleUserDeleteConfirmationClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        setTeamMember(null);
        removeUserFromTeam({ userId: teamMember?.id, teamId: team?.id })
          .then(() => {
            WalToast.success(
              `${teamMember?.firstName ?? 'Member'} ${
                teamMember?.lastName ?? ''
              } removed successfully`
            );
          })
          .finally(() => {
            setIsRefresh(true);
          })
          .catch(() => WalToast.error('Member not removed'));
      }
    },
    [teamMember, team]
  );

  const handleTeamDeleteConfirmationClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        setTeam(null);
        removeTeam(team.id)
          .then(() => {
            setIsRefresh(true);
            reloadUser();
            WalToast.success(`${team?.name} was removed from WALHQ`);
          })
          .catch(() => WalToast.error('Team not deleted'));
      }
    },
    [team]
  );

  const headCells = useMemo(
    () => [
      {
        id: 'firstName',
        name: 'Name',
        sortable: true,
      },
      {
        id: 'jobRole',
        name: 'Role',
        sortable: true,
      },
      {
        id: 'displayName',
        name: 'Account Type',
        sortable: true,
      },
      {
        id: 'lastLoginAt',
        name: 'Last Active',
        sortable: true,
      },
      {
        id: 'action',
      },
    ],
    []
  );

  const handleShowTeam = (teamId: number) => {
    if (team?.id !== teamId) {
      setIsShowTeam(true);
    } else {
      if (isShowTeam) {
        setTeam(undefined);
      }
      setIsShowTeam(!isShowTeam);
    }
  };

  const rightSideContent = (
    <>
      {hasPermissionV2('team_create') && (
        <ButtonCmp
          className="btn_primary flex"
          onClick={handleActionType('team_management', 'team-create')}
          icon={<Plus className="text-white w-4 h-4" />}
        >
          Create New Team
        </ButtonCmp>
      )}
    </>
  );

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title="Team Management"
            desc="Manage your organization teams and their members here."
            mainContainerClassName="sm:flex-nowrap sm:flex-row"
            rightClassName="sm:w-auto"
            rightSideContent={rightSideContent}
          />
        }
      >
        <div className="w-full flex mdm:flex-row flex-col mdm:justify-between mdm:items-center gap-3">
          <div className="gap-1 flex flex-col">
            <div className="text-grayLight900 font-semibold flex items-center flex-wrap xxl:gap-4 gap-3">
              {hasRoleV2('user') ? 'My Teams' : 'All Teams'}
              {/* {hasRoleV2('manager') || hasRoleV2('user') ? 'Your Teams' : ''} */}
              <span className={`${isTeamsListLoading ? 'custom-loading' : ''}`}>
                <BadgeCmp style="modern" type="success">
                  {teamList.length} {teamList.length > 1 ? 'Teams' : 'Team'}
                </BadgeCmp>
              </span>
            </div>
            <div className="text-grayLight600 text-sm font-normal">
              View all teams at Western Alliance Logistics
            </div>
          </div>
          <div className="gap-3 flex sm:flex-row flex-col flex-none">
            <div className="xls:w-72  mdm:w-40 sm:w-[calc(50%_-_6px)]">
              <InputText
                inputName="searchMembers"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                value={searchInputValue}
                onChangeFunc={(evt: any) => {
                  setSearchInputValue(evt.target.value);
                  searchDebounce(evt.target.value);
                }}
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                isClearable={true}
              />
            </div>
            <div className="xls:min-w-[220px] mdm:min-w-32 min-w-[calc(50%_-_6px)]">
              <SelectBox
                name="team"
                placeholder="All teams"
                parentClassName="md:flex-1 md:w-auto w-full"
                labelClassName="form_label"
                value={teamOptions.find(
                  (val: any) => val.value === params.teamId
                )}
                options={teamOptions}
                isLoading={isTeamListLoading}
                onChangeFunc={(event: any) => {
                  setIsRefresh(true);
                  setParams((old) => ({ ...old, teamId: event?.value }));
                }}
                isSearchable={false}
                isClearable
              />
            </div>
          </div>
        </div>
        {teamList.length && !isTeamsListLoading ? (
          <>
            {teamList.map((teamData: any) => (
              <AccordionCmp
                key={teamData?.name}
                isOpen={team?.id === teamData.id && isShowTeam}
                title={
                  <>
                    <div className="xxl:gap-4 gap-3 flex w-[calc(100%_-_100px)] [&>*]:select-none">
                      {teamData?.iconUrl && teamData?.icon ? (
                        <img
                          className="w-8 h-8 rounded-full border-black/[0.08] flex-none "
                          src={`${teamData?.iconUrl}${teamData?.icon}`}
                          alt="House Team"
                          title="House Team"
                          onError={onError}
                        />
                      ) : (
                        <img
                          className="w-8 h-8 rounded-full border-black flex-none"
                          src={TeamIcon1}
                          alt="House Team"
                          title="House Team"
                        />
                      )}
                      <div className="w-[calc(100%_-_50px)] flex sm:items-center sm:flex-row flex-col  items-start xl:gap-2 xxl:gap-4 gap-3">
                        <div className="text-textSecondary text-sm font-semibold leading-tight truncate sm:w-auto w-full">
                          {capitalizeFirstLetterSpace(teamData?.name)}
                        </div>
                        <BadgeCmp
                          style="modern"
                          type="success"
                          mainClassName="flex-none "
                        >
                          {teamData.members?.length}{' '}
                          {teamData.members?.length > 1 ? 'Members' : 'Member'}
                        </BadgeCmp>
                      </div>
                    </div>
                    <div className="items-center flex gap-4 justify-end">
                      {hasPermissionV2('team_delete') && (
                        <div
                          className="rounded-lg gap-2 flex"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleActionType(
                              'team_management',
                              'team-remove',
                              teamData
                            )();
                          }}
                        >
                          <Trash01
                            viewBox="0 0 20 22"
                            className="w-4 h-4 relative cursor-pointer"
                          />
                        </div>
                      )}
                      {hasPermissionV2('team_update') && (
                        <Link to={`${PATH.TEAM_MANAGEMENT}/${teamData.id}`}>
                          <div
                            className="rounded-lg gap-2 flex"
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   handleActionType(
                            //     'team_management',
                            //     'team-update',
                            //     teamData
                            //   )();
                            // }}
                          >
                            <Edit05 className="w-4 h-4 relative cursor-pointer" />
                          </div>
                        </Link>
                      )}
                    </div>
                  </>
                }
                className="shadow-sm"
                contentClassName="!p-0"
                handleClick={() => {
                  setTeam(teamData);
                  handleShowTeam(teamData?.id);
                }}
              >
                <>
                  {team?.id === teamData.id && isShowTeam && (
                    <div className="w-full bg-white rounded-xl border border-utilityGray200">
                      <TeamMembersTable
                        headCells={headCells}
                        memberList={teamData.members}
                        handleActionType={handleActionType}
                        isPaginateRecords={true}
                        recordsPerPage={params.limit}
                      />
                    </div>
                  )}
                </>
              </AccordionCmp>
            ))}
          </>
        ) : isTeamsListLoading ? (
          <>
            {[...Array(10)].map((_, index) => (
              <div
                key={index}
                className="w-full px-4 py-3 bg-white rounded-[10px] border border-utilityGray200 "
              >
                <div className="justify-between items-center flex gap-2">
                  <div className="gap-3 flex">
                    <div className="custom-loading w-8 h-8 rounded-full flex-none">
                      <img
                        className="w-8 h-8 rounded-full border-black flex-none"
                        src={TeamIcon1}
                        alt="House Team"
                        title="House Team"
                      />
                    </div>
                    <div className="w-[calc(100%_-_44px)] flex sm:items-center sm:flex-row flex-col  items-start xl:gap-2 gap-1">
                      <div className="custom-loading text-textSecondary text-sm font-semibold leading-tight truncate">
                        xxxxxx
                      </div>
                      <span className="custom-loading">
                        <BadgeCmp style="modern" type="success">
                          0 Member
                        </BadgeCmp>
                      </span>
                    </div>
                  </div>
                  <div className="items-center flex gap-4 justify-end custom-loading">
                    <ChevronDown className="w-4 h-4 relative cursor-pointer" />
                    <ChevronDown className="w-4 h-4 relative cursor-pointer" />
                    <ChevronDown className="w-4 h-4 relative cursor-pointer" />
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="h-full w-full flex justify-center items-center object-cover">
            <NotFoundUI
              handleActionType={handleActionType(
                'team_management',
                'team-create'
              )}
              title="No teams found"
              desc="There are no data for teams you can create new team."
              buttonText="Create New Team"
              buttonDisabled={!hasPermissionV2('team_create')}
            />
          </div>
        )}
      </PageSectionLayout>

      {action.mode === 'user-update' && (
        <CreateUpdateMemberModal
          mode={action.mode}
          team={teamMember}
          handleClose={handleDrawerClose}
          setIsRefresh={setIsRefresh}
        />
      )}

      {action.mode === 'access-control' && (
        <AccessControlModal
          handleClose={handleDrawerClose}
          team={teamMember}
          setIsRefresh={setIsRefresh}
        />
      )}

      {(action.mode === 'team-create' || action.mode === 'team-update') && (
        <CreateUpdateTeam
          mode={action.mode}
          team={team}
          handleClose={(teamFormData?: any, isSaveChanges?: boolean) => {
            if (teamFormData && !isSaveChanges) {
              if (action.mode === 'team-create') {
                handleActionType('team_management', 'team-add-managers')();
              } else if (action.mode === 'team-update') {
                handleActionType(
                  'team_management',
                  'team-add-managers-update'
                )();
              }
              setUpdateTeamFormData(teamFormData);
            } else {
              handleDrawerClose();
            }
          }}
          setIsRefresh={setIsRefresh}
        />
      )}

      {(action.mode === 'team-add-managers' ||
        action.mode === 'team-add-members' ||
        action.mode === 'team-add-managers-update' ||
        action.mode === 'team-add-members-update') && (
        <CreateUpdateTeamMember
          mode={action.mode}
          team={team}
          updateTeamFormData={updateTeamFormData}
          handleClose={() => handleDrawerClose()}
          setIsRefresh={setIsRefresh}
          handleNext={(teamFormData?: any, isSaveChanges?: boolean) => {
            if (
              action.mode === 'team-add-managers' &&
              teamFormData &&
              !isSaveChanges
            ) {
              setUpdateTeamFormData(teamFormData);
              handleActionType('team_management', 'team-add-members')();
            } else if (
              action.mode === 'team-add-managers-update' &&
              teamFormData &&
              !isSaveChanges
            ) {
              setUpdateTeamFormData(teamFormData);
              handleActionType('team_management', 'team-add-members-update')();
            } else if (action.mode === 'team-add-members' && !isSaveChanges) {
              setTeam(undefined);
              setUpdateTeamFormData(undefined);
              handleActionType(
                'team_management',
                'team-created-confirmation'
              )();
            } else if (
              action.mode === 'team-add-members-update' ||
              isSaveChanges
            ) {
              setTeam(undefined);
              setUpdateTeamFormData(undefined);
              handleDrawerClose();
            }
          }}
        />
      )}

      {action.mode === 'team-created-confirmation' && (
        <CommonModal
          title="Team successfully created"
          titleDesc="Team is successfully created.Please add new team member"
          handleClose={handleDrawerClose}
          headerIcon={<CheckCircle />}
          secondaryBtnOnClick={handleDrawerClose}
          primaryBtnText="Get Started"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={handleDrawerClose}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}

      {action.mode === 'user-remove-from-team' && (
        <DeleteModal
          handleClose={handleUserDeleteConfirmationClose}
          moduleName="team"
          moduleTitle={
            <>
              Delete {teamMember.firstName ?? ''} from Team {team?.name ?? ''}
            </>
          }
        />
      )}

      {action.mode === 'team-remove' && (
        <DeleteModal
          handleClose={handleTeamDeleteConfirmationClose}
          moduleName="team"
          moduleTitle={<>Delete {team?.name ?? ''}</>}
        />
      )}

      {action.mode === 'view_profile' && (
        <MemberProfile
          handleClose={handleDrawerClose}
          teamMemberId={teamMember?.id}
        />
      )}
    </>
  );
};

export default TeamManagement;
