import { yupResolver } from '@hookform/resolvers/yup';
import { File06 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { PATH } from 'src/constants/path';
import { updateClaimStatus } from 'src/services/ClaimService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const claimResolveSchema = yup.object().shape({
  claimResolveDescription: yup.string().required('Description is required.'),
  claimID: yup.string().required('ClaimID is required.'),
});

interface IProps {
  handleClose: () => void;
  claim: any;
  getClaimList?: () => void;
  isClaimListPage: boolean;
}

const ClaimResolve = ({
  handleClose,
  claim,
  getClaimList,
  isClaimListPage,
}: IProps) => {
  const navigate = useNavigate();

  const [isClaimStatusUpdateLoading, setIsClaimStatusUpdateLoading] =
    useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(claimResolveSchema),
    defaultValues: {
      claimResolveDescription: '',
    },
  });

  useEffect(() => {
    if (claim && claim?.claimID) {
      setValue('claimID', `#${claim?.claimID}`);
    }
  }, []);

  const onSubmit = (formData: any) => {
    setIsClaimStatusUpdateLoading(true);

    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;

      if (key !== 'claimID') {
        appendFormData.append(key, value);
      }
    });

    if (claim && claim?.claimID) {
      appendFormData.append('claimID', claim?.claimID);
    }
    appendFormData.append('status', 'Resolved');

    updateClaimStatus(appendFormData)
      .then(() => {
        if (isClaimListPage && getClaimList) {
          getClaimList();
        } else {
          navigate(PATH.CLAIMS_AND_DISPUTES_DETAILS);
        }
      })
      .catch(() =>
        WalToast.error('Something went wrong while resolving claim.')
      )
      .finally(() => {
        setIsClaimStatusUpdateLoading(false);
        handleClose();
      });
  };

  const headerIcon = <File06 className="w-7 h-7" />;

  return (
    <CommonModal
      title={`Resolve Claim`}
      titleDesc={`Add description to resolve claim`}
      handleClose={handleClose}
      size={'max-w-[540px]'}
      headerIcon={headerIcon}
      primaryBtnText="Save"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isClaimStatusUpdateLoading}
      primaryBtnDisabled={isClaimStatusUpdateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isClaimStatusUpdateLoading}
    >
      <div className="p-5">
        <Controller
          name="claimID"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Claim ID"
              inputName="claimID"
              placeholder="Claim ID"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              disabled={true}
              errorText={errors?.claimID?.message ?? ''}
              labelClassName="block mb-1.5"
            />
          )}
        />

        <Controller
          name="claimResolveDescription"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="claimResolveDescription"
              label="Resolution description"
              placeholder="Enter Resolve Claim Description"
              value={value}
              onChangeFunc={onChange}
              labelClassName="block mb-1.5"
              className="h-32"
              errorText={errors?.claimResolveDescription?.message ?? ''}
              asTextarea="textarea"
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default ClaimResolve;
