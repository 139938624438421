import { ArrowUpRight } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';

import CustomerSplitPieChartModal from '../CustomerSplitPieChartModal';

import CustomerOrdersTable from './CustomerOrdersTable';
import CustomerSplitAsideTableFooterRow from './CustomerSplitAsideTableFooterRow';
import CustomerSplitAsideTableRow from './CustomerSplitAsideTableRow';
import CustomerSplitAsideTableRowLoader from './CustomerSplitAsideTableRowLoader';
import CustomerSplitDetailTable from './CustomerSplitDetailTable';

interface IProps {
  customerList: any;
  isDataLoading: boolean;
}

const CustomerSplitAsideTable = ({ customerList, isDataLoading }: IProps) => {
  const [filteredCustomerList, setFilteredCustomerList] = useState<any[]>([]);
  const [sortData, setSortData] = useState({
    sortField: 'createdAt',
    sortType: 'desc',
  });
  const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowPieChartModal, setIsShowPieChartModal] = useState(false);
  const [isShowDetailModal, setIsShowDetailModal] = useState(false);
  const [isShowOrdersModal, setIsShowOrdersModal] = useState(false);
  const [pageItem, setPageItem] = useState([...filteredCustomerList]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [totalObj, setTotalObj] = useState<any>({});

  //Static values
  const recordsPerPage = 8;

  useEffect(() => {
    setIsLoading(isDataLoading);
  }, [isDataLoading]);

  useEffect(() => {
    setFilteredCustomerList([...customerList]);
  }, [customerList]);

  useEffect(() => {
    let tempTotals: any = {
      customersCount: filteredCustomerList.length,
    };
    const pageItems = filteredCustomerList.slice(
      (currentPage - 1) * recordsPerPage,
      currentPage * recordsPerPage
    );

    pageItems.forEach((customerRecords) => {
      Object.entries(customerRecords).forEach(([key, value]) => {
        if (key !== 'name' && key !== 'city') {
          if (!tempTotals[key]) {
            tempTotals[key] = 0;
          }

          if (typeof value === 'number') {
            tempTotals[key] += value;
          }
        }
      });
    });

    setTotalObj(tempTotals);
  }, [filteredCustomerList, currentPage, recordsPerPage]);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Customer',
        sortable: true,
      },
      {
        id: 'orderCount',
        name: 'Total Orders',
        sortable: true,
      },
      {
        id: 'profit',
        name: 'Gross Profit',
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    setSortData({ sortField: 'name', sortType: 'desc' });
  }, [customerList]);

  useEffect(() => {
    if (filteredCustomerList.length > recordsPerPage) {
      const pageItems = filteredCustomerList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );

      if (pageItems.length <= 7) {
        const blankRow = Object.keys(pageItems[0]).reduce(
          (acc: any, key: any) => {
            acc[key] = '';

            return acc;
          },
          {}
        );

        blankRow.id = 0;
        pageItems.push(blankRow);
      }
      setPageItem([...pageItems]);
    } else {
      let customerData: any = [];

      if (
        filteredCustomerList?.length <= 7 &&
        filteredCustomerList?.length > 0
      ) {
        const blankRow = Object.keys(filteredCustomerList[0]).reduce(
          (acc: any, key: any) => {
            acc[key] = '';

            return acc;
          },
          {}
        );

        blankRow.id = 0;
        customerData.push(blankRow);
      }
      setPageItem([...filteredCustomerList, ...customerData]);
    }
  }, [filteredCustomerList, currentPage, recordsPerPage]);

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedCustomerList = filteredCustomerList.sort((a: any, b: any) => {
      if (sortField === 'name') {
        const nameA = a.name?.toUpperCase(); // to ensure case-insensitive comparison
        const nameB = b.name?.toUpperCase(); // to ensure case-insensitive comparison

        if (sortType === 'asc') {
          return nameA?.localeCompare(nameB);
        }

        return nameB?.localeCompare(nameA);
      } else {
        if (a[sortField] < b[sortField]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortField] > b[sortField]) {
          return sortType === 'asc' ? 1 : -1;
        }
      }

      return 0;
    });

    setFilteredCustomerList([...sortedCustomerList]);
  };

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  const handleOnRowClick = (currentCustomer: any) => {
    setIsShowOrdersModal(true);
    setSelectedCustomer(currentCustomer);
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="table-top-header flex-wrap ">
          <div className="table-left-wrap flex-none max-mdm:flex-[0_0_100%]">
            <div className="table-title-wrap flex-wrap">
              <h5 className="table-title">Customer Split</h5>
            </div>
            {/* <div className="text-grayLight900 text-base font-semibold pr-2 flex lg:gap-3 gap-3 items-center flex-wrap">
              Customer Split
              {!isLoading && (
                <BadgeCmp style="modern" type="success">
                  {filteredCustomerList.length}{' '}
                  {filteredCustomerList.length > 1 ? 'Customers' : 'Customer'}
                </BadgeCmp>sortField
              )}
              {customerList.length > 0 && (
                <div
                  className="flex justify-between items-center cursor-pointer flex-none"
                  onClick={() => setIsShowPieChartModal(true)}
                >
                  <div className="flex text-[11px] items-center text-primary font-semibold">
                    View Graph <ArrowUpRight className="h-4 w-4" />
                  </div>
                </div>
              )}
            </div> */}
          </div>
          <div className="flex gap-2.5 flex-wrap">
            {/* {customerList.length > 0 && (
                  <div
                    className="flex justify-between items-center cursor-pointer flex-none"
                    onClick={() => setIsShowPieChartModal(true)}
                  >
                    <div className="flex text-[11px] items-center text-primary font-semibold">
                      View Graph <ArrowUpRight className="h-4 w-4" />
                    </div>
                  </div>
                )} */}
            {customerList.length > 0 && (
              <div
                className="flex justify-between items-center cursor-pointer flex-none"
                onClick={() => setIsShowDetailModal(true)}
              >
                <div className="flex text-[11px] items-center text-primary font-semibold">
                  View All <ArrowUpRight className="h-4 w-4" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full bg-white border-t border-gray100 overflow-x-auto custom-scrollbar scrollbar-hide flex-none">
          <TableCmp
            params={sortData}
            setParams={setSortData}
            headCells={headCells}
            tableDataArr={isLoading ? [] : pageItem}
            TableRowCmp={CustomerSplitAsideTableRow}
            TableLoaderRowCmp={CustomerSplitAsideTableRowLoader}
            isTableDataLoading={isLoading}
            numberOfSkeletonRows={10}
            tableHeaderClass=""
            onRowClick={(ecurrentCust: any) => handleOnRowClick(ecurrentCust)}
            isTableRowClickable={true}
            tableRowCmpProps={{
              customerList: customerList,
            }}
            TableFooterRowCmp={CustomerSplitAsideTableFooterRow}
            footerDataArr={[totalObj]}
            trCustomClass={true}
            trCustomBaseHeight={205}
            totalHeight={'727px'}
          />
        </div>
        {filteredCustomerList.length <= 0 && !isDataLoading && (
          <NotFoundUI
            title="No Customer found"
            desc="There are no data for team members you can create new team member."
            containerClassName="min-h-[unset]"
          />
        )}
      </div>
      <div className="w-full">
        {!isLoading && (
          <CustomPagination
            recordsPerPage={recordsPerPage}
            totalRecords={filteredCustomerList.length}
            currentPage={currentPage}
            handlePagination={setCurrentPage}
          />
        )}
      </div>
      {isShowPieChartModal && (
        <CustomerSplitPieChartModal
          handleClose={() => setIsShowPieChartModal(false)}
          customerList={customerList}
        />
      )}
      {isShowDetailModal && (
        <CustomerSplitDetailTable
          handleClose={() => setIsShowDetailModal(false)}
          customerList={customerList}
          isLoading={isLoading}
          // currentFilterType={currentFilterType}
        />
      )}
      {isShowOrdersModal && (
        <CustomerOrdersTable
          handleClose={() => {
            setIsShowOrdersModal(false);
            setSelectedCustomer(null);
          }}
          selectedCustomer={selectedCustomer}
          isLoading={isLoading}
          // currentFilterType={currentFilterType}
        />
      )}
    </>
  );
};

export default CustomerSplitAsideTable;
