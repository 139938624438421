import {
  ArrowLeft,
  ArrowRight,
  Check,
  Mail02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useContext, useEffect, useState } from 'react';
import InputText from 'src/components/InputText/InputText';
import { AuthContext } from 'src/context/AuthContext';
import { getShortName } from 'src/utils/CommonFunctions';

import defaultImage from '../../../../assets/img/default-image.jpg';
import CommonModal from '../../../../components/CommonModal';
import { CARRIERS } from '../../../../constants/common';

const SpotquoteConfirmation = ({
  handleClose,
  dimensions,
  // services,
  selectedCarrierRates,
  addressDetails,
  loading,
}: any) => {
  const [doNotAskChecked] = useState<boolean>(false);
  const [serviceNames, setServiceNames] = useState<any[]>([]);
  const [activeFilter, setActiveFilter] = useState<any>(undefined);
  const [currentSelected, setCurrentSelected] = useState<any>(0);
  const [selectedServices, setSelectedServices] = useState<any[]>(
    selectedCarrierRates?.[0]?.services
  );
  const { currentUser } = useContext(AuthContext);

  const getApiName = (selectedCarrierRate: any, service: any) => {
    let apiName;

    if (selectedCarrierRate.freightServiceId) {
      apiName = service.freightcomName ?? '';
    } else if (selectedCarrierRate.isBanyan) {
      apiName = service.banyanName ?? '';
    } else {
      switch (selectedCarrierRate?.slug) {
        case CARRIERS.ARC_BEST.SLUG:
          apiName = service.arcName ?? '';
          break;
        case CARRIERS.POLARIS.SLUG:
          apiName = service.polarisName ?? '';
          break;
        case CARRIERS.DAY_ROSS.SLUG:
          apiName = service.dayRossName ?? '';
          break;
        case CARRIERS.DAY_ROSS_USA.SLUG:
          apiName = service.dayRossName ?? '';
          break;
        case CARRIERS.FREIGHTCOM.SLUG:
          apiName = service.freightcomName ?? '';
          break;
        case CARRIERS.MARITIME_ONTARIO.SLUG:
          apiName = service.maritimeOnName ?? '';
          break;
        case CARRIERS.CSA.SLUG:
          apiName = service.csaName ?? '';
          break;
        case CARRIERS.KINDERSLEY.SLUG:
          apiName = service.kindersleyName ?? '';
          break;
        default:
          break;
      }
    }

    return apiName;
  };

  const handleChangeCarrierChange = (type: string) => {
    const key = selectedCarrierRates.findIndex(
      (service: any) => service.id == activeFilter
    );

    if (type === 'prev') {
      if (selectedCarrierRates[key - 1]) {
        setActiveFilter(selectedCarrierRates[key - 1].id);
        setCurrentSelected(key - 1);
        const curentRate = selectedCarrierRates[key - 1];
        setSelectedServices(curentRate.services);
      }
    }

    if (type === 'next') {
      if (selectedCarrierRates[key + 1]) {
        setActiveFilter(selectedCarrierRates[key + 1].id);
        setCurrentSelected(key + 1);
        const curentRate = selectedCarrierRates[key + 1];
        setSelectedServices(curentRate.services);
      }
    }
  };

  useEffect(() => {
    if (selectedCarrierRates.length > 0) {
      // setTabArray(
      //     selectedCarrierRates.map((selectedCarrierRate: any) => {
      //         return {
      //             value: selectedCarrierRate.id.toString(),
      //             name: selectedCarrierRate.name,
      //             image: selectedCarrierRate.image ? selectedCarrierRate.imageUrl + selectedCarrierRate.image : defaultImage,
      //         };
      //     }),
      // );
      setActiveFilter(selectedCarrierRates[0].id.toString());
    }
  }, [selectedCarrierRates]);

  useEffect(() => {
    const findNames: any[] = [];

    if (selectedServices.length > 0) {
      selectedServices.forEach((service: any) => {
        const serviceName = getApiName(
          selectedCarrierRates.length > 0
            ? selectedCarrierRates[currentSelected]
            : [],
          service.serviceId
        );

        if (serviceName) {
          findNames.push(serviceName);
        }
      });
    }
    setServiceNames(findNames);
  }, [selectedServices]);

  const headerIcon = <Mail02 className="text-textSecondary w-5 h-5" />;

  return (
    <CommonModal
      handleClose={handleClose(false)}
      titleDesc="Create new carrier for free in less than 5 minutes."
      headerIcon={headerIcon}
      title="Request a Spot Quote"
      primaryBtnText="Submit"
      primaryBtnOnClick={handleClose(true, currentUser?.email, doNotAskChecked)}
      size={'max-w-3xl'}
      primaryBtnLoading={loading}
    >
      <div className="w-full p-5 ">
        <div className=" p-5 flex flex-col gap-4 rounded-xl border border-borderPrimary">
          {selectedCarrierRates.map((selectedCarrierRate: any) => (
            <div
              key={selectedCarrierRate?.name}
              className={`${
                selectedCarrierRate.id == activeFilter
                  ? ' flex-1 flex flex-col'
                  : 'hidden'
              }`}
              data-testid={selectedCarrierRate.id}
            >
              <div className="flex xs:flex-row flex-col-reverse xs:justify-between pb-5 border-b border-utilityGray200">
                <div className="flex gap-2 items-center">
                  <img
                    src={
                      selectedCarrierRate.image
                        ? selectedCarrierRate.imageUrl +
                          selectedCarrierRate.image
                        : defaultImage
                    }
                    className="object-cover w-5 h-5 rounded flex-none border-gray-200 border-[1px]"
                    alt=""
                  />
                  <p className="text-textSecondary text-base font-bold">
                    {selectedCarrierRate.name}
                  </p>
                </div>
                {selectedCarrierRates.length > 1 && (
                  <ul className="flex sm:gap-3 gap-1.5 items-center xs:mb-0 mb-5 xs:justify-normal justify-between">
                    <li
                      className={`border-utilityGray200 border flex items-center justify-center rounded-full sm:w-[38px] xs:w-9 w-8 sm:h-[38px] xs:h-9 h-8 ${
                        currentSelected == 0
                          ? 'cursor-not-allowed text-gray-300'
                          : 'cursor-pointer'
                      }`}
                    >
                      <ArrowLeft
                        className={`w-4 h-4 `}
                        onClick={() => handleChangeCarrierChange('prev')}
                      />
                    </li>
                    <li className="text-gray500 text-base font-semibold">
                      {currentSelected + 1}/{selectedCarrierRates?.length}
                    </li>
                    <li
                      className={`border-utilityGray200 border flex items-center justify-center rounded-full sm:w-[38px] xs:w-9 w-8 sm:h-[38px] xs:h-9 h-8 ${
                        currentSelected !== selectedCarrierRates.length - 1
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed text-gray-300'
                      }`}
                    >
                      <ArrowRight
                        className={`w-4 h-4 `}
                        onClick={() => handleChangeCarrierChange('next')}
                      />
                    </li>
                  </ul>
                )}
              </div>
              <div className="flex-col gap-3.5 flex py-4 mb-4 border-b border-utilityGray200">
                <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
                  <span className="text-gray500 text-sm font-semibold sm:min-w-[60px] min-w-6">
                    From:
                  </span>
                  <div className="items-center gap-2 flex text-textSecondary text-xs font-semibold flex-wrap">
                    {currentUser?.image ? (
                      <img
                        alt=""
                        src={currentUser.imageUrl + currentUser.image}
                        className="w-7 h-7 relative rounded-full border border-black/[0.08]"
                      />
                    ) : (
                      <div className="w-7 h-7 bg-primary rounded-full text-white font-semibold flex items-center justify-center uppercase border border-black/[0.08] flex-none">
                        {getShortName(
                          `${currentUser?.firstName} ${currentUser?.lastName}`
                        )}
                      </div>
                    )}
                    <span className="font-medium sm:w-auto break-all">
                      {currentUser?.firstName} {currentUser?.lastName}
                    </span>
                    <span className="underline font-medium sm:w-auto break-all">
                      {currentUser.email}
                    </span>
                  </div>
                </div>
                {selectedCarrierRates.length > 0 &&
                  selectedCarrierRate.spotQuoteEmail && (
                    <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
                      <span className="text-gray500 text-sm font-semibold sm:min-w-[60px] min-w-6">
                        To:
                      </span>
                      <InputText
                        inputName="Email"
                        placeholder="-"
                        parentClassName="flex-1"
                        value={
                          selectedCarrierRates.length > 0
                            ? selectedCarrierRate.email
                            : '-'
                        }
                      />
                    </div>
                  )}
                <div className="md:gap-6 sm:gap-4 gap-2 text-grayLight600 text-xs flex sm:flex-row flex-col sm:items-center">
                  <div className=" text-gray500 text-sm font-semibold text-nowrap sm:min-w-[60px] min-w-6">
                    Subject :{' '}
                  </div>
                  <InputText
                    inputName="Subjec"
                    placeholder="-"
                    parentClassName="flex-1"
                    value={
                      'Spot Quote Request (SQ-1219)  - Shipment Details Enclosed'
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col border border-utilityGray200 rounded-xl px-4 py-3 gap-4 ">
                <p className="text-grayLight600 text-xs font-normal">Hi,</p>
                <p className="text-grayLight600 text-xs font-normal">
                  We are looking to get a spot quote for the following load:{' '}
                </p>

                <h5 className="text-grayLight900 text-xs font-bold pt-1">
                  Shipment Details
                </h5>
                <div className="flex items-center text-xs ">
                  <b className="mr-1 text-grayLight600 font-medium">
                    Pickup Date:
                  </b>
                  <span className="font-normal text-grayLight600">
                    {moment(addressDetails.pickupDate).format(
                      'dddd, MMMM Do, YYYY'
                    )}
                  </span>
                </div>

                <div className="">
                  <h6 className="text-grayLight600 uppercase text-[11px] leading-4 font-medium mb-1">
                    SHIPPER DETAILS
                  </h6>
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide rounded-xl border border-utilityGray200 bg-white shadow-sm">
                    <table className="w-full text-xs text-left  text-nowrap whitespace-nowrap">
                      <thead>
                        <tr className="text-grayLight600 border-b border-utilityGray200">
                          <th className="px-5 py-3 font-medium">Address</th>
                          <th className="px-5 py-3 font-medium">City</th>
                          <th className="px-5 py-3 font-medium">Province</th>
                          <th className="px-5 py-3 font-medium">PostalCode</th>
                          <th className="px-5 py-3 font-medium">Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-grayLight600 border-b border-utilityGray200 bg-gray50">
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.shipperAddress1}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.shipperCity}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.shipperStateCode}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.shipperPostal}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.shipperCountryCode}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="">
                  <h6 className="text-grayLight600 uppercase text-[11px] leading-4 font-medium mb-1">
                    CONSIGNEE DETAILS
                  </h6>
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide rounded-xl border border-utilityGray200 bg-white shadow-sm">
                    <table className="w-full text-xs text-left  text-nowrap whitespace-nowrap">
                      <thead>
                        <tr className="text-grayLight600 border-b border-utilityGray200">
                          <th className="px-5 py-3 font-medium">Address</th>
                          <th className="px-5 py-3 font-medium">City</th>
                          <th className="px-5 py-3 font-medium">Province</th>
                          <th className="px-5 py-3 font-medium">PostalCode</th>
                          <th className="px-5 py-3 font-medium">Country</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-grayLight600 border-b border-utilityGray200 bg-gray50">
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.consigneeAddress1}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.consigneeCity}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.consigneeStateCode}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.consigneePostal}
                          </td>
                          <td className="px-5 py-3 font-normal">
                            {addressDetails?.consigneeCountryCode}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="">
                  <h6 className="text-grayLight600 uppercase text-[11px] leading-4 font-medium mb-1">
                    Dimensions
                  </h6>
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide rounded-xl border border-utilityGray200 bg-white shadow-sm">
                    <table className="w-full text-xs text-left  text-nowrap whitespace-nowrap">
                      <thead>
                        <tr className="text-grayLight600 border-b border-utilityGray200">
                          <th className="px-5 py-3 font-medium">Quantity</th>
                          <th className="px-5 py-3 font-medium">Description</th>
                          <th className="px-5 py-3 font-medium">
                            Dimensions (
                            <span className="font-bold">
                              {dimensions.length > 0 &&
                                dimensions[0].lengthMeasurement.toLowerCase()}
                            </span>
                            )
                          </th>
                          <th className="px-5 py-3 font-medium">Class</th>
                          <th className="px-5 py-3 font-medium">
                            Total weight
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dimensions.map((dimension: any, index: number) => (
                          <tr
                            className="text-grayLight600 border-b border-utilityGray200 bg-gray50"
                            key={`spot_dim_${index}`}
                          >
                            <td className="px-5 py-3 font-semibold text-grayLight900">
                              {dimension.handlingUnitNo < 10
                                ? `0${dimension.handlingUnitNo}`
                                : dimension.handlingUnitNo}
                            </td>
                            <td className="px-5 py-3 font-normal">
                              {dimension.handlingUnitName}
                            </td>
                            <td className="px-5 py-3 font-normal">
                              Length: {dimension.itemLength} Width:{' '}
                              {dimension.width} Height: {dimension.height}
                            </td>
                            <td className="px-5 py-3 font-normal">
                              {dimension.class ?? '-'}
                            </td>
                            <td className="px-5 py-3 font-normal">
                              {' '}
                              {dimension.totalWeight} (
                              {dimension.weightMeasurement})
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {serviceNames.length > 0 && (
                  <>
                    <h6 className="border-t pt-4 text-grayLight900 text-xs font-bold">
                      Additional Services
                    </h6>
                    <div className="grid mdm:grid-cols-3 md:grid-cols-2 gap-4">
                      {serviceNames.map((service: any) => (
                        <div key={service} className=" items-start flex gap-1">
                          <Check className="w-4 h-4 text-primary" />
                          <div className="text-grayLight600 text-xs font-medium">
                            {service}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </CommonModal>
  );
};

export default memo(SpotquoteConfirmation);
