import { Check } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getProgressClass,
  goalTarget,
} from 'src/utils/CommonFunctions';

import { ICustomerFooterRowProps } from './Target.interface';

const TargetSetFooterRow = ({ data }: ICustomerFooterRowProps) => (
  <>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <span className="h-[32.5px] flex items-center">{data.name}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[13rem] w-[13rem] max-w-[13rem]">
      <span>-</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[150px] w-[12%]">
      <span>
        ${getFormattedNumber(data.completedProfit, false, false, true)}
      </span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[150px] w-[12%]">
      <span>${getFormattedNumber(data.targetProfit, false, false, true)}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50  min-w-[200px] w-[12%]">
      {/* <progress
        className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
          data.completedProfitPercentage
        )}`}
        value={data.completedProfitPercentage}
        max="100"
      ></progress>
      <span className="text-textSecondary font-medium">
        {data.completedProfitPercentage}%
      </span> */}
      <TooltipCmp message={''}>
        <div
          className={`radial-progress text-[10px] ${getProgressClass(
            data.completedProfitPercentage,
            false
          )}`}
          style={
            {
              '--value': data.completedProfitPercentage,
              '--size': '2rem',
            } as React.CSSProperties & { '--value': number }
          }
          role="progressbar"
        >
          {data.completedProfitPercentage &&
          data.completedProfitPercentage >= 100 ? (
            <>
              <Check className="w-4 h-4" />
            </>
          ) : (
            <span className="text-grayLight900 font-medium">
              {data.completedProfitPercentage || 0}%
            </span>
          )}

          <div
            className="target-div"
            style={
              {
                '--value': goalTarget(),
              } as React.CSSProperties & { '--value': number }
            }
          ></div>
        </div>
      </TooltipCmp>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50  min-w-[100px] w-[10%]">
      <span>{data.completedLoads}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[100px] w-[10%]">
      <span>{data.targetLoads}</span>
    </td>
    <td className="px-5 py-4 font-semibold text-sm bg-gray50 min-w-[200px] w-[12%]">
      {/* <progress
        className={`[&::-webkit-progress-bar]:rounded-lg  [&::-webkit-progress-value]:rounded-lg rounded-lg progress w-[80px] progress-primary bg-utilityGray200 me-3 ${getProgressClass(
          data.completedLoadsPercentage
        )}`}
        value={data.completedLoadsPercentage}
        max="100"
      ></progress>
      <span className="text-textSecondary font-medium">
        {data.completedLoadsPercentage}%
      </span> */}
      <TooltipCmp message={''}>
        <div
          className={`radial-progress text-[10px] ${getProgressClass(
            data.completedLoadsPercentage,
            false
          )}`}
          style={
            {
              '--value': data.completedLoadsPercentage,
              '--size': '2rem',
            } as React.CSSProperties & { '--value': number }
          }
          role="progressbar"
        >
          {data.completedLoadsPercentage &&
          data.completedLoadsPercentage >= 100 ? (
            <>
              <Check className="w-4 h-4" />
            </>
          ) : (
            <span className="text-grayLight900 font-medium">
              {data.completedLoadsPercentage}%
            </span>
          )}
          <div
            className="target-div"
            style={
              {
                '--value': goalTarget(),
              } as React.CSSProperties & { '--value': number }
            }
          ></div>
        </div>
      </TooltipCmp>
    </td>
  </>
);

export default TargetSetFooterRow;
