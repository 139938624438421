import { Plus, X } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';

const TradeReferenceItem = ({
  errors,
  setValue,
  trigger,
  touchedFields,
  control,
  tradeReferenceArr,
  addRaw,
  removeRaw,
}: any) => (
  <div className="rounded-lg border border-utilityGray200 shadow-xs p-4 flex flex-col gap-y-4">
    {tradeReferenceArr.map((data: any, index: number) => (
      <div className="flex w-full gap-2 " key={index}>
        <div className="flex-1 flex flex-wrap max-sm:flex-col max-md:gap-y-3 sm:-mx-1.5">
          <Controller
            name={`tradeReferences.${index}.company_name`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Company name"
                inputName={`tradeReferences.${index}.company_name`}
                placeholder="Enter Name"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.tradeReferences?.[index]?.company_name?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <Controller
            name={`tradeReferences.${index}.city`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="City/ State"
                inputName={`tradeReferences.${index}.city`}
                placeholder="Enter city/state"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.tradeReferences?.[index]?.city?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
          <div className="md:w-1/4 sm:w-1/2 sm:px-1.5">
            <label className="form_label block mb-1.5">
              Phone Number <span className="text-red-600 leading-4">*</span>
            </label>
            <Controller
              name={`tradeReferences.${index}.phone_number`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  onNumberChange={(country: any, phone: any, code: any) => {
                    console.log('onNumberChange');
                    const newValue = `+${code}${phone}`;
                    setValue(`tradeReferences.${index}.phone_number`, newValue);
                    setValue('phone_country_code', country);
                    onChange(newValue);

                    if (touchedFields.bank_phone) {
                      trigger(`tradeReferences.${index}.phone_number`);
                      trigger('phone_country_code');
                    }
                  }}
                  value={value}
                  name="bank_phone"
                  errors={
                    errors.creditReferences?.[index]?.phone_number?.message ??
                    null
                  }
                />
              )}
            />
          </div>
          <Controller
            name={`tradeReferences.${index}.email`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Email"
                inputName="email"
                placeholder="Enter email"
                parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                value={value}
                required={true}
                onChangeFunc={onChange}
                errorText={
                  errors.tradeReferences?.[index]?.email?.message ?? null
                }
                labelClassName="block mb-1.5"
              />
            )}
          />
        </div>
        <div className="flex justify-center items-end gap-2 h-[48px]">
          {index > 0 ? (
            <ButtonCmp
              className="btn_primary cursor-pointer !bg-transparent border-[#FFEBE9] text-fgErrorPrimary !border-0  !p-0 rounded-full"
              onClick={() => removeRaw(index, 'trade_reference')}
            >
              <X className="w-4 h-4" />
            </ButtonCmp>
          ) : (
            <span className="w-9 h-9">&nbsp;</span>
          )}
        </div>
      </div>
    ))}
    <div
      className="flex gap-x-1 items-center text-sm text-primary font-medium w-fit cursor-pointer"
      onClick={() => addRaw('trade_reference')}
    >
      <Plus className="w-4 h-4" />
      <span>Add new</span>
    </div>
  </div>
);

export default TradeReferenceItem;
