import { CalendarCheck01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';

interface IProps {
  handleClose: any;
}

const RefreshRatesModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Refresh Expired Rates'}
    titleDesc={'Set a new pickup date for the shipment.'}
    handleClose={() => handleClose(false)}
    size={'max-w-[400px] overflow-unset'}
    isOverflow={false}
    modalClassName=""
  >
    <div className="w-full p-5 gap-4">
      <DateSelect
        inputName="business_start_date"
        className="form_control"
        parentClassName="datepicker-w-auto one-month-datepicker"
        label="Business Started Date"
        selected={new Date()}
        value={'value'} // Ensuring the value is always a Date object
        labelClassName="block"
        placeholder="Business Start Date"
        required={true}
        dateFormat="dd/MM/yyyy"
        errorText={() => {}}
        onChangeFunc={() => {}} // Update form value on change
        icon={<CalendarCheck01 className="h-5 w-5" />}
      />
    </div>
  </CommonModal>
);

export default RefreshRatesModal;
