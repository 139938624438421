import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getSalesHistoryList = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(API.SALES_HISTORY.LIST, {
    params,
    signal,
  });

  return response;
};
