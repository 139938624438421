import {
  ClockFastForward,
  Edit01,
  Lock02,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const LoadingRow = () => (
  <>
    <td className="px-5 py-4 w-80 min-w-80 max-w-80">
      <div className="flex items-center ">
        <div className="w-8 h-8 bg-primary rounded-full text-white flex items-center justify-center uppercase flex-none image-loading custom-loading">
          TE
        </div>
        <div className="ml-3 custom-loading max-w-40">
          <h6 className="text-grayLight900 font-medium leading-tight">
            Zaheer Cheema
          </h6>
          <p className="text-grayLight600 font-normal leading-tight">
            zcheema@westernalliancelogistics.com
          </p>
        </div>
      </div>
    </td>
    <td className="px-5 py-4 w-56 max-w-56 min-w-56">
      <div className="flex ">
        <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
          Operations
        </BadgeCmp>
      </div>
    </td>
    <td className="px-5 py-4 w-56 max-w-56 min-w-56 ">
      <span className="custom-loading">Jul 12, 2024</span>
    </td>
    <td className="px-5 py-4 w-[26rem] max-w-[26rem] min-w-[26rem]">
      <BadgeCmp style="pill" type="warning" mainClassName="custom-loading">
        <ClockFastForward className="mr-1 h-3 w-3 text-lg text-warning500" />{' '}
        Pending
      </BadgeCmp>
    </td>
    <td className="px-5 py-4 ">
      <div className="items-center flex gap-4 justify-end ">
        <TooltipCmp
          message={'Change Permission'}
          childrenClassName="custom-loading"
        >
          <Lock02 className="w-4 h-4 relative cursor-pointer " />
        </TooltipCmp>

        <TooltipCmp
          message={'Delete Member'}
          childrenClassName="custom-loading"
        >
          <Trash01 className="w-4 h-4 relative cursor-pointer" />
        </TooltipCmp>

        <TooltipCmp message={'Edit Member'} childrenClassName="custom-loading">
          <Edit01 className="w-4 h-4 relative cursor-pointer" />
        </TooltipCmp>
      </div>
    </td>
  </>
);

export default LoadingRow;
