import { Edit01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { updateClaimDisputeTask } from 'src/services/ClaimAndDisputeService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

const ActionItemsRowCmp = ({
  data,
  handleDeleteTaskFromList,
  handleEditTaskFromList,
  updateTaskToList,
}: any) => {
  const handleUpdateTask = (taskID: number, param: any) => {
    updateClaimDisputeTask(taskID, param)
      .then((response: any) => {
        if (response && response.updatedAction) {
          const responseData = response.updatedAction;

          if (responseData) {
            updateTaskToList(responseData);
          }
        } else {
          console.log('error');
        }
      })
      .catch(console.error);
  };

  const handleCompleteTask = (isCompleted: boolean) => {
    let params: any = {
      isCompleted: isCompleted,
      progressStatus: isCompleted ? 'completed' : 'in-progress',
    };

    handleUpdateTask(data?.id, params);
  };

  const handleUpdateProgressStatus = () => {
    let params: any = {};

    if (data?.progressStatus === 'new') {
      params.progressStatus = 'in-progress';
    } else if (data?.progressStatus === 'in-progress') {
      params.progressStatus = 'completed';
      params.isCompleted = true;
    } else if (data?.progressStatus === 'completed') {
      params.progressStatus = 'in-progress';
      params.isCompleted = false;
    }

    handleUpdateTask(data?.id, params);
  };

  return (
    <>
      <td
        className="px-5 py-4 min-w-64 w-64 max-w-64 cursor-pointer"
        onClick={handleUpdateProgressStatus}
      >
        <div className="flex gap-x-3 items-center">
          <span onClick={(e) => e.stopPropagation()}>
            <CheckBox
              classes="z-10"
              onChangeFunc={() => handleCompleteTask(!data.isCompleted)}
              checked={data.isCompleted}
            />
          </span>
          <p className="truncate  flex items-center">{data?.taskName}</p>
        </div>
      </td>

      <td
        className="px-5 py-4 max-w-[1px] min-w-[20%] w-[20%] relative cursor-pointer"
        onClick={handleUpdateProgressStatus}
      >
        <div className="flex items-center gap-3 cursor-pointer">
          {data?.assignedTo && data?.assignedTo != '' ? (
            <>
              <div>
                {data?.image && data?.imageUrl ? (
                  <img
                    src={`${data?.imageUrl}${data?.image}`}
                    className="w-8 h-8 rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full border border-black/[0.08] flex-none flex items-center justify-center bg-primary text-white">
                    {getShortName(`${data?.name}`)}
                  </div>
                )}
              </div>
              <div className="truncate">
                <p className="text-grayLight900 font-medium truncate">
                  {data?.name}
                </p>
                <p className="text-grayLight900 truncate">{data?.jobRole}</p>
              </div>
            </>
          ) : (
            <>-</>
          )}
        </div>
      </td>

      <td
        className="px-5 py-4 text-xs max-w-64 min-w-[20%] w-[20%] relative cursor-pointer"
        onClick={handleUpdateProgressStatus}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.dueDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(data.dueDate, `MMM Do - YYYY`, false, false)
                )
              : '-',
          }}
        ></span>
      </td>

      <td
        className="px-5 py-4  min-w-44 max-w-44 relative cursor-pointer"
        onClick={handleUpdateProgressStatus}
      >
        <div className="flex gap-x-1">
          {data?.progressStatus === 'new' && (
            <TooltipCmp message={'Set to In Progress'}>
              <BadgeCmp style="modern" type="info">
                New
              </BadgeCmp>
            </TooltipCmp>
          )}
          {data?.progressStatus === 'in-progress' && (
            <TooltipCmp message={'Set to Completed'}>
              <BadgeCmp style="modern" type="red">
                In Progress
              </BadgeCmp>
            </TooltipCmp>
          )}
          {data?.progressStatus === 'completed' && (
            <BadgeCmp style="modern" type="success">
              Completed
            </BadgeCmp>
          )}
        </div>
      </td>

      <td className="px-5 py-4 max-w-20 w-20 min-w-20">
        {!data?.isCompleted && (
          <div className="flex justify-end gap-2">
            <TooltipCmp message={'Edit'}>
              <Edit01
                className="mt-[1px] w-4 h-4 cursor-pointer"
                onClick={() => handleEditTaskFromList(data)}
              />
            </TooltipCmp>
            <TooltipCmp message={'Delete'}>
              <Trash01
                className="w-4 h-4 cursor-pointer"
                onClick={() => handleDeleteTaskFromList(data.id)}
              />
            </TooltipCmp>
          </div>
        )}
      </td>
    </>
  );
};

export default ActionItemsRowCmp;
