import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Confirmation from 'src/components/Confirmation';
import { PATH } from 'src/constants/path';

// import FloraFauna from '../../assets/img/FloraFauna.png';
// import LogoDark from '../../assets/img/logo-dark.png';

const Thankyou = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: any }>();

  const handleFormEdit = () => {
    navigate(`${PATH.CUSTOMER_ONBOARDING}/${id}`);
  };

  return (
    <div className="overflow-y-auto h-full p-5 bg-utilityGray50 flex flex-col">
      <div className="max-w-[1200px] rounded-xl bg-white shadow-lgc xl:my-[30px] my-5 mx-auto flex flex-col flex-1 overflow-hidden w-full">
        <div className="border-b border-borderPrimary px-5 py-3 flex flex-wrap gap-3 justify-between">
          <div className="max-w-[517px] ">
            <h6 className="text-grayLight900 text-base font-semibold">
              Submission Completed
            </h6>
            <p className="text-grayLight600 text-xs font-normal ">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </p>
          </div>
          {/* <div className="flex items-center">
          <div className="max-w-[113px] ">
            <img src={LogoDark} alt="logo-light" className="w-full" />
          </div>
          <div className="max-w-[128px] min-w-[50px] pl-3 ml-3 border-l border-borderPrimary ">
            <img src={FloraFauna} className="w-full object-cover" />
          </div>
        </div> */}
        </div>
        <div className="bg-gray50 h-full">
          <Confirmation
            title="Thank You!"
            Icon={<CheckCircle className="w-7 h-7" />}
            desc="Thank you for submitting the form. Our team will be in contact shortly."
            containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            buttonText="Edit Form"
            handleActionType={handleFormEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
