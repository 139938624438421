import { API } from 'src/constants/api';

import { axiosInterceptor } from './interceptors';

export const setUsetStatus = async (data: any) => {
  const response = await axiosInterceptor.post(API.USER.USER_STATUS, data);

  return response;
};

export const switchUserRole = async (data: any) => {
  const response = await axiosInterceptor.post(API.USER.SWITCH_ROLE, data);

  return response;
};
