import {
  ArrowRight,
  ArrowUp,
  PackageCheck,
  Route,
  Target05,
  Truck01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';

import avatar from '../../../assets/img/Avatar1.png';

const ActiveShipments = () => {
  console.log('ActiveShipments :>> ');

  return (
    <div className="fixed bottom-10 right-10 w-[500px] h-[calc(100vh_-_198px)] bg-white rounded-[10px] shadow-sm border border-utilityGray200 z-[2] flex flex-col">
      <div className="border-b border-utilityGray200  sticky top-0 z-[1] bg-white py-5 mx-4 ">
        <div className="flex items-start gap-2">
          <div className="bg-utilityGray100 rounded-md border border-utilityGray200 shadow-xs p-[9px] mt-0.5">
            <Route className="w-4 h-4" />
          </div>
          <div className="flex-1">
            <h6 className="text-primary text-base font-medium underline">
              #WAL-DMIF-1
            </h6>
            <div className="text-grayLight900 text-xs font-medium flex items-center mt-1">
              <p className="w-3 h-3 bg-successSecondary rounded-full border-[3px] border-utilitySuccess100 mr-1.5"></p>
              <p>Last Update: Today, 11:03 AM</p>
            </div>
          </div>
          {/* <ButtonCmp className="!p-1.5 btn_secondary_black">
            <Minimize02
              className="w-4 h-4 text-primary cursor-pointer"
              onClick={() => {
                console.log('button :>> ');
                handleClose();
              }}
            />
          </ButtonCmp> */}
          <BadgeCmp style="modern" type="success">
            LTL
          </BadgeCmp>
        </div>
        <div className="flex items-center mt-6">
          <div className="flex-1">
            <p className="text-gray500 text-xs font-normal">From</p>
            <p className="text-textSecondary text-xs font-medium">
              Montreal Eaton Centre, West, Montreal, QC, Canada
            </p>
          </div>
          <ButtonCmp className="lg:!px-[9px] !px-2 btn_secondary_black mx-3">
            <ArrowRight className="w-4 h-4" />
          </ButtonCmp>
          <div className="pl-3 flex-1">
            <p className="text-gray500 text-xs font-normal">To</p>
            <p className="text-textSecondary text-xs font-medium">
              8001 S Orange Blossom Trl, Orlando, FL 32809, USA
            </p>
          </div>
        </div>
      </div>
      <div className="my-4 px-4  flex flex-col gap-y-5 flex-1 overflow-y-auto  custom-scrollbar-v2 ">
        <ul className="border border-utilityGray200 rounded-lg p-3 flex flex-wrap gap-y-4">
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Customer Name
            </h6>
            <div className="flex items-center gap-x-0.5">
              <img
                src={avatar}
                className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              <p className="text-textSecondary text-xs font-medium">
                Olivia Rhye
              </p>
            </div>
          </li>
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Order Created
            </h6>

            <p className="text-textSecondary text-xs font-medium">
              Oct 27, 2022
            </p>
          </li>
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Sales Rep.
            </h6>
            <div className="flex items-center gap-x-0.5">
              <img
                src={avatar}
                className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              <p className="text-textSecondary text-xs font-medium">
                Olivia Rhye
              </p>
            </div>
          </li>
          <li className="sm:w-1/2 odd:pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Carrier
            </h6>
            <div className="flex items-center gap-x-0.5">
              <img
                src={avatar}
                className="w-[18px] h-[18px] rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              <p className="text-textSecondary text-xs font-medium">
                Olivia Rhye
              </p>
            </div>
          </li>
        </ul>
        <ul className="border border-utilityGray200 rounded-lg p-3 flex flex-wrap gap-y-4">
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Total Price
            </h6>
            <p className="text-grayLight900 text-xs font-medium">$905.80</p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Total Cost
            </h6>
            <p className="text-grayLight900 text-xs font-medium">$752.00</p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Margin
            </h6>
            <p className="text-grayLight900 text-xs font-medium">$56.00</p>
          </li>
          <li className="lg:w-1/4 sm:w-1/2 last:pr-0 pr-3">
            <h6 className="text-grayLight600 text-xs font-normal mb-1.5">
              Est. Delivery
            </h6>
            <p className="text-grayLight900 text-xs font-medium">8hrs23mins</p>
          </li>
        </ul>
        <div>
          <div className="flex items-center justify-between gap-x-2 mb-3">
            <h6 className="text-textSecondary text-sm font-semibold">
              Latest Order Updates
            </h6>
            <ButtonCmp
              className="btn_secondary_black"
              icon={<Target05 className="w-4 h-4" />}
            >
              Radar
            </ButtonCmp>
          </div>
          <ul>
            <li className="flex gap-x-3 pb-6 last:pb-0 mb-1 last:mb-0 overflow-hidden">
              <div className="relative after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[calc(100%_+_4px)] after:absolute flex items-center justify-center after:bg-primary">
                <div className="rounded-md shadow-xs p-2 border border-primary text-primary">
                  <Truck01 className="w-4 h-4" />
                </div>
              </div>
              <div className="flex-1">
                <h6 className="text-primary text-xs font-semibold">
                  Jun 19th, 2024
                </h6>
                <p className="text-grayLight600 text-xs font-normal">9:15 AM</p>
              </div>
              <div className="text-right">
                <h6 className="text-primary text-xs font-medium">
                  In delivery to destination
                </h6>
                <p className="text-grayLight900 text-[10px] leading-[14px]">
                  Montrose Beach, North Lake Shore Drive
                </p>
              </div>
            </li>
            <li className="flex gap-x-3 pb-6 last:pb-0 mb-1 last:mb-0 overflow-hidden">
              <div className="relative after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[calc(100%_+_4px)] after:absolute flex items-center justify-center after:bg-utilityGray200">
                <div className="rounded-md shadow-xs p-2 border border-utilityGray200 text-textSecondary">
                  <ArrowUp className="w-4 h-4" />
                </div>
              </div>
              <div className="flex-1">
                <h6 className="text-textSecondary text-xs font-semibold">
                  Jun 18th, 2024
                </h6>
                <p className="text-grayLight600 text-xs font-normal">9:15 AM</p>
              </div>
              <div className="text-right">
                <h6 className="text-gray500 text-xs font-normal">
                  Arrived to local depot
                </h6>
                <p className="text-grayLight900 text-[10px] leading-[14px]">
                  Montrose Beach, North Lake Shore Drive
                </p>
              </div>
            </li>
            <li className="flex gap-x-3 pb-6 last:pb-0 mb-1 last:mb-0 overflow-hidden opacity-60">
              <div className="relative after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[calc(100%_+_4px)] after:absolute flex items-center justify-center after:bg-utilityGray200">
                <div className="rounded-md shadow-xs p-2 border border-utilityGray200 text-textSecondary">
                  <ArrowUp className="w-4 h-4" />
                </div>
              </div>
              <div className="flex-1">
                <h6 className="text-textSecondary text-xs font-semibold">
                  Jun 18th, 2024
                </h6>
                <p className="text-grayLight600 text-xs font-normal">9:15 AM</p>
              </div>
              <div className="text-right">
                <h6 className="text-gray500 text-xs font-normal">
                  Arrived to local depot
                </h6>
                <p className="text-grayLight900 text-[10px] leading-[14px]">
                  Montrose Beach, North Lake Shore Drive
                </p>
              </div>
            </li>
            <li className="flex gap-x-3 pb-6 last:pb-0 mb-1 last:mb-0 overflow-hidden opacity-60">
              <div className="relative after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[calc(100%_+_4px)] after:absolute flex items-center justify-center after:bg-utilityGray200">
                <div className="rounded-md shadow-xs p-2 border border-utilityGray200 text-textSecondary">
                  <PackageCheck className="w-4 h-4" />
                </div>
              </div>
              <div className="flex-1">
                <h6 className="text-textSecondary text-xs font-semibold">
                  Jun 18th, 2024
                </h6>
                <p className="text-grayLight600 text-xs font-normal">9:15 AM</p>
              </div>
              <div className="text-right">
                <h6 className="text-gray500 text-xs font-normal">
                  Arrived to local depot
                </h6>
                <p className="text-grayLight900 text-[10px] leading-[14px]">
                  Montrose Beach, North Lake Shore Drive
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ActiveShipments;
