import { ArrowUp, ArrowDown } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import { getShortName } from 'src/utils/CommonFunctions';

const UserCommissionReportRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4 min-w-52">
      <div className="flex items-center gap-3">
        {data?.userImage ? (
          <img
            src={data?.userImageUrl + data?.userImage}
            className="w-8 h-8 rounded border-[0.75px] border-black/[0.08] flex-none"
          />
        ) : (
          <div className="w-8 h-8 rounded bg-utilityGray100 text-primary flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none">
            {getShortName(`${data?.name}`)}
          </div>
        )}
        <h6 className="text-grayLight900 font-semibold truncate max-w-44">
          {data?.name}
        </h6>
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span>
        {data?.month} {data?.year}
      </span>
    </td>
    <td className="px-5 py-4 ">
      <div className="flex items-center">
        <span className="text-grayLight900 font-semibold">
          ${data?.totalMargin}
        </span>
        {data?.marginPercentageDifference >= 0 ? (
          <BadgeCmp
            style="pill"
            type="success"
            mainClassName="ml-2 flex items-center"
          >
            <ArrowUp className="w-3 h-3" />
            {data?.marginPercentageDifference}%
          </BadgeCmp>
        ) : (
          <BadgeCmp
            style="pill"
            type="error"
            mainClassName="ml-2 flex items-center"
          >
            <ArrowDown className="w-3 h-3" />
            {data?.marginPercentageDifference}%
          </BadgeCmp>
        )}
      </div>
    </td>
    <td className="px-5 py-4 ">
      <span>{parseFloat(data?.averageMarginPercentage).toFixed(2)}%</span>
    </td>
    <td className="px-5 py-4 ">
      <span>${parseFloat(data?.averageMargin).toFixed(2)}</span>
    </td>
    <td className="px-5 py-4 ">
      <span>{data?.numberOfOrders}</span>
    </td>
  </>
);

export default UserCommissionReportRow;
