/* eslint-disable max-lines-per-function */
import {
  Calendar,
  CurrencyDollar,
  InfoCircle,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CgCheck } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import TooltipCmp from 'src/components/TooltipCmp';

import defaultImage from '../../../../assets/img/default-image.jpg';
import ButtonCmp from '../../../../components/ButtonCmp';
import CommonModal from '../../../../components/CommonModal';
import ConfirmModal from '../../../../components/ConfirmModal';
import DateSelect from '../../../../components/DateSelect/DateSelect';
import Drawer from '../../../../components/Drawer';
import InputText from '../../../../components/InputText/InputText';
import TabButton from '../../../../components/TabButton';
import Toggle from '../../../../components/Toggle/Toggle';
import {
  MARGIN_TYPE,
  ROSE_ROCKET,
  CURRENCY,
} from '../../../../constants/common';
import { getCarrierServicePrices } from '../../../../services/CarrierService';
import {
  createPDF,
  updatePDF,
  updateStatus,
} from '../../../../services/QuoteService';
import { downloadPdf } from '../../../../utils/CommonFunctions';
import WalToast from '../../../../utils/WalToast';

export const DATE_FORMAT: string = 'MMM DD, YYYY';

enum QuoteType {
  QUOTED = 'quoted',
  NON_QUOTED = 'non_quoted',
  WITH_RATE = 'with _rate',
}

const initEditableFormData = {
  transitTime: false,
  projectedDeliveryDate: false,
  marginUnit: false,
  totalCharge: false,
};

const initAction = {
  closedQuote: false,
  informationModel: false,
  orderSuccessModel: false,
};

const initColumns = {
  isDisplayCarrier: false,
  isDisplayTransit: true,
};

const initRequiredFields = [
  'margin',
  'totalCharge',
  'finalCharge',
  'transitTime',
  'projectedDeliveryDate',
];

const Pricing = ({
  isOpen,
  mode,
  quoteID,
  selectedCarrierRates,
  setIsRefresh,
  handleClose,
  currency,
  dimensions,
  services,
  setQuoteType,
}: any) => {
  const [isValid, setIsValid] = useState<any>(false);
  // const [isValidNonQuote, setIsValidNonQuote] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  // const [resetFormData, setResetFormData] = useState<any>({});
  const [editableFormData, setEditableFormData] =
    useState(initEditableFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [action, setAction] = useState(initAction);
  const [requiredFields, setRequiredFields] = useState(initRequiredFields);
  const [nonQuoteRequiredFields, setNonQuoteRequiredFields] = useState([]);
  const [columns, setColumns] = useState(initColumns);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [rossRocketId, setRossRocketId] = useState('');
  const [rossRocketPublicId, setRossRocketPublicId] = useState('');
  const [tabArray, setTabArray] = useState([]);
  const [activeFilter, setActiveFilter] = useState(undefined);
  const [pdfLanguage, setPdfLanguage] = useState<any>('english');

  useEffect(() => {
    if (isOpen) {
      setIsEditable(mode === 'update');
      setEditableFormData(initEditableFormData);
      setColumns(initColumns);
    }
  }, [isOpen]);

  useEffect(() => {
    const existingFormData: any = {};
    const existingNonQuoteRequiredFields: any = [];
    const existingRequiredFields = [...initRequiredFields];
    selectedCarrierRates.forEach((selectedCarrierRate: any) => {
      const quotedServices: any = [];
      const nonQuotedServices: any = [];
      const priceData: any = {};

      if (selectedCarrierRate.services && selectedCarrierRate.services.length) {
        selectedCarrierRate.services.forEach((service: any) => {
          if (service.isQuoted) {
            quotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId?.name,
                value: service.value ? parseFloat(service.value).toFixed(2) : 0,
                serviceId: service.serviceId?.id,
              },
            });
          } else {
            nonQuotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId?.name,
                value: service.value
                  ? parseFloat(service.value).toFixed(2) ?? 0
                  : 0,
                serviceId: service.serviceId?.id,
              },
            });
            existingNonQuoteRequiredFields.push(service.serviceId.slug);
          }
          existingRequiredFields.push(service.serviceId.slug);
        });
      }

      getCarrierServicePrices(selectedCarrierRate.carrierId)
        .then((response: any) => {
          if (response.data && response.data.length) {
            response.data.forEach(
              (data: any) =>
                (priceData[data.serviceId] =
                  currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
            );
          }
          selectedCarrierRate.carrierPriceData = priceData;
        })
        .catch(console.log);

      existingFormData[selectedCarrierRate.id] = {
        transitTime: selectedCarrierRate.transitTime,
        projectedDeliveryDate: selectedCarrierRate.projectedDeliveryDate,
        margin:
          selectedCarrierRate.margin > 0
            ? selectedCarrierRate.margin.toFixed(2)
            : selectedCarrierRate.margin,
        marginUnit:
          selectedCarrierRate.marginUnit > 0
            ? selectedCarrierRate.marginUnit.toFixed(2)
            : selectedCarrierRate.marginUnit,
        additionalCharges:
          selectedCarrierRate.additionalCharges > 0
            ? selectedCarrierRate.additionalCharges.toFixed(2)
            : 0,
        finalCharge:
          selectedCarrierRate.finalCharge > 0
            ? selectedCarrierRate.finalCharge.toFixed(2)
            : 0,
        totalCharge:
          selectedCarrierRate.totalCharge > 0
            ? selectedCarrierRate.totalCharge.toFixed(2)
            : 0,
        marginType: selectedCarrierRate.marginType,
        carrierPriceData: priceData,
        quotedServices,
        nonQuotedServices,
      };
    });
    setRequiredFields(existingRequiredFields);
    setNonQuoteRequiredFields(existingNonQuoteRequiredFields);
    setFormData(existingFormData);
    // setResetFormData(existingFormData);
  }, [selectedCarrierRates]);

  useEffect(() => {
    const existingValid: any = {};
    const existingValidNonQuote: any = {};
    Object.entries(formData).forEach(([key, value]: any) => {
      const isValid1 = requiredFields.every(
        (requiredField) =>
          value[requiredField] !== '' && value[requiredField] !== null
      );
      let isValid2 = true;

      if (value.nonQuotedServices && value.nonQuotedServices.length) {
        isValid2 = value.nonQuotedServices.every((nonQuotedService: any) =>
          nonQuoteRequiredFields.every((requiredField) => {
            if (nonQuotedService[requiredField]) {
              return (
                nonQuotedService[requiredField].value !== '' &&
                nonQuotedService[requiredField].value !== null
              );
            }

            return true;
          })
        );
      }
      existingValidNonQuote[key] = isValid2;
      existingValid[key] = isValid1 && isValid2;
    });

    let allCarrierValid: boolean = true;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    Object.entries(existingValid).forEach(([_, value]: any) => {
      if (!value) {
        allCarrierValid = false;
      }
    });

    setIsValid(allCarrierValid);
    // setIsValidNonQuote(existingValidNonQuote);
  }, [formData]);

  // const formatNumberData = (data: any) => {
  //     Object.entries(data).forEach(([key, value]: any) => {
  //         if (["totalCharge", "marginUnit", "margin", "additionalCharges", "finalCharge"].includes(key)) {
  //             if (value !== "") {
  //                 let formValue = parseFloat(value);
  //                 data[key] = Number.isInteger(formValue) ? formValue.toString() : formValue.toFixed(2);
  //             }
  //         }
  //     });
  //     return data;
  // };

  const handleInputChange =
    (id: number, isService = false) =>
    (event: any) => {
      const { target, type } = event;
      const { name, value } = target;
      const regex = /^[0-9.]*$/;

      if (!regex.test(value)) {
        return;
      }

      // setFormData((old: any) => ({ ...old, [id]: existingFormData }));

      const existingFormData: any = {
        ...formData[id],
      };

      if (name === 'transitTime') {
        existingFormData.projectedDeliveryDate = moment()
          .clone()
          .add(value, 'days')
          .toDate();
      }

      const oldValue = existingFormData[name];
      const newValue =
        type === 'blur' && !value
          ? 0
          : type === 'focus' && value === '0'
          ? ''
          : value;

      if (!isService) {
        existingFormData[name] = newValue >= 0 ? newValue : 0;
      }

      const parseValue =
        type === 'blur' && !value
          ? 0
          : value && value >= 0
          ? parseFloat(value)
          : 0.0;

      let additionalCharges = 0;
      existingFormData.quotedServices.forEach(
        (quoteService: any, index: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug ? parseValue : parseFloat(service.value);
            existingFormData.quotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value > 0
                  ? parseFloat(service.value).toFixed(2)
                  : 0,
            };
          });
        }
      );
      existingFormData.nonQuotedServices.forEach(
        (nonQuoteService: any, index: number) => {
          Object.entries(nonQuoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug
                ? parseValue
                : service.value
                ? parseFloat(service.value)
                : 0;
            existingFormData.nonQuotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value !== null
                  ? service.value > 0
                    ? parseFloat(service.value).toFixed(2)
                    : 0
                  : 0,
            };
          });
        }
      );
      existingFormData.additionalCharges = additionalCharges.toFixed(2);

      const total: any =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges);

      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: any =
        existingFormData.margin >= 0 ? existingFormData.margin : 0;
      let marginUnit: any =
        existingFormData.marginUnit >= 0 ? existingFormData.marginUnit : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 && marginUnit > 0
            ? parseFloat(
                ((total / 100) * parseFloat(marginUnit.toString())).toFixed(2)
              )
            : 0;
      } else {
        marginUnit =
          total > 0 && margin > 0
            ? parseFloat(
                ((100 * parseFloat(margin.toString())) / total).toFixed(2)
              )
            : 0;
      }

      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'totalCharge') {
        if (type === 'blur') {
          existingFormData[name] = parseValue ? parseValue.toFixed(2) : 0;
        }
      }

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total ? total.toFixed(2) : parseValue.toFixed(2);
        }
        let newMargin = parseValue - total;

        // if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
        //     newMargin = ((parseValue - total) / total) * 100;
        // }

        existingFormData.margin =
          parseValue < total ? 0 : newMargin ? newMargin.toFixed(2) : newMargin;
      } else {
        if (parseFloat(margin) >= 0) {
          existingFormData.finalCharge = (
            parseFloat(total) + parseFloat(margin)
          ).toFixed(2);
        }
      }

      let newVal = value;

      if (
        value.endsWith('0') &&
        value != '0.' &&
        (name === 'margin' || name === 'marginUnit') &&
        oldValue == 0
      ) {
        newVal = value.slice(0, -1);
      }

      if (
        value.startsWith('0') &&
        value != '0.' &&
        (name === 'margin' || name === 'marginUnit') &&
        oldValue == 0
      ) {
        newVal = Number(value);
      }

      if (name === 'marginUnit') {
        existingFormData.marginUnit = parseFloat(newVal) >= 0 ? newVal : 0;
      } else if (name === 'margin') {
        existingFormData.margin = parseFloat(newVal) >= 0 ? newVal : 0;
      }

      setFormData((old: any) => ({ ...old, [id]: existingFormData }));
    };

  const handleDateChange = (id: number, name: string) => (date: any) => {
    const existingFormData: any = {
      ...formData[id],
    };
    const { value } = date;
    existingFormData[name] = value;
    const daysDiff = moment(moment(value)).diff(moment(), 'days') + 1;
    existingFormData.transitTime = daysDiff;
    setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  };

  const handleSubmit =
    (id: number, isPdf = false) =>
    () => {
      if (!isPdf) {
        setIsUpdateLoading(true);
      } else {
        setIsLoading(true);
      }

      if (mode === 'create') {
        createPDF(quoteID, {
          carrierPrices: formData,
          columns,
          currency,
          pdfLanguage,
          isPdf,
        })
          .then((response: any) => {
            handleClose();
            setIsRefresh(true);
            const { data } = response;
            setQuoteType(QuoteType.WITH_RATE);

            if (data) {
              const url =
                pdfLanguage === 'english'
                  ? `${data.url}${data.file}`
                  : `${data.frenchUrl}${data.frenchFile}`;
              setTimeout(() => {
                downloadPdf(url, `${data.name} - ${data.file}`);
              }, 2000);
            }
          })
          .finally(() => {
            setIsLoading(false);
            setIsUpdateLoading(false);
          })
          .catch(console.error);
      } else if (mode === 'update') {
        const data: any = {
          currency,
          columns,
          isPdf,
          margin: formData[id].margin,
          marginUnit: formData[id].marginUnit,
          marginType: formData[id].marginType,
          transitTime: formData[id].transitTime,
          projectedDeliveryDate: formData[id].projectedDeliveryDate,
          totalCharge: formData[id].totalCharge,
          services: [
            ...formData[id].quotedServices,
            ...formData[id].nonQuotedServices,
          ],
        };
        updatePDF(id, data)
          .then(() => {
            handleClose();
            setIsRefresh(true);
            WalToast.success('Price updated successfully');
            // if (response?.data) {
            //     const url = pdfLanguage === "English" ? `${response.data.url}${response.data.file}` : `${response.data.frenchUrl}${response.data.frenchFile}`;
            //     setTimeout(() => {
            //         downloadPdf(url, `${response.data.name} - ${response.data.file}`);
            //     }, 2000);
            // }
          })
          .finally(() => {
            setIsLoading(false);
            setIsUpdateLoading(false);
          })
          .catch(console.error);
      }
    };

  // const handleEditableFormData = (name: string) => () => {
  //     setEditableFormData((old) => ({ ...old, [name]: true }));
  // };

  // const saveEditableFormData = (name: string, id: number, status: boolean) => () => {
  //     if (status) {
  //         const existingFormData: any = {
  //             ...formData[id],
  //         };
  //         setResetFormData((old: any) => ({ ...old, [id]: existingFormData }));
  //     } else {
  //         const existingFormData: any = {
  //             ...formData[id],
  //             [name]: parseFloat(resetFormData[id][name]),
  //         };
  //         if (name === "totalCharge") {
  //             const total = parseFloat(existingFormData.totalCharge) + parseFloat(existingFormData.additionalCharges);
  //             const margin = existingFormData.marginType === MARGIN_TYPE.PERCENTAGE ? (total / 100) * parseFloat(existingFormData.margin) : parseFloat(existingFormData.margin);
  //             existingFormData.finalCharge = (total + margin).toFixed(2);
  //         }
  //         setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  //     }

  //     setEditableFormData((old) => ({ ...old, [name]: false }));
  // };

  // const handleDateChange = (id: number, name: string) => (date: any) => {
  //     const existingFormData: any = {
  //         ...formData[id],
  //     };
  //     existingFormData[name] = date;
  //     setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  // };

  // const handleMarginType = (id: number, name: string) => (event: any) => {
  //     var value = event.target?.dataset?.value;
  //     const existingFormData: any = {
  //         ...formData[id],
  //     };
  //     if (name === "marginType" && !event.target?.dataset?.value) {
  //         value = event.target?.parentElement?.dataset?.value;
  //     }

  //     existingFormData[name] = value;
  //     const total = parseFloat(existingFormData.totalCharge) + parseFloat(existingFormData.additionalCharges);
  //     const margin = existingFormData.marginType === MARGIN_TYPE.PERCENTAGE ? (total / 100) * parseFloat(existingFormData.marginUnit) : parseFloat(existingFormData.marginUnit);
  //     existingFormData.margin = margin;
  //     existingFormData.finalCharge = (total + margin).toFixed(2);
  //     setFormData((old: any) => ({ ...old, [id]: existingFormData }));
  // };

  const handleToggle = (event: any) => {
    const { name, checked } = event.target;
    setColumns((old) => ({ ...old, [name]: checked }));
  };

  const handleLanguageToggle = (id: number, event: any) => {
    const { name, checked } = event.target;

    if (checked) {
      setPdfLanguage(name);
      const existingFormData: any = {
        ...formData[id],
      };
      existingFormData.pdfLanguage = name;
      // setFormData((old: any) => ({ ...old, [id]: existingFormData }));
    }
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        if (action.informationModel) {
          setAction((old) => ({ ...old, closedQuote: true }));

          return;
        }

        if (action.closedQuote) {
          setIsLoading(true);
          setIsMainLoading(true);
          updateStatus(selectedCarrierRates[0].id)
            .then((response: any) => {
              if (response && response.data) {
                setRossRocketId(response.data.rossRocketId);
                setRossRocketPublicId(response.data.rossRocketPublicId);
                setAction((old) => ({ ...old, orderSuccessModel: true }));
                // window.open(ROSE_ROCKET.QUOTE_URL + response.data.rossRocketId);
              }
              // setIsRefresh(true);
              // handleClose();
            })
            .finally(() => {
              setIsMainLoading(false);
              setIsLoading(false);
            })
            .catch((error) => {
              WalToast.error(
                error?.response?.data?.message || 'Something went wrong'
              );
            });
        }

        if (action.orderSuccessModel) {
          setIsRefresh(true);
          // handleClose();
        }
      }
    },
    [action]
  );

  const drawer = useMemo(() => {
    let drawerObj = {
      title: '',
      button: '',
    };

    switch (mode) {
      case 'create':
        drawerObj.title = '';
        break;
      case 'update':
        drawerObj.title = '';
        break;
      default:
        drawerObj.title = '';
        break;
    }

    return drawerObj;
  }, [mode]);

  useEffect(() => {
    if (selectedCarrierRates.length > 0) {
      setTabArray(
        selectedCarrierRates.map((selectedCarrierRate: any) => ({
          value: selectedCarrierRate.id.toString(),
          name: selectedCarrierRate.name,
          image: selectedCarrierRate.image
            ? selectedCarrierRate.imageUrl + selectedCarrierRate.image
            : defaultImage,
        }))
      );
      setActiveFilter(selectedCarrierRates[0].id.toString());
    }
  }, [selectedCarrierRates]);

  return (
    <>
      {isMainLoading && (
        <div className="flex justify-center items-center flex-1 absolute top-0 left-0 right-0 bottom-0 z-50 bg-[#000]/60 ">
          <div className="relative">
            <div className="loader_spinner ease-linear rounded-full border-8 border-t-8 border-white h-64 w-64"></div>
            <h2 className="text-white text-lg absolute  top-[50%] bottom-0 left-[50%] -translate-x-[50%] -translate-y-[10%] w-11/12 text-center">
              Please wait...
            </h2>
          </div>
        </div>
      )}
      <Drawer
        isOpen={isOpen}
        handleClose={handleClose}
        title={drawer.title}
        classDrawer="max-w-11/2 sm:!w-[430px] !w-[316px] flex flex-col"
        drawerHeight="pb-0 flex-1 flex flex-col"
        headerClassName="!p-0 !border-0 !hidden"
        CloseIconClassName="hidden"
      >
        {Object.keys(selectedCarrierRates).length > 0 && isOpen && (
          <>
            <TabButton
              className="!border-0 !border-b-2 border-utilityGray200 !rounded-none flex gap-1 items-center  xl:!px-2 flex-none "
              tabArray={tabArray}
              parentClassName="rounded-none whitespace-nowrap overflow-x-auto w-full scrollbar-hide !shadow-none"
              isActive={activeFilter}
              activeClassName="!border-b-2 !border-primary text-primary700"
              tabParentClassName=""
              tabTextClass="max-w-[calc(240px_-_25px)] truncate"
              handleOnClick={(e: any) => {
                setActiveFilter(e.target.dataset.value);
              }}
            />

            {selectedCarrierRates.map((selectedCarrierRate: any) => (
              <div
                key={selectedCarrierRate?.name}
                className={`${
                  selectedCarrierRate.id == activeFilter
                    ? ' flex-1 flex flex-col'
                    : 'hidden'
                }`}
                data-test-id={selectedCarrierRate.id}
              >
                <div className="pt-4 flex-1">
                  <div className="pb-4 border-b border-utilityGray200">
                    <p className="text-textSecondary text-xs font-medium mb-1.5">
                      Transit time
                    </p>
                    <InputText
                      inputType={
                        editableFormData?.transitTime ? 'number' : 'text'
                      }
                      inputName="transitTime"
                      value={
                        editableFormData?.transitTime
                          ? formData[selectedCarrierRate.id]?.transitTime
                          : `Est. ${
                              formData[selectedCarrierRate.id]
                                ? formData[selectedCarrierRate.id]?.transitTime
                                : 0
                            } Business Days.`
                      }
                      disabled={!isEditable}
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      onBlur={() =>
                        setEditableFormData((old: any) => ({
                          ...old,
                          transitTime: false,
                        }))
                      }
                      onFocus={() =>
                        setEditableFormData((old: any) => ({
                          ...old,
                          transitTime: true,
                        }))
                      }
                      parentClassName="mb-2"
                    />
                    {isEditable ? (
                      <DateSelect
                        inputName="projectedDeliveryDate"
                        className={`form_control`}
                        label="Projected delivery"
                        labelClassName="block"
                        placeholder={DATE_FORMAT}
                        dateFormat="dd/MM/yyyy"
                        minDate={moment().toDate()}
                        selected={
                          formData[selectedCarrierRate.id] &&
                          formData[selectedCarrierRate.id].projectedDeliveryDate
                            ? moment(
                                formData[selectedCarrierRate.id]
                                  .projectedDeliveryDate
                              ).toDate()
                            : null
                        }
                        errorText={''}
                        onChangeFunc={handleDateChange(
                          selectedCarrierRate.id,
                          'projectedDeliveryDate'
                        )}
                        icon={<Calendar className="h-4 w-4 " />}
                        calendarIconClass="!text-primary"
                        parentClassName="fixed-width-datepicker right-side-datepicker"
                      />
                    ) : (
                      <>
                        <p className="text-textSecondary text-xs font-medium mb-1.5">
                          Projected delivery
                        </p>
                        <InputText
                          inputType={
                            editableFormData?.transitTime ? 'number' : 'text'
                          }
                          inputName="projectedDelivery"
                          disabled={true}
                          value={
                            formData[selectedCarrierRate.id] &&
                            formData[selectedCarrierRate.id]
                              .projectedDeliveryDate
                              ? moment(
                                  formData[selectedCarrierRate.id]
                                    .projectedDeliveryDate
                                ).format('DD MMMM, YYYY')
                              : '-'
                          }
                          parentClassName=""
                        />
                      </>
                    )}
                  </div>
                  {(formData[selectedCarrierRate.id]?.nonQuotedServices
                    ?.length > 0 ||
                    formData[selectedCarrierRate.id]?.quotedServices?.length >
                      0) && (
                    <div className="py-4 border-b border-utilityGray200">
                      <h6 className="text-utilityBlack text-sm font-semibold mb-3">
                        Additional Services
                      </h6>
                      {formData[selectedCarrierRate.id] &&
                        formData[selectedCarrierRate.id].nonQuotedServices &&
                        formData[selectedCarrierRate.id].nonQuotedServices
                          .length > 0 &&
                        formData[selectedCarrierRate.id].nonQuotedServices.map(
                          (nonQuoteService: any) =>
                            Object.entries(nonQuoteService).map(
                              ([slug, service]: any) => (
                                <>
                                  <label className="form_label flex gap-1 [&:not(:nth-child(2))]:mt-3">
                                    {service.name}
                                  </label>
                                  <TooltipCmp
                                    message={
                                      !service.value ||
                                      service.value === 0 ||
                                      service.value === '0'
                                        ? `Carrier did not provide a rate for ${service.name}, for this additional service. Please validate feasibility and custom pricing.`
                                        : null
                                    }
                                  >
                                    <InputText
                                      inputName={slug}
                                      className="pl-8"
                                      parentClassName="[&:not(:last-child)]:mb-4"
                                      value={service.value}
                                      isError={
                                        !service.value ||
                                        service.value === 0 ||
                                        service.value === '0'
                                      }
                                      icon={
                                        <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-4 w-4" />
                                      }
                                      onChangeFunc={handleInputChange(
                                        selectedCarrierRate.id,
                                        true
                                      )}
                                      onBlur={handleInputChange(
                                        selectedCarrierRate.id,
                                        true
                                      )}
                                      onFocus={handleInputChange(
                                        selectedCarrierRate.id,
                                        true
                                      )}
                                    />
                                  </TooltipCmp>
                                </>
                              )
                            )
                        )}
                      {formData[selectedCarrierRate.id] &&
                        formData[selectedCarrierRate.id].quotedServices.length >
                          0 &&
                        formData[selectedCarrierRate.id].quotedServices.map(
                          (quotedService: any) =>
                            Object.entries(quotedService).map(
                              ([slug, service]: any) => (
                                <>
                                  <label className="form_label flex items-center gap-1 [&:not(:nth-child(2))]:mt-3">
                                    {service.name}
                                    <TooltipCmp message="Additional Service Pricing Returned from API.">
                                      <InfoCircle className="text-primary w-3.5 h-3.5" />
                                    </TooltipCmp>
                                  </label>
                                  <InputText
                                    inputName={slug}
                                    className="pl-8"
                                    parentClassName="[&:not(:last-child)]:mb-4"
                                    value={service.value}
                                    onChangeFunc={handleInputChange(
                                      selectedCarrierRate.id,
                                      true
                                    )}
                                    onBlur={handleInputChange(
                                      selectedCarrierRate.id,
                                      true
                                    )}
                                    onFocus={handleInputChange(
                                      selectedCarrierRate.id,
                                      true
                                    )}
                                    disabled
                                    icon={
                                      <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-4 w-4" />
                                    }
                                  />
                                </>
                              )
                            )
                        )}
                    </div>
                  )}
                  <div className="py-4 border-b border-utilityGray200">
                    <h6 className="text-utilityBlack text-sm font-semibold mb-3">
                      Total Cost
                    </h6>
                    <p className="text-textSecondary text-xs font-medium mb-1.5">
                      Base Quote
                    </p>
                    <InputText
                      inputType="text"
                      inputName="totalCharge"
                      className="pl-8"
                      value={
                        formData[selectedCarrierRate.id]
                          ? formData[selectedCarrierRate.id].totalCharge
                          : 0
                      }
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      onBlur={handleInputChange(selectedCarrierRate.id)}
                      icon={
                        <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                          <CurrencyDollar className="w-4 h-4" />
                        </div>
                      }
                      parentClassName="mb-2"
                    />
                    <p className="text-textSecondary text-xs font-medium mb-1.5">
                      Additional Services
                    </p>
                    <InputText
                      inputType="text"
                      inputName="additionalServices"
                      className="pl-8"
                      disabled={true}
                      value={
                        formData[selectedCarrierRate.id]
                          ? formData[selectedCarrierRate.id]
                              .additionalCharges || '0.00'
                          : '0.00'
                      }
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      icon={
                        <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                          <CurrencyDollar className="w-4 h-4" />
                        </div>
                      }
                      onBlur={handleInputChange(selectedCarrierRate.id)}
                    />
                  </div>
                  <div className="py-4 border-b border-utilityGray200">
                    <h6 className="text-utilityBlack text-sm font-semibold mb-3">
                      Total Quote
                    </h6>
                    <div className="flex sm:flex-row flex-col -m-1 mb-2">
                      <InputText
                        inputType="text"
                        inputName="margin"
                        value={formData[selectedCarrierRate.id]?.margin ?? 0.0}
                        onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                        onBlur={handleInputChange(selectedCarrierRate.id)}
                        onFocus={() => {
                          if (formData[selectedCarrierRate.id].margin === 0) {
                            setFormData((old: any) => ({
                              ...old,
                              [selectedCarrierRate.id]: {
                                ...formData[selectedCarrierRate.id],
                                margin: '',
                              },
                            }));
                          }
                        }}
                        className="pl-[40px]"
                        placeholder="0"
                        label="Margin"
                        labelClassName="block"
                        icon={
                          <div className="border-r border-gray100 bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                            <CurrencyDollar className="w-4 h-4" />
                          </div>
                        }
                        parentClassName="sm:w-1/2 p-1"
                      />
                      <InputText
                        inputType="text"
                        inputName="marginUnit"
                        value={
                          formData[selectedCarrierRate.id]?.marginUnit ?? 0
                        }
                        onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                        onBlur={handleInputChange(selectedCarrierRate.id)}
                        className="pl-[40px]"
                        onFocus={() => {
                          if (
                            formData[selectedCarrierRate.id].marginUnit === 0
                          ) {
                            setFormData((old: any) => ({
                              ...old,
                              [selectedCarrierRate.id]: {
                                ...formData[selectedCarrierRate.id],
                                marginUnit: '',
                              },
                            }));
                          }
                        }}
                        label="&nbsp;"
                        placeholder="0"
                        labelClassName="sm:block hidden"
                        icon={
                          <div className="border-r border-gray100 bg-utilityGray100 absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                            <Percent02 className="w-4 h-4" />
                          </div>
                        }
                        parentClassName="sm:w-1/2 p-1"
                      />
                    </div>
                    <InputText
                      inputType="text"
                      inputName="finalCharge"
                      className="pl-8"
                      parentClassName="flex-1"
                      value={
                        formData[selectedCarrierRate.id]
                          ? formData[selectedCarrierRate.id].finalCharge
                          : 0
                      }
                      label="Total Price"
                      labelClassName="block mb-1.5"
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      onBlur={handleInputChange(selectedCarrierRate.id)}
                      icon={
                        <div className="absolute h-[calc(100%_-_2px)] w-[36px] top-[1px] left-[1px] rounded-l-lg flex items-center justify-center">
                          <CurrencyDollar className="w-4 h-4" />
                        </div>
                      }
                    />
                  </div>
                  {mode !== 'update' ? (
                    <>
                      <div className="py-4">
                        <div className="flex flex-col gap-2">
                          <p className="text-utilityBlack text-sm font-semibold">
                            Display Settings
                          </p>
                          <div className="flex">
                            <Toggle
                              id=""
                              name="isDisplayCarrier"
                              labelClassName="mr-2"
                              isChecked={columns.isDisplayCarrier}
                              onChange={handleToggle}
                            />
                            <span className="text-xs fw-medium text-textSecondary">
                              Display Carrier Names
                            </span>
                          </div>
                          <div className="flex">
                            <Toggle
                              id=""
                              name="isDisplayTransit"
                              labelClassName="mr-2"
                              isChecked={columns.isDisplayTransit}
                              onChange={handleToggle}
                            />
                            <span className="text-xs fw-medium text-textSecondary">
                              Display Estimated Delivery and Transit Time
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="py-4">
                        <div className="flex flex-col gap-2">
                          <p className="text-utilityBlack text-sm font-semibold">
                            Select Language
                          </p>
                          <div className="flex mt-2">
                            <Toggle
                              id=""
                              name="english"
                              labelClassName="mr-2"
                              isChecked={pdfLanguage === 'english'}
                              onChange={(e: any) =>
                                handleLanguageToggle(selectedCarrierRate.id, e)
                              }
                            />
                            <span className="text-xs fw-medium text-textSecondary">
                              English
                            </span>
                          </div>
                          <div className="flex">
                            <Toggle
                              id=""
                              name="french"
                              labelClassName="mr-2"
                              isChecked={pdfLanguage === 'french'}
                              onChange={(e: any) =>
                                handleLanguageToggle(selectedCarrierRate.id, e)
                              }
                            />
                            <span className="text-xs fw-medium text-textSecondary">
                              French
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex sm:flex-row sticky bottom-0 p-5 border-t border-borderPrimary bg-white -mx-5">
                  {/* <ButtonCmp
                    type="submit"
                    className="btn_secondary_black sm:w-[calc(50%_-_6px)] w-full sm:mb-0 mb-3"
                    loading={isUpdateLoading}
                    disabled={isLoading || isUpdateLoading}
                    onClick={handleSubmit(selectedCarrierRate.id, false)}
                  >
                    Create Quote
                  </ButtonCmp> */}

                  <ButtonCmp
                    type="submit"
                    className="btn_primary w-full"
                    loading={isLoading}
                    disabled={!isValid || isLoading || isUpdateLoading}
                    onClick={handleSubmit(selectedCarrierRate.id, true)}
                  >
                    {mode === 'update'
                      ? 'Modify Quote'
                      : 'Create Customer Invoice'}
                  </ButtonCmp>
                </div>
              </div>
            ))}
          </>
        )}
      </Drawer>
      {action.informationModel && (
        <CommonModal
          handleClose={handleModalClose(false)}
          title="Create BOL Instruction"
          size={'max-w-6xl'}
        >
          <div className="p-6 pt-0 ">
            {' '}
            <div className="table w-full">
              <p className="font-semibold leading-relaxed text-black">
                Dimensions & services
              </p>
              <table className="mt-3 w-full">
                <thead>
                  {dimensions.map((dimension: any, index: any) => (
                    <tr key={`dimension_${index}`}>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Handling unit
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        № of handling units
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        {' '}
                        Weight per unit (
                        {dimension.weightMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Length ({dimension.lengthMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Width ({dimension.lengthMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        Height ({dimension.lengthMeasurement?.toLowerCase()})
                      </th>
                      <th className="p-3 bg-inputBg text-sm text-grayText">
                        {' '}
                        Total weight (
                        {dimension.weightMeasurement?.toLowerCase()})
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {dimensions.map((dimension: any, index: any) => (
                    <tr
                      key={`dimension_${index}`}
                      className="text-center border-b border-gray-100"
                    >
                      <td className="p-3 text-black text-base">
                        {dimension.handlingUnitName}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.handlingUnitNo}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.weight}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.itemLength}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.width}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.height}
                      </td>

                      <td className="p-3 text-black text-base">
                        {dimension.totalWeight}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {services.length > 0 && (
                <div className="border-none shadow-nonepx-5 py-3 mt-6 rounded-lg">
                  <ul className="mb-2 text-gray-500 dark:text-gray-400 flex flex-wrap">
                    {services.map((service: any, index: any) => (
                      <li
                        key={`service_${index}`}
                        className="w-1/6 capitalize text-black flex items-center text-base mt-2"
                      >
                        <CgCheck size={24} className="text-primary mr-1" />{' '}
                        {service.serviceName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="mt-4 ">
              <p className="font-semibold leading-relaxed text-black mb-4">
                Instruction
              </p>
              <ul className="leading-8">
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` You will be redirected to the carrier portal.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Log in to the carrier portal using your credentials.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Locate your quote by entering the quote ID.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Click on the "Create BOL" button within the carrier portal.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Once you have completed the Bill of Lading (BOL) process, return to our portal.`}
                </li>
                <li className="flex items-center">
                  <BsFillCheckCircleFill className="text-primary text-base mr-3" />
                  {` Accept that you have created the quote and finalize the process on our portal.`}
                </li>
              </ul>
            </div>
            <div className="flex flex-wrap -mx-3 mt-4">
              <div className="w-1/2 px-3">
                <button
                  className="btn_primary w-full"
                  onClick={handleModalClose(true)}
                >
                  {'Proceed with Order'}
                </button>
              </div>
              <div className="w-1/2 px-3">
                <button
                  className="btn_outline_primary w-full"
                  onClick={handleModalClose(false)}
                >
                  {'Close'}
                </button>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
      {action.closedQuote && (
        <ConfirmModal
          title="Close Active Quote"
          description={`Creating a BOL with ${selectedCarrierRates[0].name} will close this active quote and set its status as won, you wont be able to reopen it. Are you sure you want to proceed?`}
          button1="Yes, I am sure"
          button2="No, not now"
          handleClose={handleModalClose}
        />
      )}
      {action.orderSuccessModel && (
        <CommonModal
          handleClose={handleModalClose(false)}
          title="Order Booked"
          size={'max-w-md'}
        >
          <div className="p-6 pt-0 ">
            <div className="text-center">
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
              <p className="text-xl font-semibold leading-relaxed text-black mt-4">
                <Link
                  to={ROSE_ROCKET.QUOTE_URL + rossRocketId}
                  target="_blank"
                  className="underline text-primary"
                >
                  {rossRocketPublicId}
                </Link>
              </p>
            </div>
            <div className="flex flex-wrap -mx-3 mt-4">
              <div className="w-full px-3">
                <button
                  className="btn_outline_primary w-full"
                  onClick={handleModalClose(true)}
                >
                  {'Close'}
                </button>
              </div>
            </div>
          </div>
        </CommonModal>
      )}
    </>
  );
};

export default memo(Pricing);
