import {
  CheckSquareBroken,
  InfoCircle,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';

import DeclineModal from './DeclineModal';
import NextTasksModal from './NextStepsModal';

// import Streaks from '../../assets/img/streaks.svg';

const CreditIncreaseRow = () => {
  const [isShowDeclineModal, setIsShowDeclineModal] = useState(false);
  const [isShowNextStepsModal, setIsShowNextStepsModal] = useState(false);

  return (
    <>
      <td className="px-5 py-4 w-64 min-w-64 max-w-64">
        <div className="flex items-center gap-3">
          {/* <img
            className="w-8 h-8  rounded-full text-white flex items-center justify-center uppercase border-[0.75px] border-black/[0.08] flex-none"
            src={}
            alt=""
            title=""
          /> */}
          <div className="w-8 h-8 rounded-full bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
            WA
          </div>
          <div className="max-w-44  flex flex-col">
            <TooltipCmp
              message={'Olivia Rhye'}
              childrenClassName="text-grayLight900 font-medium "
            >
              <p className="truncate">Olivia Rhye</p>
            </TooltipCmp>
            <TooltipCmp message={'#WAL-DMIF-1'}>#WAL-DMIF-1</TooltipCmp>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 w-80 min-w-80 max-w-80">
        <div className="flex flex-wrap gap-1">
          <BadgeCmp style="modern" type="success">
            Approved
          </BadgeCmp>
          <BadgeCmp style="modern" type="info">
            In-review
          </BadgeCmp>
          <BadgeCmp style="modern" type="red">
            Denied
          </BadgeCmp>
        </div>
      </td>
      <td className="px-5 py-4">
        <span>$20,000</span>
      </td>
      <td className="px-5 py-4">
        <span>$5,000</span>
      </td>
      <td className="px-5 py-4">
        <span>$25,000</span>
      </td>
      <td className="px-5 py-4">
        <div className="justify-end items-center gap-2 flex ">
          <TooltipCmp message={'Approve'}>
            <CheckSquareBroken className="w-3.5 h-3.5 text-fgSuccessPrimary" />
          </TooltipCmp>
          <TooltipCmp message={'Decline'}>
            <XClose
              className="w-4 h-4 text-fgErrorPrimary"
              onClick={() => setIsShowDeclineModal(true)}
            />
          </TooltipCmp>

          <InfoCircle
            className="w-4 h-4 text-primary"
            onClick={() => setIsShowNextStepsModal(true)}
          />
        </div>
      </td>
      {isShowDeclineModal && (
        <DeclineModal handleClose={() => setIsShowDeclineModal(false)} />
      )}
      {isShowNextStepsModal && (
        <NextTasksModal handleClose={() => setIsShowNextStepsModal(false)} />
      )}
    </>
  );
};
export default CreditIncreaseRow;
