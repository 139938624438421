import React from 'react';
import {
  capitalizeFirstLetterAndDash,
  getDateWithSuffixFormat,
  getFormattedDate,
} from 'src/utils/CommonFunctions';

const CommissionReportRow = ({ data }: any) => {
  console.log('commission report row data');

  return (
    <>
      {/* Different padding on 1st <td> to adjust table row height  */}
      <td className="px-5 py-[11px] ">
        <h6 className=" font-semibold rounded p-1.5 border border-utilityGray200 w-fit text-cyan text-center min-w-[42px]">
          <p className="">
            {data?.month.length > 8
              ? capitalizeFirstLetterAndDash(
                  data?.month.slice(0, 4).toLowerCase()
                )
              : capitalizeFirstLetterAndDash(
                  data?.month.slice(0, 3).toLowerCase()
                )}
          </p>
          <p className="font-normal text-[10px] leading-3">{data?.year}</p>
        </h6>
      </td>

      <td className="px-5 py-4 ">
        <span
          dangerouslySetInnerHTML={{
            __html: data.periodStartDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.periodStartDate,
                    `MMM Do - YYYY`,
                    true,
                    false
                  )
                )
              : '-',
          }}
        ></span>
      </td>
      <td className="px-5 py-4">
        <span
          dangerouslySetInnerHTML={{
            __html: data.periodEndDate
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.periodEndDate,
                    `MMM Do - YYYY`,
                    true,
                    false
                  )
                )
              : '-',
          }}
        ></span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.commissionedUser}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>${data.totalMargin}</span>
      </td>
      <td className="px-5 py-4">
        <span>${data.avgMargin}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.loadsDelivered}</span>
      </td>
      <td className="px-5 py-4 ">
        <span>{data.marginPercentage}%</span>
      </td>
    </>
  );
};

export default CommissionReportRow;
