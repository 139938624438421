import {
  DotsVertical,
  Edit05,
  Lock02,
  Trash01,
  User01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import { USER_ROLE } from 'src/constants/common';

import {
  getShortName,
  onError,
  useRolePermission,
} from '../../../utils/CommonFunctions';

interface IProps {
  memberRecord: any;
  handleActionType: (
    action: any,
    mode?: any,
    teamManager?: any,
    team?: any
  ) => () => void;
}

const TeamMemberTableRow = ({ memberRecord, handleActionType }: IProps) => {
  const { hasPermissionV2 } = useRolePermission();

  const tooltipItems = (
    <div className="menu bg-base-100 rounded-box w-36">
      <div
        onClick={handleActionType(
          'team_management',
          'view_profile',
          null,
          memberRecord
        )}
        className="py-2"
      >
        <div className="flex items-center">
          <User01 className="w-4 h-4 relative mr-3" /> View profile
        </div>
      </div>
      {hasPermissionV2('team_member_delete') && (
        <div
          onClick={handleActionType(
            'team_management',
            'user-remove-from-team',
            null,
            memberRecord
          )}
          className="py-2"
        >
          <div className="flex items-center">
            <Trash01 className="w-4 h-4 relative mr-3" />
            Remove
          </div>
        </div>
      )}
      {hasPermissionV2('team_member_update') && (
        <div
          onClick={handleActionType(
            'team_management',
            'user-update',
            null,
            memberRecord
          )}
          className="py-2"
        >
          <div className="flex items-center">
            <Edit05 className="w-4 h-4 relative mr-3" /> Edit
          </div>
        </div>
      )}
      {hasPermissionV2('team_member_update') && (
        <div
          onClick={handleActionType(
            'team_management',
            'access-control',
            null,
            memberRecord
          )}
          className="py-2"
        >
          <div className="flex items-center">
            <Lock02 className="w-4 h-4 relative mr-3" />
            Permissions
          </div>
        </div>
      )}
    </div>
  );

  const getUserAccountTypeColor = (accountType: string) => {
    if (accountType === USER_ROLE.ADMIN) {
      return 'success';
    } else if (accountType === USER_ROLE.MANAGER) {
      return 'primary';
    } else if (accountType === USER_ROLE.SALES) {
      return 'info';
    } else if (accountType === USER_ROLE.OPERATIONS) {
      return 'lightblue';
    } else if (accountType === USER_ROLE.CLAIMS) {
      return 'error';
    } else if (accountType === USER_ROLE.FINANCE) {
      return 'warning';
    } else if (accountType === USER_ROLE.USER) {
      return 'info';
    } else {
      return 'error';
    }
  };

  const roles =
    typeof memberRecord.roles === 'string'
      ? JSON.parse(memberRecord.roles)
      : memberRecord.roles;

  return (
    <>
      {/* <li className="p-3  xlm:w-1/4 xls:w-1/3 mdm:w-1/2 md:w-full sm:w-1/2 w-full  [&>*]:select-none ">
        <div className="shadow-md border border-utilityGray200 rounded-lg h-full flex flex-col">
          <div className="px-5 py-6 flex border-b border-utilityGray200 flex-1">
            {memberRecord.imageUrl ? (
              <img
                className="md:w-[50px] w-10 md:h-[50px] h-10 border border-utilityGray200 rounded-lg shadow-sm object-cover"
                src={memberRecord.imageUrl + memberRecord.image}
                alt=""
                title=""
                onError={onError}
              />
            ) : (
              <label className="md:w-[50px] w-10 md:h-[50px] h-10 border border-utilityGray200 rounded-lg shadow-sm bg-primary text-white text-sm font-semibold flex items-center justify-center uppercase">
                <div className="">
                  {getShortName(
                    `${memberRecord.firstName} ${memberRecord.lastName}`
                  )}
                </div>
              </label>
            )}
            <div className="ml-3 mr-2 flex-1 py-0.5">
              <h5 className="md:text-base text-sm font-semibold">{`${memberRecord.firstName} ${memberRecord.lastName}`}</h5>

              <p className="text-grayLight600 md:text-sm text-xs font-normal capitalize">
                {memberRecord.jobRole}
              </p>
            </div>

            <div className="dropdown dropdown-bottom dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="p-0 text-gray400 rounded-md transition-all border-none bg-transparent !outline-0"
              >
                <ActionTooltip
                  message={tooltipItems}
                  isOpenChildTopPosOnTop={true}
                  type="light"
                >
                  <DotsVertical className="cursor-pointer w-5 h-5" />
                </ActionTooltip>
              </div>
            </div>
          </div>
          <div className="px-5 py-4 flex gap-4 justify-between items-center">
            {memberRecord?.roleInTeam === 'Manager' && (
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName=" flex-shrink"
              >
                Manager
              </BadgeCmp>
            )}
            {memberRecord?.roleInTeam === 'User' && (
              <BadgeCmp
                style="modern"
                type="primary"
                mainClassName=" flex-shrink"
              >
                User
              </BadgeCmp>
            )}

            <p className="text-xs font-normal text-textSecondary">
              {memberRecord?.lastLoginAt && (
                <>
                  Last Active:{' '}
                  <span className="font-semibold">{`${moment(
                    memberRecord.lastLoginAt
                  ).format('MMM DD, YYYY')}`}</span>
                </>
              )}
            </p>
          </div>
        </div>
      </li> */}

      <tr className="text-grayLight600 font-normal  [&:not(:last-child)]:border-b border-utilityGray200">
        <td className="px-5 py-4 w-80 min-w-80 max-w-80 text-xs">
          <div className="flex items-center">
            {memberRecord.imageUrl ? (
              <img
                className="w-8 h-8 rounded-full border border-black/[0.08]"
                src={memberRecord.imageUrl + memberRecord.image}
                alt=""
                title=""
                onError={onError}
              />
            ) : (
              <label className="w-8 h-8 relative bg-primary text-white text-sm rounded-full font-semibold border border-black/[0.08] flex items-center justify-center uppercase">
                <div className="">
                  {getShortName(
                    `${memberRecord.firstName} ${memberRecord.lastName}`
                  )}
                </div>
              </label>
            )}

            <div className="ml-3  ">
              <div className="text-grayLight900 font-medium leading-tight truncate">{`${memberRecord.firstName} ${memberRecord.lastName}`}</div>
              <div className="text-grayLight600 font-normal leading-tight truncate">
                {memberRecord.email}
              </div>
            </div>
          </div>
        </td>
        <td className="px-5 py-4 text-xs">{memberRecord.jobRole}</td>
        <td className="px-5 py-4 text-xs">
          {' '}
          <div className="flex">
            {roles && roles.length > 0 ? (
              <>
                {roles?.map((role: any, index: number) => (
                  <BadgeCmp
                    style="modern"
                    type={getUserAccountTypeColor(role.name)}
                    key={index}
                    mainClassName="me-2"
                  >
                    {role ? role.displayName : '-'}
                  </BadgeCmp>
                ))}
              </>
            ) : memberRecord.role && memberRecord?.role?.displayName ? (
              <BadgeCmp
                style="modern"
                type={getUserAccountTypeColor(memberRecord?.role?.name)}
                mainClassName=" flex-shrink"
              >
                {memberRecord.role ? memberRecord.role.displayName : '-'}
              </BadgeCmp>
            ) : (
              '-'
            )}
            {/* {roles && roles.length > 2 ? (
              <div className="rounded-full bg-utilityGray100 ">
                <p className="text-grayLight600 text-xs font-semibold w-6 h-5 flex items-center justify-center flex-none">
                  +{roles.length - 2}
                </p>
              </div>
            ) : (
              false
            )} */}
          </div>
        </td>
        <td className="px-5 py-4 text-xs">
          {memberRecord.lastLoginAt
            ? `${moment(memberRecord.lastLoginAt).format('MMM DD, YYYY')}`
            : '-'}
        </td>
        <td className="px-5 py-4 text-xs relative">
          <div className="dropdown dropdown-bottom dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="p-0 text-grayText rounded-md transition-all ms-3 border-none bg-transparent !outline-0"
            >
              <ActionTooltip
                message={tooltipItems}
                isOpenChildTopPosOnTop={true}
                type="light"
              >
                <DotsVertical className="cursor-pointer w-5 h-5" />
              </ActionTooltip>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default TeamMemberTableRow;
