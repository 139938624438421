import {
  Check,
  ClockFastForward,
  Edit01,
  Lock02,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext } from 'react';
import { DATE_FORMAT, USER_ROLE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';

import BadgeCmp from '../../../../components/BadgeCmp';
import TooltipCmp from '../../../../components/TooltipCmp';
import {
  getShortName,
  onError,
  useRolePermission,
} from '../../../../utils/CommonFunctions';

interface IProps {
  data: any;
  keyValue: string;
  handleActionType: (action: any, mode?: any, team?: any) => () => void;
}

const MemberRow = ({ data, handleActionType }: IProps) => {
  const { currentUser } = useContext(AuthContext);
  const { hasPermissionV2 } = useRolePermission();

  const getUserAccountTypeColor = (accountType: string) => {
    if (accountType === USER_ROLE.ADMIN) {
      return 'primary';
    } else if (accountType === USER_ROLE.MANAGER) {
      return 'success';
    } else if (accountType === USER_ROLE.SALES) {
      return 'pink';
    } else if (accountType === USER_ROLE.OPERATIONS) {
      return 'lightblue';
    } else if (accountType === USER_ROLE.CLAIMS) {
      return 'error';
    } else if (accountType === USER_ROLE.FINANCE) {
      return 'warning';
    }
  };

  const roles =
    typeof data?.roles === 'string' ? JSON.parse(data?.roles) : data?.roles;

  return (
    <>
      {/* <tr
      key={keyValue}
      className="text-grayLight600 border-b border-utilityGray200  font-normal text-xs"
    > */}
      <td
        className=" px-5 py-4 w-80 min-w-80 max-w-80"
        onClick={handleActionType('team_management', 'view_profile', data)}
      >
        <div className="flex items-center">
          {data.image ? (
            <img
              className="w-8 h-8 rounded-full flex-none"
              src={data.imageUrl + data.image}
              alt={data.firstName}
              title={data.firstName}
              onError={onError}
            />
          ) : (
            <div className="w-8 h-8 bg-primary rounded-full text-white flex items-center justify-center uppercase flex-none">
              {getShortName(`${data.firstName} ${data.lastName}`)}
            </div>
          )}
          <div className="ml-3 max-w-40">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">{`${data.firstName} ${data.lastName}`}</h6>
            <p className="text-grayLight600 font-normal leading-tight truncate">{`${data.email}`}</p>
          </div>
        </div>
      </td>

      <td className="px-5 py-4 w-56 max-w-56 min-w-56 ">
        <span>
          {data.lastLoginAt
            ? moment(data.lastLoginAt).format(DATE_FORMAT)
            : '-'}
        </span>
      </td>
      <td className="px-5 py-4 w-56 max-w-56 min-w-56 ">
        <div className="flex">
          {data.isVerified && data.userId ? (
            <BadgeCmp style="pill" type="success">
              <Check className="text-green500 mr-1 h-3 w-3" /> Active
            </BadgeCmp>
          ) : (
            <BadgeCmp style="pill" type="warning">
              <ClockFastForward className="mr-1 h-3 w-3 text-lg text-warning500" />{' '}
              Pending
            </BadgeCmp>
          )}
        </div>
      </td>
      <td className="px-5 py-4 w-[26rem] max-w-[26rem] min-w-[26rem]">
        <div className="flex">
          {roles && roles.length > 0 ? (
            roles?.map((role: any, index: number) => (
              <BadgeCmp
                style="modern"
                type={getUserAccountTypeColor(role.roleName)}
                key={index}
                mainClassName="me-2"
              >
                {role ? role.displayName : '-'}
              </BadgeCmp>
            ))
          ) : data?.role ? (
            <BadgeCmp
              style="modern"
              type={getUserAccountTypeColor(data.role.roleName)}
            >
              {data.role ? data.role.displayName : '-'}
            </BadgeCmp>
          ) : (
            '-'
          )}
          {/* {roles && roles.length > 2 ? (
            <div className="rounded-full bg-utilityGray100 ">
              <p className="text-grayLight600 text-xs font-semibold w-6 h-5 flex items-center justify-center flex-none">
                +{roles.length - 2}
              </p>
            </div>
          ) : (
            false
          )} */}
        </div>
      </td>
      <td className="px-5 py-4 ">
        <div className="items-center flex gap-4 justify-end">
          {hasPermissionV2('team_member_update') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Change Permission'}>
                <Lock02
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('team', 'access-control', data)}
                />
              </TooltipCmp>
            )}
          {hasPermissionV2('team_member_delete') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Delete Member'}>
                <Trash01
                  className="w-4 h-4 relative cursor-pointer"
                  onClick={handleActionType('team', 'user-delete', data)}
                />
              </TooltipCmp>
            )}
          {hasPermissionV2('team_member_update') &&
            data?.id !== currentUser?.id && (
              <TooltipCmp message={'Edit Member'}>
                <Edit01
                  className="w-4 h-4 relative cursor-pointer "
                  onClick={handleActionType('team', 'user-update', data)}
                />
              </TooltipCmp>
            )}
        </div>
      </td>
    </>
  );
};

export default MemberRow;
