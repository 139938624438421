import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import InputText from 'src/components/InputText/InputText';
import TableCmp from 'src/components/TableCmp';
import { getSalesHistoryList } from 'src/services/SalesHistoryService';

import BadgeCmp from '../../../components/BadgeCmp';
import CustomPagination from '../../../components/CustomPagination';
import NotFoundUI from '../../../components/NotFoundUI';
import PageSectionLayout from '../../../components/PageSectionLayout';
import Header from '../../../components/PageSectionLayout/Header/Header';

import FileListRow from './FileListRow';
import FileLoadingRow from './FileLoadingRow';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'startDate',
  page: 1,
  limit: 10,
};

const SalesHistory = () => {
  const [params, setParams] = useState(initParams);
  const [isBackupFileListLoading, setIsBackupFileListLoading] = useState(true);
  const [uploadMasterDataList, setUploadMasterDataList] = useState<any>([]);
  const [uploadMasterDataTotal, setUploadMasterDataTotal] = useState(0);
  const [search, setSearch] = useState<string>('');

  const headCells = useMemo(
    () => [
      {
        id: 'fileName',
        name: 'Backup File Name',
        sortable: true,
      },
      {
        id: 'createdAt',
        name: 'Backup Created Date',
        sortable: true,
      },
      {
        id: 'startDate',
        name: 'Backup From Date',
        sortable: true,
      },
      {
        id: 'endDate',
        name: 'Backup End Date',
        sortable: true,
      },
      {
        id: 'monthlyProjectedMargin',
        name: 'Monthly Projected Margin',
        sortable: true,
      },
      {
        id: 'projectedMarginVariance',
        name: 'Projected Margin Variance',
        sortable: true,
      },
      {
        id: 'dailyBookedMargin',
        name: 'Daily Booked Margin',
        sortable: true,
        rowClassName: 'w-[200px] min-w-[200px] max-w-[200px]',
      },
      {
        id: 'dailyDeliveredMargin',
        name: 'Daily Delivered Margin',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
        sortable: false,
      },
    ],
    []
  );

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  useEffect(() => {
    const backupFileApiController = new AbortController();
    const backupFileApiSignal = backupFileApiController.signal;

    setUploadMasterDataList([]);
    setIsBackupFileListLoading(true);

    getSalesHistoryList({ ...params }, backupFileApiSignal)
      .then((response: any) => {
        setUploadMasterDataList(response.data);
        setUploadMasterDataTotal(response.total);
        setIsBackupFileListLoading(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsBackupFileListLoading(false);
        console.error(e?.response?.message);
      });

    return () => {
      backupFileApiController.abort();
    };
  }, [params]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  return (
    <PageSectionLayout
      header={
        <Header
          title="Sales Backup"
          desc="Sales dashboard backup file containing daily orders list."
          mainContainerClassName="!flex-nowrap mdm:!flex-row !flex-col mdm:!items-center !items-start"
          rightClassName="mdm:min-w-fit min-w-full"
        />
      }
    >
      <div className="min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className="table-title-wrap">
              <h5 className="table-title">Backup</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${
                  isBackupFileListLoading ? 'custom-loading' : ''
                }`}
              >
                {uploadMasterDataTotal} files
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              View the historical uploaded data over time.
            </p>
          </div>
        </div>

        <div className="table-bottom-header">
          <div className="table-header-bottom-left flex-1">
            <InputText
              inputName="searchAdvance"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={search}
              inputType="text"
              isClearable={true}
              parentClassName="table-searchInput sm:min-w-[auto] min-w-full"
              onChangeFunc={(e: any) => {
                setSearch(e?.target?.value);
                searchDebounce(e?.target?.value);
              }}
            />
          </div>
        </div>

        <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 ">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
            <TableCmp
              headCells={headCells}
              tableDataArr={uploadMasterDataList}
              TableLoaderRowCmp={FileLoadingRow}
              TableRowCmp={FileListRow}
              params={params}
              setParams={setParams}
              isTableDataLoading={isBackupFileListLoading}
              numberOfSkeletonRows={10}
            />
          </div>

          {!(uploadMasterDataList.length > 0) && !isBackupFileListLoading && (
            <NotFoundUI
              title="No backup data found"
              desc="No sales orders backup file found. Please try again later."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          )}
        </div>

        {!!uploadMasterDataList.length && (
          <div className="w-full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={params.limit}
              totalRecords={uploadMasterDataTotal}
              currentPage={params.page}
              handlePagination={handlePagination}
            />
          </div>
        )}
      </div>
    </PageSectionLayout>
  );
};

export default SalesHistory;
