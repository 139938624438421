import { Plus, XClose } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import TabButton from 'src/components/TabButton';
import {
  getInternalNotes,
  createInternalNotes,
  updateLastNoteRead,
} from 'src/services/ClaimAndDisputeService';

import InternalNoteCard from './InternalNoteCard';
import InternalNotesSkeletonLoader from './InternalNotesSkeletonLoader';

const initParams = {
  page: 1,
  limit: 10,
};

let chatArr = [
  {
    value: 'messages',
    name: 'Messages',
  },
  {
    value: 'internal_notes',
    name: ({ isActive, notesCount }: any) => (
      <div className="flex items-center gap-1 ">
        <p>Internal Notes</p>
        {!!notesCount && (
          <BadgeCmp
            style="pill"
            type={isActive ? 'primary' : 'gray'}
            mainClassName={`!border-borderPrimary`}
          >
            {notesCount ?? 0}
          </BadgeCmp>
        )}
      </div>
    ),
  },
];

interface IProps {
  data: any;
  isClaim: boolean;
  notesCount: null | number;
  internalNotes: any;
  setInternalNotes: any;
  currentTab: any;
  setCurrentTab: any;
  setIsShowChatAndNotesSideBar: any;
  lastNoteId: any;
  setLastNoteId: any;
}

const InternalNotes = ({
  data,
  isClaim,
  notesCount,
  internalNotes,
  setInternalNotes,
  currentTab,
  setCurrentTab,
  setIsShowChatAndNotesSideBar,
  lastNoteId,
  setLastNoteId,
}: IProps) => {
  const [params, setParams] = useState({
    ...initParams,
    isClaim,
    orderID: data?.orderID,
  });
  const [isInternalNotesLoading, setIsInternalNotesLoading] = useState(false);
  const [isCreateInternalNoteLoading, setIsCreateInternalNoteLoading] =
    useState(false);
  const [isEndReached, setIsEndReached] = useState(false);
  const [groupID, setGroupID] = useState<number | null>(null);
  const [hasUnreadNotes, setHasUnreadNotes] = useState(false);
  const [isMakeReadNote, setIsMakeReadNote] = useState(false);
  const [lastNoteRecordId, setLastNoteRecordId] = useState<any>(null);

  const { handleSubmit, control, reset, watch } = useForm({});
  const noteContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (noteContainerRef.current) {
      noteContainerRef.current.scrollTop =
        noteContainerRef.current.scrollHeight;

      setTimeout(() => {
        const inputElement = document.getElementById('noteInput');

        if (inputElement) {
          inputElement.focus();
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (params.page === 2) scrollToBottom();
  }, [internalNotes, params]);

  const groupIDRef = useRef(groupID);
  const typeRef = useRef(isClaim ? 'claim' : 'dispute');
  const entityIdRef = useRef(data.id);
  const lastNoteIdRef = useRef(lastNoteId);
  const lastNoteRecordRef = useRef(lastNoteRecordId);

  useEffect(() => {
    groupIDRef.current = groupID;
    typeRef.current = isClaim ? 'claim' : 'dispute';
    entityIdRef.current = data.id;
    lastNoteIdRef.current = lastNoteId;
    lastNoteRecordRef.current = lastNoteRecordId;
  }, [groupID, isClaim, data.id, lastNoteId, lastNoteRecordId]);

  const getClaimInternalNotesList = (param: any) => {
    setIsInternalNotesLoading(true);

    getInternalNotes(data?.id, param)
      .then((response: any) => {
        setInternalNotes((prevNotes: any) => [
          ...response.data.data,
          ...prevNotes,
        ]);
        setIsInternalNotesLoading(false);
        setParams((old) => ({ ...old, ...{ page: params.page + 1 } }));
        setIsEndReached(response?.data?.data?.length < 10);
        setHasUnreadNotes(response?.data?.hasUnreadNotes);
        setLastNoteId(response?.data?.lastNoteId);
        setGroupID(response?.data?.groupId);
        setLastNoteRecordId(response?.data?.lastReadRecord?.note);

        if (response?.data?.lastReadRecord?.note) {
          if (
            response?.data?.data.some(
              (note: any) => note.id === response?.data?.lastReadRecord.note
            )
          ) {
            setIsMakeReadNote(true);
          }
        } else {
          if (
            response?.data?.data.length === 0 ||
            response?.data?.data.length < 10
          ) {
            setIsMakeReadNote(true);
          }
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (params)
      if (data?.id) {
        getClaimInternalNotesList(params);
      }
  }, []);

  const fetchMoreNotes = async (formData: any) => {
    try {
      if (!isInternalNotesLoading && !isEndReached) {
        getClaimInternalNotesList(formData);
      }
    } catch (error) {
      console.error('Failed to load older messages:', error);
    }
  };

  const debouncedFetchMoreMessages = useCallback(
    debounce(fetchMoreNotes, 300),
    [params]
  );

  useEffect(() => {
    const noteContainer = noteContainerRef.current;

    const handleWheel = () => {
      if (
        noteContainer &&
        noteContainer.scrollTop === 0 &&
        !isInternalNotesLoading
      ) {
        if (data.orderID) {
          debouncedFetchMoreMessages(params);
        }
      }
    };

    if (noteContainer) {
      noteContainer.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (noteContainer) {
        noteContainer.removeEventListener('wheel', handleWheel);
      }
      debouncedFetchMoreMessages.cancel();
    };
  }, [data.orderID, params, isInternalNotesLoading]);

  const handleCreateNote = (formData: any) => {
    if (formData.note && formData.note != '') {
      setIsEndReached(false);
      setIsCreateInternalNoteLoading(true);
      createInternalNotes(data?.id, {
        content: formData.note,
        isClaim: isClaim,
      })
        .then((response: any) => {
          if (response && response.data) {
            reset({ note: '' });
            scrollToBottom();
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsCreateInternalNoteLoading(false);
        });
    }
  };

  const markNotesAsRead = () => {
    const parameters = {
      groupId: groupIDRef.current,
      type: typeRef.current,
      entityId: entityIdRef.current,
      lastNoteId: lastNoteIdRef.current,
    };

    updateLastNoteRead(parameters)
      .then(() => {
        const updatedNotes = internalNotes.map((note: any) => ({
          ...note,
          isUnread: false,
        }));

        setInternalNotes(updatedNotes);
        setHasUnreadNotes(false);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (hasUnreadNotes && internalNotes.length > 0 && isMakeReadNote) {
      setTimeout(() => {
        markNotesAsRead();
      }, 5000);
    }
  }, [hasUnreadNotes, lastNoteId, internalNotes, isMakeReadNote]);

  useEffect(() => {
    setInternalNotes([]);

    return () => {
      if (
        (lastNoteIdRef.current &&
          lastNoteRecordRef.current &&
          lastNoteIdRef.current > lastNoteRecordRef.current) ||
        (lastNoteIdRef.current && !lastNoteRecordRef.current)
      ) {
        markNotesAsRead();
      }
    };
  }, []);

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <div className="p-5 pb-4 border-b border-utilityGray200">
        <div className="flex justify-between gap-1">
          <div className="flex items-center flex-wrap">
            <h6 className="mr-1 text-grayLight900 text-md font-semibold ">
              Internal Notes
            </h6>
            <BadgeCmp
              style="modern"
              type="success"
              mainClassName={`${
                isInternalNotesLoading ? 'custom-loading' : ''
              }`}
            >
              {notesCount} {notesCount && notesCount > 1 ? 'notes' : 'notes'}
            </BadgeCmp>
          </div>
          <div
            className="cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full"
            onClick={() => setIsShowChatAndNotesSideBar(false)}
          >
            <XClose className="w-4 h-4 text-gray400 hover:text-textSecondary " />
          </div>
        </div>
        <div className="flex flex-wrap text-grayLight600 font-normal text-xs mb-4">
          Internal Reference and Documentation
        </div>
        <TabButton
          tabArray={chatArr.map((tab: any) => ({
            ...tab,
            name:
              typeof tab.name === 'function'
                ? tab.name({
                    isActive: currentTab === tab.value,
                  })
                : tab.name,
          }))}
          tabParentClassName="!w-full"
          parentClassName="!bg-gray50 !w-full "
          className="rounded-md !text-gray500 !border-0 w-1/2 "
          activeClassName="!bg-white shadow-md active-tab [&>span]:text-textSecondary"
          isActive={currentTab}
          isTab={true}
          handleOnClick={(e: any) => {
            e.stopPropagation();
            setCurrentTab(
              e?.currentTarget?.dataset?.value ?? e?.target?.dataset?.value
            );
          }}
        />
      </div>

      <div
        className="px-4 flex-1 overflow-auto custom-scrollbar-v2 flex "
        ref={noteContainerRef}
      >
        <ul className="w-full mt-auto">
          {isInternalNotesLoading &&
            Array(10)
              .fill(null)
              .map((_, index) => <InternalNotesSkeletonLoader key={index} />)}

          {internalNotes?.map((internalNote: any) => (
            <InternalNoteCard
              key={internalNote?.id}
              internalNote={internalNote}
            />
          ))}
        </ul>
      </div>

      <form
        className="p-4 flex gap-3 border-t border-utilityGray200"
        onSubmit={handleSubmit(handleCreateNote)}
      >
        <Controller
          name="note"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="note"
              placeholder="Add an internal note"
              className=""
              id="noteInput"
              value={value}
              parentClassName="flex-1"
              onChangeFunc={onChange}
            />
          )}
        />
        <ButtonCmp
          className="btn_primary lg:!px-[9px] !px-2"
          disabled={!watch('note') || isCreateInternalNoteLoading}
          loading={isCreateInternalNoteLoading}
        >
          <Plus className="w-4 h-4 text-white" />
        </ButtonCmp>
      </form>
    </div>
  );
};

export default InternalNotes;
