import { XClose } from '@untitled-ui/icons-react//build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const SalesRepCommissionReportLoadingRow = ({ data }: any) => (
  <>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <h6 className=" h-8 text-primary underline flex items-center font-medium hover:text-primary700 cursor-pointer custom-loading">
        {data?.fullID}
      </h6>
    </td>
    <td className="px-5 py-4 pr-0 w-8 min-w-8 max-w-8">
      <div className="custom-loading">
        <XClose className="w-4 h-4 text-fgErrorPrimary " />
      </div>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <div className="flex gap-1 flex-wrap">
        {(data?.orderStatus === 'new' ||
          data?.orderStatus === 'saved' ||
          data?.orderStatus === 'quoted' ||
          data?.orderStatus === 'spot-qq-requested' ||
          data?.orderStatus === 'spot-quote-requested' ||
          data?.orderStatus === 'pending' ||
          data?.orderStatus === 'draft-quick-quote' ||
          data?.orderStatus === 'quick-quoted' ||
          data?.orderStatus === 'reject' ||
          data?.orderStatus === 'archived' ||
          data?.orderStatus === 'expired') && (
          <BadgeCmp style="modern" type="gray">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'no-quote' ||
          data?.orderStatus === 'no-quick-quote' ||
          data?.orderStatus === 'cancelled') && (
          <BadgeCmp style="modern" type="pink">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'tender' ||
          data?.orderStatus === 'booked' ||
          data?.orderStatus === 'invoice-paid' ||
          data?.orderStatus === 'delivered') && (
          <BadgeCmp style="modern" type="lightblue">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {(data?.orderStatus === 'invoice-created' ||
          data?.orderStatus === 'invoice-sent') && (
          <BadgeCmp style="modern" type="error">
            {data?.orderStatus}
          </BadgeCmp>
        )}
        {/* Tags */}
        {data?.orderStatus === 'dispatched' && (
          <BadgeCmp style="modern" type="pink">
            {data?.orderStatus}
          </BadgeCmp>
        )}

        {/* <BadgeCmp style="modern" type="success">
            New
          </BadgeCmp>
          <BadgeCmp style="modern" type="primary">
            Dispatched
          </BadgeCmp>
          <BadgeCmp style="modern" type="warning">
            Invoice- created
          </BadgeCmp> */}
      </div>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <span className="custom-loading">{data?.orderCreatedAt}</span>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <span className="custom-loading">{data?.combinedCost}</span>
    </td>
    <td className="px-5 py-4 w-[13.18%] max-w-[13.18%] min-w-[13.18%]">
      <span className="custom-loading">{data?.combinedRevenue}</span>
    </td>
    <td className="px-5 py-4 ">
      <span className="custom-loading">{data?.combinedMargin}</span>
    </td>

    <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
      <div className="justify-center items-center gap-3 flex custom-loading">
        <XClose className="w-4 h-4 text-fgErrorPrimary" />
      </div>
    </td>
  </>
);

export default SalesRepCommissionReportLoadingRow;
