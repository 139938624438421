import {
  ArrowNarrowRight,
  ClockPlus,
  Cube01,
  Cube03,
  DotsVertical,
  Package,
  Plane,
  Repeat01,
  ReverseLeft,
  Signal01,
  // Star05,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
// import TabButton from 'src/components/TabButton';
import { PATH } from 'src/constants/path';
import { displayTimeDifference, getShortName } from 'src/utils/CommonFunctions';

import ReplyModal from '../../QuotingRequest/ReplyModal';
import MessageBodyLoading from '../Skeleton/MessageBodyLoading';

const MailDetailView = ({ emailDetails, setIsEmailClicked }: any) => {
  const [isShowReplyModal, setIsShowReplyModal] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState<string>('LTL');
  const [selectedUrgency, setSelectedUrgency] = useState<string>('LIVE');
  const navigate = useNavigate();
  const selectedEmail = emailDetails?.emailBody;
  const isQuoteEmail = emailDetails?.isQuoteEmail;

  const dotsMenuRef = useRef<HTMLDivElement>(null);

  const handleRedirect = () => {
    navigate(
      `${PATH.ADDRESS_DETAILS}?messageId=${selectedEmail?.id}&class=${selectedClass}&urgency=${selectedUrgency}`
    );
  };

  const [timeAgo, setTimeAgo] = useState('');

  // Function to calculate time difference

  useEffect(() => {
    // Update the timeAgo state initially
    setTimeAgo(displayTimeDifference(selectedEmail?.receivedDateTime));

    // Update the timeAgo state every second
    const interval = setInterval(() => {
      setTimeAgo(displayTimeDifference(selectedEmail?.receivedDateTime));
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [selectedEmail?.receivedDateTime]);

  const [isClassApplied, setIsClassApplied] = useState<boolean>(false);

  const handleToggleClass = () => {
    setIsClassApplied(!isClassApplied);
  };

  // Check if click is outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dotsMenuRef.current &&
        !dotsMenuRef.current.contains(event.target as Node)
      ) {
        setIsClassApplied(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dotsMenuRef]);

  return (
    <>
      {selectedEmail && (
        <>
          <div className="flex flex-wrap xxl:gap-4 lg:gap-3 gap-2 w-full justify-between border-b border-utilityGray200 px-5 py-3.5">
            <div className="lg:w-12 w-8 lg:h-12 h-8 relative bg-utilityGray100 rounded-full border-[0.75px] border-black/[0.08] flex justify-center items-center overflow-hidden flex-none">
              <span className="text-primary lg:text-xl text-sm font-semibold">
                {selectedEmail?.from?.emailAddress?.name
                  ? getShortName(
                      selectedEmail?.from?.emailAddress?.name?.toUpperCase()
                    )
                  : ''}
              </span>
            </div>
            <div className="flex-1 flex flex-wrap xxl:gap-x-4 gap-x-2 lg:flex-row flex-col ">
              <div className="flex-1">
                <div className="flex flex-wrap gap-x-2 gap-y-1 items-center mb-0.5">
                  <h6 className="text-grayLight900 text-base font-semibold flex xxl:gap-4 gap-3 items-center">
                    {selectedEmail?.from?.emailAddress?.name}
                  </h6>
                  <div className="rounded-md border border-utilityGray200 bg-gray50 flex px-1.5 py-0.5">
                    <a
                      href="mailto:katherine.moss@wal.com"
                      className="text-textSecondary text-xs font-medium line-clamp-1 break-all"
                    >
                      {selectedEmail?.from?.emailAddress?.address}
                    </a>
                  </div>
                  {/* <span className="text-grayLight600 text-[11px] font-normal">
                    {moment(selectedEmail?.receivedDateTime).format(
                      'dddd, MMMM D, YYYY h:mm A'
                    )}
                  </span> */}
                </div>
                <div className="text-grayLight600 text-sm flex sm:flex-row flex-col items-start">
                  <span className="font-bold mr-1">Subject: </span>
                  <span className="font-normal">{selectedEmail?.subject}</span>

                  {/* <BadgeCmp
                  style="pill"
                  type="success"
                  mainClassName="rounded-[8px]"
                >
                  <ClockPlus className="w-3 h-3 me-1" /> 00:20:00
                </BadgeCmp> */}
                </div>
              </div>
              <div className="flex flex-wrap items-end justify-start lg:flex-col gap-1">
                <div className="text-grayLight600 text-xs font-normal lg:mb-1">
                  {moment(selectedEmail?.receivedDateTime).format(
                    'dddd, MMMM D, YYYY h:mm A'
                  )}
                </div>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName="rounded-[8px] border border-success200 bg-success50 "
                  badgeTextColor="!text-success700"
                  isHidePillDot
                >
                  <ClockPlus className="w-3 h-3 me-1" /> {timeAgo}
                </BadgeCmp>
              </div>
            </div>
            {/* <DotsVertical
              className="w-4 h-4 text-gray400 sm:hidden flex-none"
              onClick={handleToggleClass}
            /> */}
            <ButtonCmp
              className="btn_secondary_black !px-1.5 !py-1.5 self-start sm:hidden"
              onClick={handleToggleClass}
            >
              <DotsVertical className="w-3.5 h-3.5 text-gray400 sm:hidden flex-none" />
            </ButtonCmp>
            <ButtonCmp
              className="btn_secondary_black !px-1.5 !py-1.5 self-start sm:hidden"
              onClick={() => setIsEmailClicked(false)}
            >
              <XClose className="w-3.5 h-3.5" />
            </ButtonCmp>
          </div>
          <div className="flex-1 w-full flex-col justify-start items-start gap-4 flex p-5 overflow-y-auto custom-scrollbar-v2">
            {/* <div
          className=" "
          dangerouslySetInnerHTML={{
            __html: selectedEmail?.body?.content?.replace(/\\|\r\n/g, ''),
          }}
        ></div> */}
            <iframe
              srcDoc={selectedEmail?.body?.content?.replace(/\\|\r\n/g, '')}
              style={{ border: 'none', width: '100%', height: '100%' }}
              className="min-h-[auto]"
            />
          </div>
          <div
            ref={dotsMenuRef}
            className={`flex sm:flex-wrap xxl:gap-5 gap-2 sm:sticky sm:bottom-0 bg-gray50 border-t border-utilityGray200 px-5 py-3  max-sm:top-465px] max-sm:right-[55px] max-sm:shadow max-sm:bg-white max-sm:rounded-lg max-sm:border max-sm:border-borderPrimary max-sm:h-64 max-sm:overflow-y-auto max-sm:flex-col max-sm:p-3 ${
              isClassApplied ? 'max-sm:absolute' : 'max-sm:hidden'
            } `}
          >
            <div>
              <label className="form_label block mb-1.5">
                Classification
                <span className="text-red-600 leading-4">*</span>
              </label>
              <div className="flex max-sm:flex-col gap-2">
                <ButtonCmp
                  icon={<Package className="w-4 h-4" />}
                  iconSide="left"
                  disabled={!isQuoteEmail}
                  className={`${
                    selectedClass === 'LTL'
                      ? 'btn_primary'
                      : 'btn_secondary_black'
                  }`}
                  onClick={() => setSelectedClass('LTL')}
                >
                  LTL
                </ButtonCmp>
                <ButtonCmp
                  icon={<Cube01 className="w-4 h-4" />}
                  iconSide="left"
                  className={`${
                    selectedClass === 'FTL'
                      ? 'btn_primary'
                      : 'btn_secondary_black'
                  }`}
                  onClick={() => setSelectedClass('FTL')}
                  disabled={!isQuoteEmail}
                >
                  FTL
                </ButtonCmp>
                <ButtonCmp
                  icon={<Cube03 className="w-4 h-4" />}
                  iconSide="left"
                  className={`${
                    selectedClass === 'VOLUME_LTL'
                      ? 'btn_primary'
                      : 'btn_secondary_black'
                  }`}
                  onClick={() => setSelectedClass('VOLUME_LTL')}
                  disabled={!isQuoteEmail}
                >
                  Volume LTL
                </ButtonCmp>
                <ButtonCmp
                  icon={<Plane className="w-4 h-4" />}
                  iconSide="left"
                  className={`${
                    selectedClass === 'OTHER'
                      ? 'btn_primary'
                      : 'btn_secondary_black'
                  }`}
                  onClick={() => setSelectedClass('OTHER')}
                  disabled={!isQuoteEmail}
                >
                  Other
                </ButtonCmp>
              </div>

              {/* <TabButton
                className="min-w-30 w-30 flex justify-center items-center"
                tabArray={quoteTypeArr}
                isActive={classifyQuote}
                activeClassName="bg-primary text-white"
                handleOnClick={(e: any) => {
                  setClassifyQuote(e.target.dataset.value);
                }}
              /> */}
            </div>
            <div className="flex-1 min-w-[126px]">
              <label className="form_label block mb-1.5">
                Urgency
                <span className="text-red-600 leading-4">*</span>
              </label>
              <div className="flex max-sm:flex-col  gap-2">
                <ButtonCmp
                  icon={<Signal01 className="w-4 h-4" />}
                  iconSide="left"
                  disabled={!isQuoteEmail}
                  className={`${
                    selectedUrgency === 'LIVE'
                      ? 'btn_primary'
                      : 'btn_secondary_black'
                  }`}
                  onClick={() => setSelectedUrgency('LIVE')}
                >
                  Live
                </ButtonCmp>
                <ButtonCmp
                  icon={<Repeat01 className="w-4 h-4" />}
                  iconSide="left"
                  className={`${
                    selectedUrgency === 'RFQ'
                      ? 'btn_primary'
                      : 'btn_secondary_black'
                  }`}
                  onClick={() => setSelectedUrgency('RFQ')}
                  disabled={!isQuoteEmail}
                >
                  RFQ
                </ButtonCmp>
              </div>
            </div>
            <div className="max-xl:w-full">
              <label className="form_label block mb-1.5">
                Actions
                <span className="text-red-600 leading-4">*</span>
              </label>
              <div className="flex max-sm:flex-col gap-2">
                <ButtonCmp
                  className="btn_secondary_black"
                  icon={<XClose className="w-4 h-4" />}
                  disabled={!isQuoteEmail}
                >
                  Dismiss
                </ButtonCmp>
                <ButtonCmp
                  className="btn_secondary_black"
                  icon={<ReverseLeft className="w-4 h-4" />}
                  onClick={() => setIsShowReplyModal(true)}
                  disabled={!isQuoteEmail}
                >
                  Reply
                </ButtonCmp>

                <ButtonCmp
                  className="btn_primary"
                  iconSide="right"
                  icon={<ArrowNarrowRight className="w-4 h-4" />}
                  disabled={!isQuoteEmail}
                  onClick={() => handleRedirect()}
                >
                  Confirm Quote Details
                </ButtonCmp>
              </div>
            </div>
          </div>
        </>
      )}
      {!selectedEmail && <MessageBodyLoading />}

      {isShowReplyModal && (
        <ReplyModal
          emailDetails={selectedEmail}
          handleClose={() => setIsShowReplyModal(false)}
        />
      )}
    </>
  );
};

export default MailDetailView;
