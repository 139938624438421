import { yupResolver } from '@hookform/resolvers/yup';
import { File06 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { PATH } from 'src/constants/path';
import { updateDisputeStatus } from 'src/services/DisputeService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

const disputeResolveSchema = yup.object().shape({
  disputeResolveDescription: yup.string().required('Description is required.'),
  disputeID: yup.string().required('DisputeID is required.'),
});

interface IProps {
  handleClose: () => void;
  dispute: any;
  reloadDisputes: () => void;
  isDisputeListPage: boolean;
}

const DisputeResolve = ({
  handleClose,
  dispute,
  reloadDisputes,
  isDisputeListPage,
}: IProps) => {
  const navigate = useNavigate();

  const [isDisputeStatusUpdateLoading, setIsDisputeStatusUpdateLoading] =
    useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(disputeResolveSchema),
    defaultValues: {
      disputeResolveDescription: '',
    },
  });

  useEffect(() => {
    if (dispute && dispute?.disputeID) {
      setValue('disputeID', `#${dispute?.disputeID}`);
    }
  }, []);

  const onSubmit = (formData: any) => {
    setIsDisputeStatusUpdateLoading(true);

    const appendFormData: any = new FormData();
    Object.entries(formData).forEach((data) => {
      const [key, value] = data;

      if (key !== 'disputeID') {
        appendFormData.append(key, value);
      }
    });

    if (dispute && dispute?.disputeID) {
      appendFormData.append('disputeID', dispute?.disputeID);
    }
    appendFormData.append('status', 'Resolved');

    updateDisputeStatus(appendFormData)
      .then(() => {
        if (isDisputeListPage && reloadDisputes) {
          reloadDisputes();
        } else {
          navigate(PATH.CLAIMS_AND_DISPUTES_DETAILS);
        }
      })
      .catch(() =>
        WalToast.error('Something went wrong while resolving dispute.')
      )
      .finally(() => {
        setIsDisputeStatusUpdateLoading(false);
        handleClose();
      });
  };

  const headerIcon = <File06 className="w-7 h-7" />;

  return (
    <CommonModal
      title={`Resolve Dispute`}
      titleDesc={`Add description to resolve dispute`}
      handleClose={handleClose}
      size={'max-w-[540px]'}
      headerIcon={headerIcon}
      primaryBtnText="Save"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isDisputeStatusUpdateLoading}
      primaryBtnDisabled={isDisputeStatusUpdateLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose()}
      secondaryBtnDisabled={isDisputeStatusUpdateLoading}
    >
      <div className="p-5">
        <Controller
          name="disputeID"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              label="Dispute ID"
              inputName="disputeID"
              placeholder="Dispute ID"
              parentClassName="mb-4"
              className=""
              value={value}
              onChangeFunc={onChange}
              disabled={true}
              errorText={errors?.disputeID?.message ?? ''}
              labelClassName="block mb-1.5"
            />
          )}
        />

        <Controller
          name="disputeResolveDescription"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="disputeResolveDescription"
              label="Resolution description"
              placeholder="Enter Resolve Dispute Description"
              value={value}
              onChangeFunc={onChange}
              labelClassName="block mb-1.5"
              className="h-32"
              errorText={errors?.disputeResolveDescription?.message ?? ''}
              asTextarea="textarea"
            />
          )}
        />
      </div>
    </CommonModal>
  );
};

export default DisputeResolve;
