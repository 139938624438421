import { yupResolver } from '@hookform/resolvers/yup';
import {
  Copy06,
  LinkExternal02,
  User01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
// import DynamicCKEditor from 'src/components/DynamicCKEditor';
import InputText from 'src/components/InputText/InputText';
import { PATH } from 'src/constants/path';
import { AuthContext } from 'src/context/AuthContext';
import { sendCustomerMail } from 'src/services/CustomerOnBoardingService';
// import { getShortName, onError } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import TailwindAdvancedEditor from '../../components/AdvancedEditor';

interface IMailConfirmation {
  action?: string;
  setAction?: any;
  customerData?: any;
  handleClose: any;
}

const MailConfirmation = ({
  handleClose,
  customerData,
  setAction,
}: IMailConfirmation) => {
  const webUrl = window.WEB_URL;
  const defaultEditorContent = {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [{ type: 'text', text: `Hi, ${customerData.name}, ` }],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Your request for secure access requires a one-time password (OTP). Please use the OTP below to complete your authentication process.',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            marks: [
              {
                type: 'textStyle',
                attrs: {
                  color: '#008A00',
                },
              },
            ],
            text: 'Your OTP is: ',
          },
          {
            type: 'text',
            marks: [
              {
                type: 'textStyle',
                attrs: {
                  color: '#008A00',
                },
              },
              {
                type: 'bold',
              },
            ],
            text: '[XXXX]',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Please find our account setup portal below:',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: `${webUrl}${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}`,
                  target: '_blank',
                  class:
                    '!cursor-not-allowed !pointer-events-none disabledLink',
                },
              },
            ],
            text: `${webUrl}${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}`,
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'I’ve CC’ed my finance team should you have any questions or concerns.',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'I’ll send you your BOL as soon as your account is opened.',
          },
        ],
      },
      {
        type: 'paragraph',
        content: [{ type: 'text', text: 'Welcome aboard!' }],
      },
      {
        type: 'paragraph',
        content: [{ type: 'text', text: 'Regards,' }],
      },
    ],
  };
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [novelContent, setNovelContent] = useState<any>(
    JSON.stringify(defaultEditorContent)
  );

  const emailBody = `<div className="flex flex-col border border-utilityGray200 rounded-xl px-4 py-3 gap-4 mt-4  ">
      <p className="text-grayLight600 text-xs font-normal">
        Hi, ${customerData.name} <br/>
      </p>
       <p>Your request for secure access requires a one-time password (OTP). Please use the OTP below to complete your authentication process.</p>
      <p style="color:#008A00"><b>Your OTP is: [XXXX]</b><br/></p>
      <p className="text-grayLight600 text-xs font-normal">
        Please find our account setup portal below:
      </p>
      <p className="text-primary text-xs font-normal">
      <a href="${webUrl}${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}">
        ${webUrl}${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}</a><br/>
      </p>
      <p className="text-grayLight600 text-xs font-normal">
        I’ve CC’ed my finance team should you have any questions or concerns.
        <br />
      </p>
      <p className="text-grayLight600 text-xs font-normal">
        I’ll send you your BOL as soon as your account is opened.
      </p>
      <p className="text-grayLight600 text-xs font-normal">Welcome aboard!</p>
      <p className="text-grayLight600 text-xs font-normal">Regards</p>
    </div>`;

  // .props.children.reduce(
  //   (acc: any, elem: any) => acc + elem.props.children,
  //   ''
  // );
  const validationSchema = yup.object().shape({
    emailBody: yup.mixed().required('emailBody is required.'),
    to: yup
      .string()
      .required('Email is required.')
      .test({
        message: 'Invalid email address',
        test: function (value) {
          if (value != '') {
            const emailRegex =
              /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;

            return emailRegex.test(value ?? '');
          }

          return true;
        },
      }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      emailBody: emailBody,
      to: customerData.email || '',
    },
  });

  const sendMailToCustomer = (data: any) => {
    setIsLoading(true);

    sendCustomerMail(customerData?.id, data)
      .then(() => {
        handleClose();
        localStorage.removeItem('novel-content');
        localStorage.removeItem('html-content');
        WalToast.success('Mail send to Customer successfully');
      })
      .catch(() => WalToast.error('something went wrong please try again'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const handleEditorChange = (data: any) => {
  //   setValue('emailBody', data);
  // };

  // const handleLinkClick = (e: any) => {
  //   e.preventDefault();
  //   console.log('handleLinkClick :>> ');
  //   localStorage.setItem(
  //     'verifyData',
  //     JSON.stringify({ customerId: customerData?.customerId, isVerify: '1' })
  //   );
  //   window.open(
  //     `${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}`,
  //     '_blank',
  //     'noopener,noreferrer'
  //   );
  // };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  return (
    <CommonModal
      title="Send Email"
      titleDesc="You can edit your details"
      handleClose={() => handleClose(false)}
      headerIcon={<User01 />}
      size={'max-w-[688px]'}
      secondaryBtnOnClick={() =>
        setAction({
          mode: 'review-onboarding',
          data: null,
          id: customerData?.id,
        })
      }
      secondaryBtnText="Back"
      primaryBtnOnClick={handleSubmit(sendMailToCustomer)}
      primaryBtnText="Send Email"
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      isOverflow={true}
    >
      <div className="w-full p-5 ">
        <div className="flex-col gap-4 flex ">
          <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
            <span className="text-gray500 text-sm font-semibold sm:min-w-[60px] min-w-6">
              From:
            </span>

            <InputText value={currentUser.email} parentClassName="flex-1" />

            {/* <div className="items-center gap-2 flex text-textSecondary text-xs font-semibold flex-wrap">
              {currentUser?.image ? (
                <img
                  alt=""
                  src={currentUser.imageUrl + currentUser.image}
                  onError={onError}
                  className="w-7 h-7 relative rounded-full border border-black/[0.08]"
                />
              ) : (
                <div className="w-7 h-7 bg-primary rounded-full text-white font-semibold flex items-center justify-center uppercase border border-black/[0.08] flex-none">
                  {getShortName(
                    `${currentUser?.firstName} ${currentUser?.lastName}`
                  )}
                </div>
              )}
              <span className="font-medium sm:w-auto break-all">
                {currentUser?.firstName} {currentUser?.lastName}
              </span>
              <span className="underline font-medium sm:w-auto break-all">
                {currentUser.email}
              </span>
            </div> */}
          </div>
          <Controller
            name="to"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
                <span className="text-gray500 text-sm font-semibold sm:min-w-[60px] min-w-6">
                  To:
                </span>
                <InputText
                  inputName="to"
                  placeholder="-"
                  onChangeFunc={onChange}
                  value={value}
                  parentClassName="flex-1"
                  errorText={errors.to ? errors.to.message : null}
                />
              </div>
            )}
          />

          <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
            <span className="text-gray500 text-sm font-semibold sm:min-w-[60px] min-w-6">
              Subject :
            </span>
            <InputText
              inputName="Subject"
              placeholder="-"
              parentClassName="flex-1"
              value="Customer onboarding form"
            />
          </div>

          {/* <div className="sm:items-center md:gap-6 sm:gap-4 gap-2 flex sm:flex-row flex-col">
            <label className="form_label block mb-0.5">Onboarding URL</label>
            <Link
              target="_blank"
              to={`${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}`}
              // onClick={handleLinkClick}
              className="text-primary truncate text-sm font-medium underline cursor-pointer"
            >
              {webUrl}
              {PATH.CUSTOMER_ONBOARDING}/{customerData?.customerId}
            </Link>
          </div> */}

          <div className="border-t border-utilityGray200">
            <div className="novel-editor-wrap mt-4">
              <Controller
                name="emailBody"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {console.log(
                      'value :>> ',
                      value,
                      '   ',
                      JSON.parse(novelContent as string)
                    )}
                    <TailwindAdvancedEditor
                      initialValue={JSON.parse(novelContent as string)}
                      handleChange={(htmlContent: any, novelCont: any) => {
                        setNovelContent(novelCont);
                        onChange(htmlContent);
                      }}
                    />
                  </>
                )}
              />
            </div>

            {/* <div className="flex flex-col border border-utilityGray200 rounded-xl px-4 py-3 gap-4 mt-4  ">
              <p className="text-grayLight600 text-xs font-normal">
                Hi, {customerData.customerName}
              </p>
              <p className="text-grayLight600 text-xs font-normal">
                Please find our account setup portal below;{' '}
              </p>
              <p className="text-primary text-xs font-normal">
                onboarding.westernalliancelogistics.com
              </p>
              <p className="text-grayLight600 text-xs font-normal">
                I’ve CC’ed my finance team should you have any questions or
                concerns. <br />
              </p>
              <p className="text-grayLight600 text-xs font-normal">
                I’ll send you your BOL as soon as your account is opened.
              </p>
              <p className="text-grayLight600 text-xs font-normal">
                Welcome aboard!
              </p>
              <p className="text-grayLight600 text-xs font-normal">Regards</p>
            </div> */}
          </div>
          <div className="">
            <h6 className="text-grayLight600 text-xs font-medium mb-1.5">
              View Onboarding URL
            </h6>
            <div className="rounded-md border border-borderPrimary bg-utilityGray100 shadow-xs px-3 py-2 flex items-center gap-1.5">
              <p className="text-primary700 text-sm font-medium underline flex-1 truncate w-0">
                {webUrl}
                {PATH.CUSTOMER_ONBOARDING}/{customerData?.customerId}
              </p>
              <Copy06
                className="h-4 w-4 text-primary"
                onClick={() =>
                  copyDebounce(
                    `${webUrl + PATH.CUSTOMER_ONBOARDING}/${
                      customerData?.customerId
                    }`
                  )
                }
              />
              <Link
                target="_blank"
                to={`${PATH.CUSTOMER_ONBOARDING}/${customerData?.customerId}`}
              >
                <LinkExternal02 className="h-4 w-4 text-primary " />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default MailConfirmation;
