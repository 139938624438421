import { yupResolver } from '@hookform/resolvers/yup';
import {
  Check,
  ChevronDown,
  CurrencyDollar,
  FilePlus01,
} from '@untitled-ui/icons-react/build/cjs';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { PATH } from 'src/constants/path';
import { getCustomersByUserId } from 'src/services/CustomerService';
import {
  addLoadInsurance,
  updateLoadInsurance,
} from 'src/services/LoadInsurance';
import { getOrders } from 'src/services/OrderService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  getFormattedNumber,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

interface ILoadInsurance {
  loadInsurance?: any;
  getDetail?: any;
  handleClose: any;
  // orderData: any;
}

const RequestLoadInsurance = ({
  handleClose,
  // orderData,
  getDetail,
  loadInsurance,
}: ILoadInsurance) => {
  const navigate = useNavigate();
  const filterRef: any = useRef(null);

  const [userList, setUserList] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState<any>([]);
  const [customerId, setCustomerId] = useState();
  const [userId, setUserId] = useState();
  const [customerList, setCustomerList] = useState<any>([]);
  const [filterOrderList, setFilteredOrderList] = useState<any>([]);
  const [orderFullID, setOrderFullID] = useState(loadInsurance?.fullId);
  const [showOrdersSearchValue, setShowOrdersSearchValue] = useState('');
  const [isShowOrders, setIsShowOrders] = useState(false);
  const validationSchema = yup.object().shape({
    userId: yup.string().nullable(),
    customerId: yup.string(),
    orderID: yup.string().required('orderId is required.'),
    commodity: yup.string().required('commodity is required.'),
    commercial: yup.string().required('commercial is required.'),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      userId: loadInsurance?.userId || '',
      customerId: loadInsurance?.customerId || '',
      orderID: loadInsurance?.fullId || '',
      commodity: loadInsurance?.commodity || '',
      commercial: loadInsurance?.commercial
        ? getFormattedNumber(loadInsurance?.commercial)
        : '',
    },
  });

  const getShowFilteredMemberList = (
    searchValue: string,
    originalList: any
  ) => {
    const filteredList = originalList.filter((originalData: any) =>
      originalData.fullId
        .toLowerCase()
        .includes(searchValue.toLowerCase().trim())
    );

    return [...filteredList] as any;
  };

  useEffect(() => {
    if (showOrdersSearchValue) {
      const filteredList = getShowFilteredMemberList(
        showOrdersSearchValue,
        orderList
      );
      setFilteredOrderList(filteredList);
    } else {
      setFilteredOrderList(orderList);
    }
  }, [showOrdersSearchValue]);

  const getCustomerList = () => {
    getCustomersByUserId({ member: watch('userId'), onlySales: true })
      .then((response: any) => {
        const customerObj = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'imageUrl',
          'image',
          undefined,
          '',
          true
        );
        setCustomerList(customerObj);
      })
      .catch(console.error);
  };

  const getOrderList = () => {
    const params = {
      member: watch('userId'),
      customerId: watch('customerId'),
    };
    getOrders(params)
      .then((response: any) => {
        // const orders = getSelectBoxOptions(response.data, 'fullId', 'fullId');
        setOrderList(response.data);
        setFilteredOrderList(response?.data);
      })
      .catch(() => WalToast.error('orders not fetched'));
  };

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        const allTeamMembers = Object.values(allTeamMembersObj).flat();
        const uniqueArr = _.uniqBy(allTeamMembers, 'id');
        const updatedListAllMembers = getSelectBoxOptions(
          uniqueArr,
          'id',
          'firstName',
          true,
          'imageUrl',
          'image',
          undefined,
          'lastName',
          true
        );

        setUserList([...updatedListAllMembers]);
        setCustomerList([]);
      })
      .catch(console.error);

    getCustomerList();
    getOrderList();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        isShowOrders &&
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        event.target.name !== 'searchOrder'
      ) {
        setIsShowOrders(false);
      }
    };

    const handleEscapePress = (event: any) => {
      if (isShowOrders && event.key === 'Escape') {
        setIsShowOrders(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowOrders]);

  useEffect(() => {
    getCustomerList();
    getOrderList();
    setIsShowOrders(false);
  }, [watch('userId'), watch('customerId')]);

  // useEffect(() => {
  //   getOrders()
  //     .then((response) => {
  //       // const orders = getSelectBoxOptions(response.data, 'id', 'fullId');
  //       setOrderList(response.data);
  //       setFilteredOrderList(response.data);
  //     })
  //     .catch(() => WalToast.error('Load Insurance request not added'))
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, []);

  const onSubmit = async (formData: any) => {
    setIsLoading(true);

    if (!watch('customerId')) formData.customerId = customerId;

    if (!watch('userId') || watch('userId') === '') {
      formData.userId = userId ?? undefined;
    }

    if (loadInsurance?.id) {
      updateLoadInsurance(formData, loadInsurance?.id)
        .then((response: any) => {
          if (response) {
            getDetail();
            handleClose();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      addLoadInsurance(formData)
        .then((response) => {
          handleClose();
          setIsLoading(false);
          navigate(`${PATH.LOAD_INSURANCE}/${response?.data?.id}`);
        })
        .catch(() => WalToast.error('Load Insurance request not added'))
        .finally(() => {
          setIsLoading(false);
        });
    }

    // handleClose();
  };

  return (
    <CommonModal
      title={
        loadInsurance?.id
          ? 'Edit Load Insurance Request'
          : 'New Load Insurance Request'
      }
      titleDesc="Create a new request for load insurance."
      handleClose={() => handleClose(false)}
      headerIcon={<FilePlus01 className="w-5 h-5" />}
      size={'max-w-[500px] overflow-unset'}
      secondaryBtnOnClick={() => handleClose(false)}
      modalHeaderClass=""
      headerInfoClass="!gap-0 [&>.text-xs]:text-sm"
      modalClassName="flex flex-col "
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      primaryBtnText={loadInsurance?.id ? 'Edit Request' : 'Send Request'}
      secondaryBtnText="Cancel"
      isOverflow={false}
    >
      <div className=" flex md:flex-row flex-col flex-1 p-5">
        <div className="flex-1 md:order-[0] order-2 flex flex-col gap-4">
          <Controller
            name="userId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                label="User"
                labelClassName="form_label block mb-1.5"
                name="userId"
                size="sm"
                placeholder="All Users"
                noOptionMessage="No Users Found"
                isSearchable={true}
                className="form_control"
                isClearable={true}
                options={userList}
                errorText={errors?.userId?.message ?? null}
                onChangeFunc={(event: any) => {
                  onChange(event?.value ?? undefined);
                }}
                value={userList.filter((user: any) => value === user.value)}
              />
            )}
          />
          <Controller
            name="customerId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                label="Customer"
                labelClassName="form_label block mb-1.5"
                name="customerId"
                size="sm"
                placeholder="All Customer"
                noOptionMessage="No Customer Found"
                isSearchable={true}
                className="form_control"
                isClearable={true}
                options={customerList}
                errorText={errors?.customerId?.message ?? null}
                onChangeFunc={(event: any) => {
                  onChange(event?.value);
                }}
                value={customerList.filter((cust: any) => value === cust.value)}
              />
            )}
          />

          {/* <div className="xls:max-w-[770px] flex-1 xls:w-[calc(100%_-_333px)] w-full"> */}

          <div className="w-full relative">
            <Controller
              name="orderID"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputText
                    label="Order ID"
                    labelClassName="block mb-1.5"
                    inputName="searchOrder"
                    placeholder="Order ID"
                    className="placeholder:text-secondaryTxtColor"
                    parentClassName=""
                    searchIconClass={`!right-8`}
                    icon={
                      <ChevronDown className="absolute top-1/2 -translate-y-1/2 right-2 text-grayText h-5 w-5" />
                    }
                    value={orderFullID}
                    onChangeFunc={(e: any) => {
                      setOrderFullID(e.target.value);
                      setShowOrdersSearchValue(e.target.value);
                    }}
                    onFocus={() => {
                      setIsShowOrders(true);
                    }}
                    errorText={errors?.orderID?.message ?? null}
                    // onBlur={() => {
                    //   setTimeout(() => {
                    //     setIsShowOrders(false);
                    //   }, 200);
                    // }}
                    isClearable={true}
                  />
                  {isShowOrders && (
                    <div
                      id="dropdownDelay"
                      ref={filterRef}
                      className={`min-w-[262px] z-10 absolute top-15 bg-white w-full border border-borderPrimary shadow-md my-1.5 rounded-[10px] left-0 top-full`}
                    >
                      <ul
                        className="mt-1.5 mb-[9px] max-h-[180px] overflow-y-auto custom-scrollbar-v2"
                        aria-labelledby="dropdownDelayButton"
                      >
                        {filterOrderList?.length > 0 ? (
                          filterOrderList
                            .slice(0, 1000)
                            .map((order: any, index: number) => (
                              <>
                                <li
                                  key={index}
                                  className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 hover:text-primary transition-all duration-[0.3s] ${
                                    order.fullId === value ? 'bg-gray50' : ''
                                  }`}
                                  onClick={() => {
                                    onChange(order?.fullId);
                                    setOrderFullID(order?.fullId);
                                    if (!watch('customerId'))
                                      setCustomerId(order?.customerId);
                                    if (!watch('userId'))
                                      setUserId(order?.salesRepId);

                                    // setShowOrdersSearchValue();
                                    setIsShowOrders(false);
                                  }}
                                >
                                  <div className="flex justify-between items-center align-middle">
                                    {' '}
                                    <div className="flex justify-center items-center">
                                      <span className="font-medium mr-2">
                                        # {order.fullId}{' '}
                                        {order.orderFromCity &&
                                        order.orderToCity
                                          ? ` (${order.orderFromCity} -
                                          ${order.orderToCity})`
                                          : ''}
                                      </span>
                                    </div>
                                    {order.id === value ? (
                                      <Check className="w-4 h-4 ml-2 text-primary" />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </li>
                              </>
                            ))
                        ) : (
                          <li
                            className={`mx-2 p-2 text-xs rounded-md cursor-pointer [&.active]:!bg-gray50 hover:!bg-gray50 transition-all duration-[0.3s] `}
                          >
                            {' '}
                            <span className="font-semibold mr-2 items-center justify-center flex">
                              No Orders found
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}

                  {/* {orderList?.length > 0 && (
                    <div
                      className={`mt-3 w-full max-h-[235px] team-user-select overflow-auto py-4 px-2 scrollbar-hide rounded-[10px] shadow-xxl border border-utilityGray200 top-22 bg-white z-10 ${
                        isShowOrders ? 'absolute' : 'hidden'
                      }`}
                    >
                      {filterOrderList.map((order: any) => (
                        <div
                          key={`member_select_list_${order.id}`}
                          className={`w-full flex justify-between rounded-lg cursor-pointer hover:bg-gray50 mb-2 member_select_list_${
                            order.id
                          }${order.id === value ? 'bg-gray50' : ''}`}
                          onClick={() => {
                            onChange(order?.id);
                            setShowOrdersSearchValue(order?.fullId);
                            setIsShowOrders(false);
                          }}
                        >
                          <div className="px-3.5 py-2.5 justify-start gap-2 flex items-center">
                            <div className="flex-col justify-start items-start flex ">
                              <div className="text-grayLight900 text-xs font-medium leading-tight">
                                ${order.fullId}
                              </div>
                            </div>
                          </div>
                          {order.id === value ? (
                            <div className="flex-col justify-center items-center flex ">
                              <Check className="w-5 h-5 text-primary m-2" />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </div>
                  )} */}
                </>
              )}
            />
          </div>

          <Controller
            name="commodity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Commodity"
                inputName="commodity"
                placeholder="Commodity"
                parentClassName=""
                className=""
                value={value}
                onChangeFunc={onChange}
                errorText={errors.commodity ? errors.commodity.message : null}
                labelClassName="block mb-1.5"
              />
            )}
          />

          <Controller
            name="commercial"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                label="Commercial value"
                inputName="commercial"
                placeholder="Commercial"
                parentClassName=""
                className="pl-8"
                value={value}
                // onChangeFunc={onChange}
                onChangeFunc={(e) => {
                  let rawValue = e.target.value.replace(/[^0-9.]/g, '');
                  console.log('numericValue', rawValue);
                  const fractionPart = rawValue.split('.');
                  let numericValue = rawValue;

                  if (fractionPart.length >= 2) {
                    numericValue = `${parseFloat(fractionPart[0] ?? 0)}`;
                  }

                  let formattedValue = '';

                  if (!isNaN(numericValue)) {
                    formattedValue = new Intl.NumberFormat('en-US').format(
                      numericValue
                    );

                    if (fractionPart.length >= 2) {
                      formattedValue = `${formattedValue}.`;

                      if (fractionPart[1] > 0)
                        formattedValue += `${
                          fractionPart[1] > 0 ? fractionPart[1] : 0
                        }`;
                    }
                    onChange(formattedValue);
                  } else {
                    onChange(rawValue);
                  }
                }}
                // onChangeFunc={(e) => handleInputChange(e, onChange)} // Pass onChange to debounce
                errorText={errors.commercial ? errors.commercial.message : null}
                labelClassName="block mb-1.5"
                icon={
                  <CurrencyDollar className="absolute -translate-y-2/4 top-2/4 left-3 text-grayLight600 h-3.5 w-3.5" />
                }
              />
            )}
          />
        </div>
      </div>
    </CommonModal>
  );
};
export default RequestLoadInsurance;
