import { yupResolver } from '@hookform/resolvers/yup';
import { CalendarCheck01, File06 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import {
  createClaimDisputeTask,
  updateClaimDisputeTask,
} from 'src/services/ClaimAndDisputeService';
import * as yup from 'yup';

const schema = yup.object().shape({
  taskName: yup.string().required('Task Name is required'),
  assignedTo: yup.number().required('Assigned To is required'),
  dueDate: yup.date().required('Due Date is required').nullable(),
});

interface IProps {
  handleClose: any;
  getClaimDisputeTask: any;
  userOptions: any[];
  claimID?: any;
  disputeID?: any;
  selectedActionItemForEdit?: any;
}

const CreateUpdateTaskModal = ({
  handleClose,
  getClaimDisputeTask,
  userOptions,
  claimID,
  disputeID,
  selectedActionItemForEdit,
}: IProps) => {
  const [userOptionsList, setUserOptionsList] = useState<any[]>([]);
  const [isCreateUpdateLoading, setIsCreateUpdateLoading] = useState(false);

  useEffect(() => {
    const userOptionsArr = userOptions.map((e: any) => ({
      label: e?.name,
      value: e?.id,
    }));
    console.log('userOptionsArr ', userOptionsArr);
    setUserOptionsList(userOptionsArr);
  }, [userOptions]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      taskName: selectedActionItemForEdit
        ? selectedActionItemForEdit?.taskName
        : '',
      assignedTo: selectedActionItemForEdit
        ? selectedActionItemForEdit?.assignedTo
        : undefined,
      dueDate: selectedActionItemForEdit
        ? moment(selectedActionItemForEdit?.dueDate).toDate()
        : moment().toDate(),
    },
  });

  // Submit handler
  const onSubmit = (data: any) => {
    // formatting date
    data.dueDate = moment(data?.dueDate).format('YYYY-MM-DD');

    if (selectedActionItemForEdit) {
      let param: any = undefined;

      if (selectedActionItemForEdit?.taskName != data?.taskName) {
        if (!param) {
          param = {};
        }
        param.taskName = data?.taskName;
      }

      if (selectedActionItemForEdit?.dueDate != data?.dueDate) {
        if (!param) {
          param = {};
        }
        param.dueDate = data?.dueDate;
      }

      if (selectedActionItemForEdit?.assignedTo != data?.assignedTo) {
        if (!param) {
          param = {};
        }
        param.assignedTo = data?.assignedTo;
      }

      if (!param) {
        handleClose();

        return;
      }

      setIsCreateUpdateLoading(true);
      updateClaimDisputeTask(selectedActionItemForEdit?.id, param)
        .then(() => {
          getClaimDisputeTask();
          handleClose();
        })
        .catch(console.error)
        .finally(() => {
          setIsCreateUpdateLoading(false);
        });
    } else {
      setIsCreateUpdateLoading(true);
      createClaimDisputeTask({
        taskName: data?.taskName,
        claimID: claimID,
        disputeID: disputeID,
        dueDate: moment().format('YYYY-MM-DD'),
        assignedTo: data?.assignedTo,
      })
        .then(() => {
          getClaimDisputeTask();
          handleClose();
        })
        .catch(console.error)
        .finally(() => {
          setIsCreateUpdateLoading(false);
        });
    }
  };

  return (
    <CommonModal
      title={`${selectedActionItemForEdit ? 'Update' : 'Add'} New Task`}
      titleDesc={`${
        selectedActionItemForEdit
          ? 'Update and reassign a task.'
          : 'Add and assign a new task.'
      }`}
      handleClose={() => (isCreateUpdateLoading ? false : handleClose(false))}
      headerIcon={<File06 />}
      size={'max-w-[688px] overflow-unset'}
      isOverflow={false}
      modalClassName=""
      secondaryBtnText="Cancel"
      primaryBtnText={`${selectedActionItemForEdit ? 'Update' : 'Add'}`}
      primaryBtnOnClick={handleSubmit(onSubmit)}
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnLoading={isCreateUpdateLoading}
      primaryBtnDisabled={isCreateUpdateLoading}
      secondaryBtnDisabled={isCreateUpdateLoading}
    >
      <div className="w-full p-5 flex flex-col gap-y-4 ">
        <Controller
          name="taskName"
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputText
              inputName="description"
              placeholder="Add task name"
              label="Task Description"
              labelClassName="mb-1.5 block"
              errorText={errors.taskName?.message}
              onChangeFunc={onChange}
              value={value}
            />
          )}
        />

        <div className="sm:-mx-1.5 flex ">
          <Controller
            name="assignedTo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectBox
                parentClassName="sm:px-1.5 sm:w-1/2"
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                label="Assigned to"
                labelClassName="form_label mb-1.5 block"
                size="sm"
                options={userOptionsList}
                onChangeFunc={(e: any) => onChange(e?.value)}
                value={userOptionsList?.find((e) => e?.value == value)}
                isSearchable={false}
                errorText={errors.assignedTo?.message}
              />
            )}
          />

          <Controller
            name="dueDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateSelect
                inputName="Due_Date"
                className="form_control"
                parentClassName="datepicker-w-auto one-month-datepicker sm:px-1.5 sm:w-1/2"
                label="Due Date"
                selected={value ? moment(value).toDate() : undefined}
                onChangeFunc={(e: any) => onChange(e?.value)}
                labelClassName="block"
                placeholder="Select Due Date"
                dateFormat="dd/MM/yyyy"
                errorText={errors.dueDate?.message}
                icon={<CalendarCheck01 className="h-5 w-5" />}
              />
            )}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default CreateUpdateTaskModal;
