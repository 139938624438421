import {
  ChevronLeftDouble,
  Eye,
  EyeOff,
  SearchLg,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce, throttle, unionBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
// import DeleteModal from 'src/components/DeleteModal';
import InputText from 'src/components/InputText/InputText';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
// import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  deleteQuoteEmail,
  getIsActiveToken,
  getQuoteEmail,
  setEmailRead,
} from 'src/services/QuoteEmailService';
import { customFromNow, getShortName } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import MailDetailView from '../../QuotingHub/QuotingRequest/SmartQuote/MailDetailView';

import ConnectionModal from './ConnectionModal';
import NoEmailUI from './NoEmailUI';
import NotFoundUI from './NotFoundUI';
import MessageBodyLoading from './Skeleton/MessageBodyLoading';
import MessageListCloseLoading from './Skeleton/MessageListCloseLoading';
import MessageListLoading from './Skeleton/MessageListLoading';

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 10,
  isQuoteEmail: true,
};

const QuotingRequest = () => {
  const [params, setParams] = useState(initParams);
  const [isOpenQuoteRequest, setIsOpenQuoteRequest] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<any>([]);
  const [totalEmails, setTotalEmails] = useState<any>(0);
  const [selectedEmail, setSelectedEmail] = useState<any>({});
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1549);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [user, setUser] = useState<any>(null);
  // const [emailToDelete, setEmailToDelete] = useState<any>(null);
  // const [isDeleteLoading, setIsDeleteLoading] = useState<any>(false);
  const scrollContainerRef = useRef<any>(null);
  // const [scrollPosition, setScrollPosition] = useState(1);
  const [search, setSearch] = useState('');
  const [isEmailToggle, setIsEmailToggle] = useState(false);
  const [isEmailClicked, setIsEmailClicked] = useState(false);

  const getQuoteEmails = async (isDeleteId = null) => {
    try {
      let filter = ``;

      if (loadMore) {
        if (scrollContainerRef.current) {
          console.log('object');
          setTimeout(() => {
            scrollContainerRef.current.scrollTop =
              scrollContainerRef.current.scrollHeight + 500;
          }, 200);
        }
      }

      if (isDeleteId === null) {
        setLoading(true);
      }

      if (params?.page) {
        filter += `?page=${params?.page}&limit=${params.limit}`;
      }

      if (params?.search) {
        filter += `&search=${params?.search}`;
      }

      if (params?.isQuoteEmail) {
        filter += `&isQuoteEmail=true`;
      }
      const quoteEmails = (await getQuoteEmail(filter)) as any;
      let emailFilterData = quoteEmails?.data?.filter(
        (v: any) => v?.isConvertedToQuote === 0
      );
      setUser(quoteEmails?.user || null);
      setLoading(false);
      setLoadMore(false);
      setInitialLoading(false);
      setIsEmailToggle(false);

      emailFilterData = emailFilterData?.map((email: any) => {
        const value = { ...email, emailBody: JSON.parse(email?.emailBody) };

        return value;
      });
      const deletedIndex = emailData.findIndex(
        (x: any) => x.id === isDeleteId || false
      );

      if (params?.page === 1) {
        setEmailData(emailFilterData);
      } else {
        if (isDeleteId) {
          setEmailData((prevItems: any) => {
            const combinedData = unionBy(prevItems, emailFilterData, 'id');
            const filteredData = combinedData.filter(
              (item: any) => item.id !== isDeleteId
            );

            return filteredData;
          });
        } else {
          setEmailData((prevItems: any) =>
            unionBy(prevItems, emailFilterData, 'id')
          );
        }
      }
      setTotalEmails(quoteEmails?.total || 0);

      if (!selectedEmail?.id) {
        setSelectedEmail(emailData?.[0] || emailFilterData?.[0]);
      } else if (isDeleteId) {
        console.log('🚀 ~ getQuoteEmails ~ isDeleteId:', isDeleteId);
        setTimeout(() => {
          const selectedEmailData =
            emailData?.[deletedIndex + 1] || emailData?.[deletedIndex - 1];
          console.log(
            '🚀 ~ setTimeout ~ selectedEmailData:',
            selectedEmailData
          );
          setSelectedEmail(selectedEmailData);
        }, 200);
      }
    } catch (e: any) {
      console.log(e);
      setInitialLoading(false);
      setIsEmailToggle(false);
      WalToast.error(
        e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      );
    }
  };

  const makeEmailRead = async (messageId: string) => {
    try {
      (await setEmailRead({ messageId: messageId })) as any;
    } catch (e: any) {
      console.log(e);
    }
  };

  const fetchTokenStatus = async () => {
    try {
      setLoading(true);
      const response = await getIsActiveToken();
      setIsActive(response.data?.isActive);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching token status:', error);
      setIsActive(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenStatus();
  }, []);

  useEffect(() => {
    if (isActive) {
      getQuoteEmails();
    }
  }, [params, isActive]);

  const handleSelectedEmail = (emailDetails: any) => {
    setSelectedEmail(emailDetails);
    setIsEmailClicked(true);

    if (!emailDetails?.isRead) {
      makeEmailRead(emailDetails?.messageId);
      const emailDataCopy = emailData.map((email: any) => {
        if (email?.messageId === emailDetails?.messageId) {
          email.isRead = 1;
        }

        return email;
      });
      setEmailData(emailDataCopy);
    }
  };

  // const handleScroll = useCallback(() => {
  //   if (!scrollContainerRef.current) return;
  //   const { scrollTop, scrollHeight, clientHeight } =
  //     scrollContainerRef.current;

  //   if (
  //     !loadMore &&
  //     scrollTop + clientHeight >= scrollHeight &&
  //     totalEmails > emailData.length
  //   ) {
  //     // setScrollPosition(scrollTop + clientHeight);
  //     setTimeout(() => {
  //        - 52;
  //     }, 200);

  //     if (!loadMore) {
  //       setLoadMore(true);
  //     }
  //     setParams((old: any) => ({
  //       ...old,
  //       page: params?.page + 1,
  //     }));
  //   }
  // }, [loading, emailData]);

  const handleScroll = useCallback(
    throttle(() => {
      if (!scrollContainerRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;

      // Check if we've reached the bottom and need to load more
      if (
        !loadMore &&
        scrollTop + clientHeight >= scrollHeight - 1 &&
        totalEmails > emailData.length
      ) {
        setLoadMore(true);
        // Load more emails (simulated API call)
        setParams((old) => ({
          ...old,
          page: old.page + 1,
        }));
      }
    }, 200),
    [loadMore, emailData.length, totalEmails]
  );

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current as any;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  // useEffect(() => {
  //   if (scrollContainerRef.current && scrollPosition) {
  //     // scrollContainerRef.current.scrollTop = scrollPosition; // Restore the scroll position
  //   }
  // }, [emailData]);

  const searchDebounce = useCallback(
    debounce((debSearch: string) => {
      setParams((old: any) => ({ ...old, ...{ page: 1, search: debSearch } }));
    }, 700),
    []
  );

  const handleFilter = (event: any) => {
    setIsEmailToggle(true);
    const { value } = event.target;
    setSearch(value);
    searchDebounce(value);
  };

  useEffect(() => {
    setLoading(true);
  }, [search, params?.isQuoteEmail]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleEmail = () => {
    setIsEmailToggle(true);
    setParams((old: any) => ({
      ...old,
      page: 1,
      isQuoteEmail: !params?.isQuoteEmail,
    }));
  };

  // const handleDeleteEmail = (email: any) => {
  //   // setDeleteModalOpen(true);
  //   setEmailToDelete(email);
  // };

  // const handleDeleteModalClose = useCallback(
  //   (confirm: boolean) => async () => {
  //     setEmailToDelete(null);

  //     if (confirm && emailToDelete) {
  //       setIsDeleteLoading(true);

  //       deleteQuoteEmail({ emailId: emailToDelete.id })
  //         .then(() => {
  //           getQuoteEmails(emailToDelete.id);
  //           setIsDeleteLoading(false);
  //           WalToast.success('Email deleted successfully');
  //         })
  //         .catch((err) => {
  //           console.error('Error deleting email:', err);
  //           WalToast.error(err.message || 'Failed to delete email');
  //           setIsDeleteLoading(false);
  //         });
  //     }
  //   },
  //   [emailToDelete]
  // );
  const handleDeleteQuote = async (item: any) => {
    // const deletedIndex = emailData.findIndex(
    //   (x: any) => x.id === item.id || false
    // );
    // const deletedData = emailData?.filter((x: any) => x.id !== item.id);
    // setEmailData(deletedData);
    // setTimeout(() => {
    //   setSelectedEmail(deletedData?.[deletedIndex + 1]);
    // }, 300);
    deleteQuoteEmail({ emailId: item.id })
      .then(() => {
        getQuoteEmails(item.id);
        // setIsDeleteLoading(false);
        // WalToast.success('Email deleted successfully');
      })
      .catch((err) => {
        console.error('Error deleting email:', err);
        // WalToast.error(err.message || 'Failed to delete email');
        // setIsDeleteLoading(false);
      });
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Quote Requests"
          desc="Create, view and manage your customer quotes."
        />
      }
      contentClassName="h-[calc(100vh_-_97px)]"
    >
      {isActive ? (
        <>
          <div className="h-full w-full relative bg-white rounded-xl border border-gray-200 flex flex-wrap overflow-hidden shadow">
            {initialLoading ? (
              <MessageListLoading />
            ) : (
              <>
                <div
                  className={` border-r border-gray-200 flex-col justify-start items-start flex transition-all duration-[0.5s] max-xlm:absolute max-xlm:bg-white max-xlm:z-[1] max-sm:w-full h-full ${
                    isOpen
                      ? 'xlm:w-[25%] sm:w-[320px]'
                      : 'xlm:w-[69px] sm:w-[69px]'
                  }`}
                >
                  <div className={`w-full px-4 py-5 items-center flex `}>
                    <div
                      className={`flex items-center transition-all duration-[0.5s]  ${
                        isOpen
                          ? 'sm:w-[calc(100%_-_36px)] w-full'
                          : 'w-0 invisible opacity-0'
                      }`}
                    >
                      <div className={`flex flex-col mr-2 truncate flex-1`}>
                        <div className={`flex gap-2 items-center`}>
                          <h5
                            className={`text-grayLight900 text-lg leading-5 font-semibold`}
                          >
                            {params?.isQuoteEmail ? 'Inbox' : 'Other'}
                          </h5>
                          <BadgeCmp
                            type="primary"
                            style="modern"
                            isHidePillDot
                            mainClassName={`${
                              loading ? 'custom-loading' : ''
                            } bg-utilityPurple50 border-utilityPurple200`}
                            badgeTextColor="!text-primary fon-medium"
                          >
                            {totalEmails}
                          </BadgeCmp>
                        </div>
                        <p
                          className={`text-xs leading-[18px] font-medium text-gray500  max-w-60 truncate`}
                        >
                          {user?.email}
                        </p>
                      </div>
                      {emailData.length != 0 && (
                        <TooltipCmp
                          message={params?.isQuoteEmail ? 'Other' : 'Inbox'}
                          childrenClassName="sm:mr-3 "
                        >
                          <ButtonCmp
                            className={`${
                              params?.isQuoteEmail
                                ? 'btn_primary'
                                : 'btn_secondary_black'
                            } lg:px-[9px] px-2 `}
                            onClick={toggleEmail}
                          >
                            {params?.isQuoteEmail ? (
                              <Eye className={`w-4 h-4`} />
                            ) : (
                              <EyeOff className={`w-4 h-4`} />
                            )}
                          </ButtonCmp>
                        </TooltipCmp>
                      )}
                    </div>
                    <ButtonCmp
                      className="btn_secondary_black lg:px-[9px] px-2 max-sm:hidden"
                      onClick={toggleSidebar}
                      icon={
                        <ChevronLeftDouble
                          className={`w-4 h-4 ${isOpen ? '' : 'rotate-180'}`}
                        />
                      }
                    >
                      <></>
                    </ButtonCmp>
                  </div>

                  <InputText
                    inputName="search"
                    placeholder="Search"
                    className={`bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input ${
                      isOpen ? ' ' : '!pr-[0px] text-[0]'
                    } `}
                    icon={
                      <SearchLg
                        onClick={toggleSidebar}
                        className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4"
                      />
                    }
                    value={search}
                    isClearable
                    inputType="text"
                    parentClassName="w-full px-4 pb-3 "
                    onChangeFunc={handleFilter}
                    inputWrapClass="!justify-start"
                  />

                  <div
                    className="w-full flex-1 overflow-auto custom-scrollbar-v2 overflow-x-hidden"
                    ref={scrollContainerRef}
                  >
                    {(!loading || loadMore) &&
                      emailData?.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="last:border-b-0 border-b border-utilityGray200"
                        >
                          {/* {isOpen ? ( */}
                          <>
                            <div
                              className={`p-4 flex items-center cursor-pointer ${
                                item?.emailBody?.id ===
                                selectedEmail?.emailBody?.id
                                  ? 'bg-utilityGray400'
                                  : ''
                              }`}
                              key={index}
                              onClick={() => handleSelectedEmail(item)}
                            >
                              <div
                                key={index}
                                className={`w-9 h-9 rounded-full bg-utilityGray100 text-primary text-sm flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none relative shadow-lg `}
                              >
                                {getShortName(
                                  `${item?.emailBody?.from?.emailAddress?.name}`
                                )}
                                <p
                                  className={` ${
                                    item?.isRead
                                      ? ''
                                      : 'w-2 h-2 bg-primary500 rounded-full -left-[0px] top-0.5 absolute'
                                  }`}
                                ></p>
                              </div>

                              {/* <p
                                  className={` ${
                                    item?.isRead
                                      ? 'w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start'
                                      : 'w-2 h-2 bg-primary500 rounded-full mt-1.5 self-start'
                                  }`}
                                ></p> */}
                              <div
                                className={`flex-1 ml-3  transition-all duration-[0.5s] ${
                                  isOpen ? ' ' : 'text-[0] opacity-0 invisible'
                                } `}
                              >
                                <h6
                                  className={`text-sm textSecondary line-clamp-1 ${
                                    item?.isRead ? 'font-medium' : 'font-bold'
                                  }`}
                                >
                                  {item?.emailBody?.from?.emailAddress?.name}
                                </h6>
                                <p
                                  className={`text-xs leading-[18px] font-medium line-clamp-1 ${
                                    item?.isRead
                                      ? 'text-textSecondary'
                                      : 'text-gray500'
                                  }`}
                                >
                                  {`${item?.emailBody?.bodyPreview?.substring(
                                    0,
                                    40
                                  )} ...`}
                                </p>
                              </div>
                              <div
                                className={`text-xs text-grayLight600 ml-1.5 transition-all duration-[0.5s] ${
                                  isOpen ? ' ' : 'text-[0] opacity-0 invisible'
                                }  ${
                                  item?.isRead ? 'font-normal' : 'font-bold'
                                }`}
                              >
                                {/* {moment(item?.emailBody?.receivedDateTime).fromNow(
                                    true
                                  )} */}
                                {customFromNow(
                                  item?.emailBody?.receivedDateTime
                                )}
                              </div>
                              <ButtonCmp
                                className="btn_secondary_black lg:px-[9px] px-2 ml-1.5"
                                icon={<XClose className="w-4 h-4" />}
                                onClick={() => handleDeleteQuote(item)}
                              >
                                <></>
                              </ButtonCmp>
                            </div>
                          </>
                        </div>
                      ))}
                    {(loading || loadMore) && isOpen && (
                      <MessageListLoading
                        isLoadMore={loadMore}
                        loading={loading}
                        isEmailToggle={isEmailToggle}
                      />
                    )}
                    {(loading || loadMore) && !isOpen && (
                      <MessageListCloseLoading isLoadMore={loadMore} />
                    )}
                  </div>
                </div>
              </>
            )}
            <div
              className={`md:w-[75%] flex-1 w-full flex flex-col max-xlm:pl-[69px] max-sm:pl-0 ${
                isEmailClicked
                  ? 'max-sm:translate-y-0'
                  : 'max-sm:translate-y-full'
              } max-sm:z-[2] transition-all duration-[0.5s] max-sm:bg-white`}
            >
              {initialLoading ? (
                <MessageBodyLoading />
              ) : emailData.length === 0 ? (
                <NoEmailUI />
              ) : loading && !loadMore ? (
                <MessageBodyLoading />
              ) : (
                <MailDetailView
                  emailDetails={selectedEmail}
                  setIsEmailClicked={setIsEmailClicked}
                />
              )}
            </div>
          </div>
          {isOpenQuoteRequest && (
            <ConnectionModal handleClose={() => setIsOpenQuoteRequest(false)} />
          )}

          {/* {emailToDelete && (
            <DeleteModal
              moduleName="Email"
              handleClose={() => null}
              isDeleteLoading={isDeleteLoading}
            />
          )} */}
        </>
      ) : (
        <NotFoundUI />
      )}
    </PageSectionLayout>
  );
};

export default QuotingRequest;
