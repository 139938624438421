import { yupResolver } from '@hookform/resolvers/yup';
import {
  CalendarCheck01,
  UploadCloud02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
// import StepBar from 'src/components/StepBar/StepBar';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import TableCmp from 'src/components/TableCmp';
import { ONBOARDING_CUSTOMER_STATUS, orgTypeArr } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import { usePhone } from 'src/hooks/usePhone';
import {
  businessCreditApplication,
  businessCreditApplicationDetail,
  deleteStatementFile,
  updateOnboarding,
} from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import LogoDark from '../../assets/img/logo-dark.png';
import { scriptId } from '../QuotingHub/QuotingDashboard/AddressDetails/Address.interface';

import BankInfoForm from './BankInfoForm';
import CreditReferenceItem from './CreditReferenceItem';
import ExpiredPage from './ExpiredPage';
import OfficersItem from './OfficersItem';
import StatementModal from './StatementModal';
import StatementOnboarding from './StatementOnboarding';
import TradeReferenceItem from './TradeReferenceItem';
import VerifyOtp from './VerifyOtp';

const initOfficers = {
  name: '',
  title: '',
  home_address: '',
  city: '',
};

const initCreditRefenerence = {
  company_name: '',
  phone_number: '',
  email: '',
  credit_amount: '',
};

const initTradeRefenerence = {
  company_name: '',
  phone_number: '',
  email: '',
  city: '',
};

const initAddressData = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
};

const BusinessApplicationForm = () => {
  const { id } = useParams<{ id: any }>();
  const { mapService, googleKey } = useContext(BasicContext);

  const navigate = useNavigate();
  const verifyData: any = localStorage.getItem('verifyData');
  const [isViewDateUpdated, setIsViewDateUpdated] = useState(false);
  // const formDataString = localStorage.getItem('formData');
  const [customerDetail, setCustomerDetail] = useState<any>();
  // const formDetail: any = formDataString ? JSON.parse(formDataString) : null;
  // const [formDetail, setFormDetail] = useState({});
  const { isVerify, customerId } = verifyData
    ? JSON.parse(verifyData)
    : { isVerify: 0, customerId: null };

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [officersArr, setOfficersArr] = useState<any[]>([initOfficers]);
  const [financeStatementArr, setFinanceStatementArr] = useState<any[]>([]);
  const [creditReferenceArr, setCreditReferenceArr] = useState<any[]>([
    initCreditRefenerence,
  ]);
  const [tradeReferenceArr, setTradeReferenceArr] = useState<any[]>([
    initTradeRefenerence,
  ]);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const { isPhoneValid } = usePhone();
  const [isVerifyOtp, setIsVerifyOtp] = useState(
    isVerify === '1' && id == customerId ? true : false
  );

  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const updateFormViewDate = (custId: number) => {
    updateOnboarding({
      id: custId,
      form_view_date: moment().format('YYYY-MM-DD'),
      form_sent_date: moment().format('YYYY-MM-DD'),
    })
      .then(() => {
        setIsViewDateUpdated(true);
      })
      .catch(() => WalToast.error('something went wrong please try again'));
  };

  // useEffect(() => {
  //   console.log('formDetail', formDetail);
  // }, [formDetail]);

  const validationSchema = yup.object().shape({
    company_name: yup.string().required('Company is required.'),
    contact_number: yup
      .string()
      .test('isValidPhone', 'Invalid phone number', function (value) {
        if (value === '') {
          return true;
        }
        const { phone_country_code: phoneCountryCode } = this.parent;

        return isPhoneValid(value, phoneCountryCode);
      })
      .required('Contact number is required.'),
    phone_country_code: yup.string().required('Country code is required.'),
    fax: yup.string(),
    company_email: yup
      .string()
      .email('Must be a valid email')
      .required('Email is required.'),
    address: yup.string().required('Address is required.'),
    city: yup.string().required('City is required.'),
    state: yup.string().required('State is required.'),
    postal: yup.string().required('Postal code is required.'),
    country: yup.string().required('Country is required.'),
    organization_type: yup
      .array()
      .of(yup.string())
      .min(1, 'At least one organization type must be selected')
      .required('Organization type is required.'),
    officers: yup.array().of(
      yup.object().shape({
        officer_name: yup.string().required('Name is required'),
        title: yup.string(),
        home_address: yup.string().required('Home address is required'),
        city: yup.string().required('City is required'),
      })
    ),
    creditReferences: yup.array().of(
      yup.object().shape({
        company_name: yup.string().required('Company name is required'),
        phone_number: yup
          .string()
          .test('isValidPhone', 'Invalid phone number', function (value) {
            if (value === '') {
              return true;
            }
            const { phone_country_code: phoneCountryCode } = this.parent;

            return isPhoneValid(value, phoneCountryCode);
          })
          .required('Contact number is required.')
          // .string()
          // .required('Phone no is required.')
          .matches(/^[+\-() \d]*$/, 'Invalid phone number'),
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),

        credit_amount: yup
          .number()
          .positive('Credit amount must be greater than 0')
          .min(0.01, 'Credit amount must be greater than 0')
          .required('Credit amount is required'),
      })
    ),
    tradeReferences: yup.array().of(
      yup.object().shape({
        company_name: yup.string().required('Company name is required'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .matches(/^[+\-() \d]*$/, 'Invalid phone number'),
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),
        city: yup.string().required('City is required'),
      })
    ),
    financeStatement: yup.array().of(
      yup.object().shape({
        year: yup.string().required('Year is required'),
        quarter: yup.string().required('Quarter is required'),
        file: yup.string().required('file is required'),
      })
    ),
    business_start_date: yup
      .date()
      .required('Business Start date is required.'),
    federal_tax_number: yup.string(),
    business_type: yup.string(),
    d_b_number: yup.string(),
    account_payable_name: yup.string().required('Name is required.'),
    account_payable_phone: yup
      .string()
      .test('isValidPhone', 'Invalid phone number', function (value) {
        if (value === '') {
          return true;
        }
        const { phone_country_code: phoneCountryCode } = this.parent;

        return isPhoneValid(value, phoneCountryCode);
      })
      .required('Contact number is required.')
      // .string()
      // .required('Phone no is required.')
      .matches(/^[+\-() \d]*$/, 'Invalid phone number'),
    account_payable_email: yup
      .string()
      .email('Invalid email')
      .required('Email is required.'),
    bank_name: yup.string().required('Bank name is required.'),
    bank_email: yup
      .string()
      .email('Invalid email')
      .required('Bank email is required.'),
    bank_address: yup.string(),
    bank_contact_name: yup.string(),
    bank_phone: yup
      .string()
      .test('isValidPhone', 'Invalid phone number', function (value) {
        if (value === '') {
          return true;
        }
        const { phone_country_code: phoneCountryCode } = this.parent;

        return isPhoneValid(value, phoneCountryCode);
      })
      .required('Contact number is required.')
      // .string()
      // .required('Phone no is required.')
      .matches(/^[+\-() \d]*$/, 'Invalid phone number'),
    // string().matches(/^[+\-() \d]*$/, 'Invalid phone number'),
    bank_city: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    setValue,
    getValues,
    trigger,
    reset,
    watch,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      business_start_date: new Date(),
      organization_type: [],
    },
    mode: 'onTouched',
  });

  const getDetail = () => {
    setIsLoading(true);
    businessCreditApplicationDetail(id)
      .then((response: any) => {
        setCustomerDetail(response?.data?.personal_detail);
        setFinanceStatementArr(response?.data?.financeStatement);

        if (
          response?.data &&
          response?.data?.personal_detail?.status !==
            ONBOARDING_CUSTOMER_STATUS.FORM_PENDING &&
          response?.data?.personal_detail?.status !==
            ONBOARDING_CUSTOMER_STATUS.EXPIRED
        ) {
          const businessDetail = response?.data?.business_address;
          const companyInfo = response?.data?.company_info;
          const bankInfo = response?.data?.bank_info;
          const personalDetail = response?.data?.personal_detail;
          const accountsPayable = response?.data?.accounts_payable;
          const overview = response?.data?.overview;

          const orgTypeName = companyInfo?.organization_type?.split(',');
          setCreditReferenceArr(response?.data?.creditReferences);
          setTradeReferenceArr(response?.data?.tradeReferences);
          setTradeReferenceArr(response?.data?.tradeReferences);

          reset({
            ...response?.data,
            ...accountsPayable,
            ...businessDetail,
            ...personalDetail,
            ...companyInfo,
            ...overview,
            ...bankInfo,
            financeStatement: [],
            organization_type: orgTypeName,
          });
          if (!isViewDateUpdated)
            updateFormViewDate(response?.data?.personal_detail?.id);
          setIsLoading(false);
        }
      })
      .catch((e: any) =>
        WalToast.error(
          e?.response?.data?.message ?? 'something went wrong please try again'
        )
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    console.log('customerDetail', customerDetail);
  }, [customerDetail]);

  useEffect(() => {
    console.log('financeStatement', watch('financeStatement'));
  }, [watch('financeStatement')]);

  const deleteFile = (deleteId: any) => {
    deleteStatementFile(deleteId)
      .then(() => {
        getDetail();
        WalToast.success('Statement Deleted successfully');
      })
      .catch((e: any) =>
        WalToast.error(
          e?.response?.data?.message ?? 'something went wrong please try again'
        )
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = async (formData: any) => {
    setIsSubmitLoading(true);
    // localStorage.setItem('formData', JSON.stringify(formData));
    // navigate(`${PATH.SUBMISSION}/${id}`, { state: { formData } });
    businessCreditApplication(id, formData)
      .then(() => {
        WalToast.success('Customer added successfully');
        localStorage.removeItem('verifyData');
        navigate(`${PATH.THANK_YOU}/${id}`);
      })
      .catch(() => WalToast.error('Customer not added'))
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const addRaw = (type: string) => {
    if (type === 'officers') {
      setOfficersArr((old: any) => [...old, initOfficers]);
    } else if (type === 'credit_reference') {
      setCreditReferenceArr((old: any) => [...old, initCreditRefenerence]);
    } else if (type === 'trade_reference') {
      setTradeReferenceArr((old: any) => [...old, initTradeRefenerence]);
    }
  };

  const removeRaw = (removeIndex: number, type: string) => {
    if (type === 'officers') {
      const newArray = getValues('officers')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      setOfficersArr(newArray);
      setValue('officers', newArray);
    } else if (type === 'credit_reference') {
      const newArray = getValues('creditReferences')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      setCreditReferenceArr(newArray);
      setValue('creditReferences', newArray);
    } else if (type === 'trade_reference') {
      const newArray = getValues('tradeReferences')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      setTradeReferenceArr(newArray);
      setValue('tradeReferences', newArray);
    } else if (type === 'financeStatement') {
      const newArray = getValues('financeStatement')?.filter(
        (_: any, index: any) => index !== removeIndex
      );
      // setTradeReferenceArr(newArray);
      setValue('financeStatement', newArray);
    }
  };

  const StatementHeadCells = useMemo(
    () => [
      {
        id: 'file_name',
        name: 'File name',
      },
      {
        id: 'year',
        name: 'Year',
      },
      {
        id: 'quarter',
        name: 'Quarter',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const handleAutocompleteChange = (value: any) => {
    if (value.type === 'blur') {
      if (!watch('address')) {
        console.log('initAddressData', initAddressData);
      }

      return;
    }
    setValue('city', value?.city);
    setValue('state', value?.state);
    setValue('postal', value?.postal);
    setValue('country', value?.country);
    console.log('value', value);
  };

  const [isShowStatementModal, setIsShowStatementModal] = useState(false);
  console.log('errors', errors);

  useEffect(() => {
    if (errors) {
      for (const inputName of Object.keys(errors)) {
        const fieldErrors = errors[inputName]; // Get the error object for each key

        if (Array.isArray(fieldErrors)) {
          for (let i = 0; i < fieldErrors.length; i++) {
            if (fieldErrors[i]) {
              const fieldErrorObj = fieldErrors[i]; // Get the error object for the specific index

              // Iterate over the fields within each array entry (e.g., company_name, phone_number, etc.)
              for (const fieldName of Object.keys(fieldErrorObj)) {
                const fieldError = fieldErrorObj[fieldName]; // Get the actual error message

                // If there's an error, focus on the corresponding input
                if (fieldError) {
                  const inputElement = document.querySelector(
                    `input[name="${inputName}.${i}.${fieldName}"]`
                  ) as HTMLInputElement | null;
                  console.log('inputElement', inputElement);

                  if (inputElement) {
                    inputElement.focus();

                    return; // Stop once the first error input is focused
                  }
                }
              }
            }
          }
        } else {
          const inputElement = document.querySelector(
            `input[name="${inputName}"]`
          ) as HTMLInputElement | null;

          if (inputElement) {
            inputElement.focus();
            break; // Focus on the first input with an error and stop
          }
        }
      }
    }
  }, [errors]);

  return (
    <>
      {!isLoading ? (
        <>
          {isVerifyOtp ||
          (customerDetail?.isMailSent === 0 &&
            customerDetail?.status !== ONBOARDING_CUSTOMER_STATUS.EXPIRED) ? (
            <div className="overflow-y-auto h-full p-5 bg-utilityGray50 flex flex-col">
              <div className="max-w-[1200px] rounded-xl bg-white shadow-lgc xl:my-[30px] my-5 mx-auto flex flex-col flex-1 overflow-hidden w-full">
                <div className="border-b border-borderPrimary px-5 py-3 flex flex-wrap gap-3 justify-between">
                  <div className="max-w-[517px] ">
                    <h6 className="text-grayLight900 text-base font-semibold">
                      Business Credit Application Form
                    </h6>
                    <p className="text-grayLight600 text-xs font-normal ">
                      Western Alliance Logistics requests your cooperation in
                      completing and returning this form as soon as possible so
                      that we can review your request for credit availability.
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="max-w-[113px] ">
                      <img src={LogoDark} alt="logo-light" className="w-full" />
                    </div>

                    {customerDetail?.image && (
                      <div className="max-w-[128px] min-w-[50px] pl-3 ml-3 border-l border-borderPrimary">
                        <img
                          src={`${customerDetail.imageUrl}${customerDetail.image}`}
                          className="w-full h-[50px] object-cover"
                          alt="Customer"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-4 flex-1 overflow-auto custom-scrollbar-v2 p-6">
                  <p className="text-grayLight900 text-sm font-semibold">
                    Company Information
                  </p>
                  <div className="rounded-lg border border-utilityGray200 px-3 pt-3.5 pb-4 flex flex-col gap-y-4">
                    <div className="flex max-sm:flex-col max-sm:gap-y-4 sm:-mx-1.5">
                      <Controller
                        name="company_name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="Company Name"
                            inputName="companyName"
                            placeholder="Company Name"
                            parentClassName="sm:w-1/2 sm:px-1.5"
                            value={value}
                            required={true}
                            onChangeFunc={onChange}
                            errorText={
                              errors.company_name
                                ? errors.company_name.message
                                : null
                            }
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                      <div className="sm:w-1/2 sm:px-1.5">
                        <label className="form_label block mb-1.5">
                          Contact Number{' '}
                          <span className="text-red-600 leading-4">*</span>
                        </label>
                        <Controller
                          name="contact_number"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              onNumberChange={(
                                country: any,
                                phone: any,
                                code: any
                              ) => {
                                console.log('onNumberChange');
                                const newValue = `+${code}${phone}`;
                                setValue('contact_number', newValue);
                                setValue('phone_country_code', country);
                                onChange(newValue);

                                if (touchedFields.contactPhone) {
                                  trigger('contactPhone');
                                  trigger('phone_country_code');
                                }
                              }}
                              value={value}
                              name="contact_number"
                              errors={
                                errors.contact_number
                                  ? errors.contact_number.message
                                  : errors.phone_country_code
                                  ? errors.phone_country_code.message
                                  : null
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex max-sm:flex-col max-sm:gap-y-4 sm:-mx-1.5">
                      <Controller
                        name="fax"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="Fax"
                            inputName="fax"
                            placeholder="Fax"
                            parentClassName="sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                      <Controller
                        name="company_email"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="Email"
                            inputName="company_email"
                            placeholder="Email"
                            parentClassName="sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            required={true}
                            errorText={
                              errors.company_email
                                ? errors.company_email.message
                                : null
                            }
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                    </div>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          {status ? (
                            mapService === 'trimble_map' ? (
                              <AutoCompleteTrimble
                                inputName="fullAddress"
                                placeholder="Enter address"
                                className="form_control truncate"
                                label="Address"
                                labelClassName="mb-1.5 block"
                                dropdownClass="!max-h-[200px]"
                                onAddressInputChange={onChange}
                                onChangeFunc={handleAutocompleteChange}
                                onBlur={handleAutocompleteChange}
                                errorText={
                                  errors.address ? 'Address is required' : null
                                }
                                value={value}
                              />
                            ) : (
                              <AutoCompleteGoogle
                                inputName="fullAddress"
                                placeholder="Enter address"
                                className="form_control truncate"
                                label="Address"
                                labelClassName="mb-1.5 block"
                                onAddressInputChange={onChange}
                                onChangeFunc={handleAutocompleteChange}
                                onBlur={handleAutocompleteChange}
                                errorText={
                                  errors.address ? 'Address is required' : null
                                }
                                value={value}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    />

                    <div className="flex flex-wrap max-sm:flex-col max-md:gap-y-4 sm:-mx-1.5">
                      <Controller
                        name="city"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="City"
                            inputName="city"
                            placeholder="City"
                            parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            required={true}
                            errorText={errors.city ? errors.city.message : null}
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                      <Controller
                        name="state"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="State"
                            inputName="state"
                            placeholder="State"
                            parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            required={true}
                            errorText={
                              errors.state ? errors.state.message : null
                            }
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                      <Controller
                        name="postal"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="Postal"
                            inputName="postal"
                            placeholder="Postal"
                            parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            required={true}
                            errorText={
                              errors.postal ? errors.postal.message : null
                            }
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                      <Controller
                        name="country"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="Country"
                            inputName="country"
                            placeholder="Country"
                            parentClassName="md:w-1/4 sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            required={true}
                            errorText={
                              errors.country ? errors.country.message : null
                            }
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                    </div>
                    <div className="">
                      <p className="form_label block mb-1.5">
                        Organization Type{' '}
                        <span className="text-red-600 leading-4">*</span>{' '}
                      </p>
                      <div className="rounded-lg border border-utilityGray200 flex flex-wrap gap-y-4 px-3 py-4">
                        <Controller
                          name="organization_type"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              {orgTypeArr.map((val: any, index: number) => (
                                <div className="flex w-1/4 pr-3" key={index}>
                                  <CheckBox
                                    id={`service_${val.value}`}
                                    onChangeFunc={(e: any) => {
                                      const checked = e.target.checked;
                                      let updatedServices = value
                                        ? [...value]
                                        : [];

                                      if (checked) {
                                        updatedServices.push(val.value);
                                      } else {
                                        updatedServices =
                                          updatedServices.filter(
                                            (item) => item !== val.value
                                          );
                                      }
                                      onChange(updatedServices);
                                    }}
                                    name="organization_type"
                                    checked={value?.includes(val.value)}
                                    value={val.label}
                                    parentClassName=""
                                  />
                                  <label
                                    htmlFor={`service_${val.value}`}
                                    className="ml-2 text-textSecondary text-xs font-medium"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              ))}
                            </>
                          )}
                        />
                      </div>
                      <span className="text-red-600 text-xs leading-4">
                        {(errors?.organization_type?.message as any) ?? null}
                      </span>
                    </div>
                    <div className="flex max-sm:flex-col max-sm:gap-y-4 sm:-mx-1.5">
                      <Controller
                        name="business_start_date"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DateSelect
                            inputName="business_start_date"
                            className="form_control"
                            parentClassName="sm:w-1/2 sm:px-1.5 datepicker-w-auto"
                            label="Business Start Date"
                            selected={
                              value ? moment(value).toDate() : undefined
                            }
                            value={moment(value).format('DD/MM/yyyy')} // Ensuring the value is always a Date object
                            labelClassName="block"
                            placeholder="Business Start Date"
                            required={true}
                            dateFormat="dd/MM/yyyy"
                            errorText={
                              errors.business_start_date
                                ? errors.business_start_date.message
                                : null
                            }
                            onChangeFunc={(date: any) => {
                              onChange(date?.value);
                            }} // Update form value on change
                            icon={<CalendarCheck01 className="h-5 w-5" />}
                          />
                        )}
                      />
                      <Controller
                        name="federal_tax_number"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="Federal Tax ID Number"
                            inputName="federal_tax_number"
                            placeholder="Federal Tax ID Number"
                            parentClassName="sm:w-1/2 sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                    </div>
                    <div className="flex max-sm:flex-col max-sm:gap-y-4 sm:-mx-1.5">
                      <Controller
                        name="business_type"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="Type of Business"
                            inputName="business_type"
                            placeholder="Enter your Business"
                            parentClassName="sm:w-1/2 sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                      <Controller
                        name="d_b_number"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="D&B (DUNS) Number"
                            inputName="d_b_number"
                            placeholder="Enter number"
                            parentClassName="sm:w-1/2 sm:w-1/2 sm:px-1.5"
                            value={value}
                            onChangeFunc={onChange}
                            labelClassName="form_label block mb-1.5"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <span className="form_label block mb-1.5 font-semibold">
                      Accounts Payable Contact
                    </span>
                    <div className="rounded-lg border border-utilityGray200 px-3 pt-3.5 pb-4">
                      <div className="flex flex-wrap gap-y-4 md:-mx-1.5">
                        <Controller
                          name="account_payable_name"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputText
                              label="Name"
                              inputName="account_payable_name"
                              placeholder="Enter Name"
                              parentClassName="md:w-1/3 md:px-1.5"
                              value={value}
                              onChangeFunc={onChange}
                              required={true}
                              errorText={
                                errors.account_payable_name
                                  ? errors.account_payable_name.message
                                  : null
                              }
                              labelClassName="block mb-1.5"
                            />
                          )}
                        />
                        <div className="md:w-1/3 md:px-1.5">
                          <label className="form_label block mb-1.5">
                            Phone Number{' '}
                            <span className="text-red-600 leading-4">*</span>
                          </label>
                          <Controller
                            name="account_payable_phone"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <PhoneInput
                                onNumberChange={(
                                  country: any,
                                  phone: any,
                                  code: any
                                ) => {
                                  console.log('onNumberChange');
                                  const newValue = `+${code}${phone}`;
                                  setValue('account_payable_phone', newValue);
                                  setValue('phone_country_code', country);
                                  onChange(newValue);

                                  if (touchedFields.account_payable_phone) {
                                    trigger('account_payable_phone');
                                    trigger('phone_country_code');
                                  }
                                }}
                                value={value}
                                name="account_payable_phone"
                                errors={
                                  errors.account_payable_phone
                                    ? errors.account_payable_phone.message
                                    : errors.phone_country_code
                                    ? errors.phone_country_code.message
                                    : null
                                }
                              />
                            )}
                          />
                        </div>
                        <Controller
                          name="account_payable_email"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputText
                              label="Email"
                              inputName="email"
                              placeholder="Enter email"
                              parentClassName="md:w-1/3 md:px-1.5"
                              value={value}
                              onChangeFunc={onChange}
                              required={true}
                              errorText={
                                errors.account_payable_email
                                  ? errors.account_payable_email.message
                                  : null
                              }
                              labelClassName="block mb-1.5"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Officers & Owners */}
                  <div>
                    <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                      Officers & Owners
                    </h6>
                    <OfficersItem
                      officersArr={officersArr}
                      addRaw={addRaw}
                      removeRaw={removeRaw}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div>
                    <BankInfoForm
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      touchedFields={touchedFields}
                      trigger={trigger}
                    />
                  </div>
                  <div>
                    <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                      Credit References
                    </h6>
                    <CreditReferenceItem
                      creditReferenceArr={creditReferenceArr}
                      setValue={setValue}
                      touchedFields={touchedFields}
                      trigger={trigger}
                      addRaw={addRaw}
                      removeRaw={removeRaw}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div>
                    <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                      Trader References
                    </h6>
                    <TradeReferenceItem
                      tradeReferenceArr={tradeReferenceArr}
                      setValue={setValue}
                      touchedFields={touchedFields}
                      trigger={trigger}
                      addRaw={addRaw}
                      removeRaw={removeRaw}
                      control={control}
                      errors={errors}
                    />
                  </div>
                  <div>
                    <h6 className="text-grayLight900 text-sm font-semibold mb-1.5">
                      Supporting Financial Statements
                      <span className="text-xs font-normal">
                        &nbsp;(Please attach statements)
                      </span>
                    </h6>

                    <div
                      className="cursor-pointer flex-auto rounded-xl border border-gray100 py-3.5 px-5 text-center bg-white mb-4 hover:border-grayText"
                      onClick={() => setIsShowStatementModal(true)}
                    >
                      <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow ">
                        <UploadCloud02 className="w-4 h-4 text-textSecondary" />
                      </div>
                      <div className="text-grayLight600 text-xs font-normal">
                        <p>
                          <span className="text-[#2422DD] font-semibold pr-1">
                            Click to upload statements
                          </span>
                          {/* or drag and drop */}
                        </p>
                        {/* <p className="text-[11px] leading-[18px]">PDF file</p> */}
                      </div>
                    </div>
                    <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                      <div className="w-full ">
                        <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                          <TableCmp
                            headCells={StatementHeadCells}
                            tableDataArr={financeStatementArr ?? []}
                            isTableDataLoading={isLoading}
                            tableRowCmpProps={{
                              deleteFile: deleteFile,
                            }}
                            TableRowCmp={StatementOnboarding}
                          />
                        </div>
                        {!financeStatementArr.length ? (
                          <NotFoundUI
                            title="There are no financial statements"
                            desc="Please add a financial statement above"
                            containerClassName="!h-auto !my-9"
                            iconClassName="!hidden"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-right sticky bottom-0 bg-white py-4 px-6 border-t border-utilityGray200">
                  <ButtonCmp
                    className="btn_primary min-w-[132px]"
                    onClick={handleSubmit(onSubmit)}
                    loading={isSubmitLoading}
                    disabled={isSubmitLoading}
                  >
                    Next
                  </ButtonCmp>
                </div>
              </div>
            </div>
          ) : customerDetail?.status === ONBOARDING_CUSTOMER_STATUS.EXPIRED ? (
            <ExpiredPage />
          ) : (
            !isLoading && <VerifyOtp setIsVerifyOtp={setIsVerifyOtp} />
          )}
        </>
      ) : (
        ''
      )}

      {isShowStatementModal && (
        <StatementModal
          customerId={id}
          setFinanceStatementArr={setFinanceStatementArr}
          handleClose={() => {
            // getDetail();
            setIsShowStatementModal(false);
          }}
        />
      )}
    </>
  );
};

export default BusinessApplicationForm;
